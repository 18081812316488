<template>
  <div class="content-wrap">
    <form class="field-container">
      <TextInput
        required
        textLabel="Nome"
        regexString="^[a-zA-ZÀ-ú ]*$"
        placeholderText="Seu nome"
        inputId="given-name"
        inputName="given-name"
        inputAutocomplete="given-name"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.nome"
        :validateField="triggerValidate"
        @update:inputValue="localData.nome = $event"
        @validateField="validateFields.nome = $event"
      />
      <TextInput
        required
        textLabel="Sobrenome"
        regexString="^[a-zA-ZÀ-ú ]*$"
        placeholderText="Seu sobrenome"
        inputId="family-name"
        inputName="family-name"
        inputAutocomplete="family-name"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.sobrenome"
        :validateField="triggerValidate"
        @update:inputValue="localData.sobrenome = $event"
        @validateField="validateFields.sobrenome = $event"
      />
      <TextInput
        required
        textLabel="Telefone"
        inputMode="tel"
        placeholderText="Telefone para contato"
        regexString="^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$"
        inputId="telNational"
        inputName="telNational"
        inputAutocomplete="tel-national"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.telefone"
        :inputMask="['(##) ####-####', '(##) #####-####']"
        :validateField="triggerValidate"
        @update:inputValue="localData.telefone = $event"
        @validateField="validateFields.telefone = $event"
      />
      <DateInput
        required
        textLabel="Nascimento"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.nascimento"
        :validateField="triggerValidate"
        @update:inputValue="localData.nascimento = $event"
        @validateField="validateFields.nascimento = $event"
      />
      <DocumentInput
        required
        textLabel="CPF"
        placeholderText="000.000.000-00"
        :readonly="savingData"
        :isDisabled="!!initialData.cpf_cnpj || isDisabled"
        :inputValue="localData.cpf_cnpj"
        :validateField="triggerValidate"
        :inputMask="['###.###.###-##']"
        @update:inputValue="localData.cpf_cnpj = $event"
        @validateField="validateFields.cpf_cnpj = $event"
      />
      <EmailInput
        required
        textLabel="E-mail"
        placeholderText="Seu melhor e-mail"
        :readonly="savingData"
        :isDisabled="true"
        :inputValue="localData.email"
      />
    </form>
    <div class="editingButtonContainer" v-if="!isDisabled">
      <MainButton
        :isSecondary="true"
        :isDisabled="savingData"
        @click.native="cancelChanges"
      >
        <span>Cancelar</span>
      </MainButton>
      <MainButton :isLoading="savingData" @click.native="saveChanges">
        <span>Salvar</span>
      </MainButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import TextInput from '@/components/input/TextInput.vue';
import DocumentInput from '@/components/input/DocumentInput.vue';
import DateInput from '@/components/input/DateInput.vue';
import EmailInput from '@/components/input/EmailInput.vue';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'UserBox',
  data() {
    return {
      localData: {},
      initialData: {},
      savingData: false,
      triggerValidate: false,
      validateFields: {
        nome: false,
        sobrenome: false,
        nascimento: false,
        telefone: false,
        cpf_cnpj: false,
      },
    };
  },
  components: {
    TextInput,
    DocumentInput,
    DateInput,
    EmailInput,
    MainButton,
  },
  props: {
    isDisabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['userData']),
  },
  watch: {
    userData(value) {
      this.localData = Object.assign({}, value);
      this.initialData = Object.assign({}, value);
    },
    isDisabled(value) {
      if (!value) {
        this.validateUserData(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUserData: 'UPDATE_USER_DATA',
      validateUserData: 'VALIDATE_USER_DATA',
    }),
    ...mapActions({
      saveUserData: 'SAVE_USER_DATA',
    }),
    async saveChanges() {
      this.savingData = true;
      const isValid = await this.checkValidation();

      if (!isValid) {
        this.$toast.error('Preencha os campos corretamente');
        this.savingData = false;
        return false;
      }

      const data = Object.assign(
        {},
        {
          nome: this.localData.nome,
          sobrenome: this.localData.sobrenome,
          telefone: this.localData.telefone,
          nascimento: this.localData.nascimento,
          email: this.initialData.email,
        }
      );

      if (!this.initialData.cpf_cnpj) {
        Object.assign(data, { cpf_cnpj: this.localData.cpf_cnpj });
      } else {
        Object.assign(this.localData, { cpf_cnpj: this.initialData.cpf_cnpj });
      }

      await this.saveUserData(data).then((res) => {
        if (res) {
          this.initialData = Object.assign({}, data);

          this.updateUserData(data);
          this.validateUserData(true);
        } else {
          this.cancelChanges();
          this.$toast.error('Erro ao processar. Tente novamente');
        }

        this.savingData = false;
        this.$emit('update:isUserDisabled', true);
      });
    },
    async cancelChanges() {
      this.localData = Object.assign({}, this.initialData);

      this.updateUserData(this.initialData);
      this.validateUserData((await this.checkValidation(false)) ? true : false);

      this.$emit('update:isUserDisabled', true);
    },
    checkValidation() {
      this.triggerValidate = true;
      this.validateUserData(false);

      return new Promise((resolve) => {
        setTimeout(() => {
          for (let key in this.validateFields) {
            if (this.validateFields[key] == false) {
              this.triggerValidate = false;
              this.validateUserData(false);
              return resolve(false);
            }
          }

          this.triggerValidate = false;
          this.validateUserData(true);
          return resolve(true);
        }, 0);
      });
    },
    cloneData() {
      let { nome, sobrenome, nascimento, telefone, email, cpf_cnpj } =
        this.userData;

      this.localData = Object.assign(
        {},
        {
          nome,
          sobrenome,
          nascimento,
          telefone,
          email,
          cpf_cnpj,
        }
      );

      this.initialData = Object.assign({}, this.localData);
    },
  },
  async activated() {
    this.cloneData();
    await this.checkValidation();
  },
  created() {
    this.cloneData();
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 1.5rem;
}

.field-container {
  display: flex;
  flex-flow: column;
  gap: 1.25rem;
  flex-grow: 1;
}

.editingButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    width: 5rem;
  }
}

.feedback-transition-enter,
.feedback-transition-leave-to {
  height: 0;
  opacity: 0;
  transition: height 200ms cubic-bezier(0.86, 0.07, 0.35, 0.89) 150ms,
    opacity 150ms ease-in;
}

.feedback-transition-enter-to,
.feedback-transition-leave {
  height: 18px;
  opacity: 1;
  transition: height 200ms cubic-bezier(0.86, 0.07, 0.35, 0.89),
    opacity 150ms ease-in 200ms;
}
</style>
