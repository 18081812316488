<template>
  <div class="container">
    <div class="content-header">
      <h1 :class="{ fade: isChanging }">{{ pageTitle }}</h1>
    </div>
    <div class="security-content">
      <div class="image-container">
        <PadlockIcon />
      </div>
      <div class="text-container">
        <span>Compra</span>
        <span>Segura</span>
      </div>
    </div>
  </div>
</template>

<script>
import PadlockIcon from '@/components/icons/pageHeader/PadlockIcon.vue';

export default {
  name: 'PageHeader',
  components: {
    PadlockIcon,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    isChanging: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;

  @media screen and (max-width: $sm) {
    flex-flow: column;
    gap: 1.5rem;
  }

  .content-header {
    display: flex;
    flex-flow: column;
    gap: 1rem;

    @media screen and (max-width: $sm) {
      align-self: start;
    }

    h1 {
      font-weight: 700;
      opacity: 1;
      font-size: 1.75rem;
      transition: opacity 300ms;
      color: var(--dark-text-color);

      &.fade {
        opacity: 0;
      }

      @media screen and (max-width: $sm) {
        font-size: 1.5rem;
      }
    }
  }

  .security-content {
    display: flex;
    flex-flow: row;
    align-items: stretch;

    @media screen and (max-width: $sm) {
      align-self: flex-start;
    }

    .image-container {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 85%;
        fill: var(--security-badge-color);
      }
    }

    .text-container {
      span {
        display: block;
        font-size: 0.625rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.01em;
        color: var(--security-badge-color);

        & + span {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}
</style>