<template>
  <div class="method-content">
    <div class="credit-container">
      <label class="card-number">
        <span>Número do cartão</span>
        <input
          type="text"
          inputmode="numeric"
          data-property="cardNumber"
          id="cc-number"
          name="cc-number"
          autocomplete="cc-number"
          v-model="card.cardNumber"
          v-mask="['#### ###### ##### ', '#### #### #### ####']"
          :readonly="isAppLoading ? true : null"
          :disabled="fetchingInstallments"
          :class="{ error: validateFields.cardNumber === false }"
          @focus="handleFocus($event)"
          @blur="
            validateCardNumber($event);
            validateCard();
            showCheckout();
          "
        />
      </label>
      <label class="card-expiration-date">
        <span>Validade</span>
        <input
          type="text"
          inputmode="numeric"
          placeholder="MM/AAAA"
          data-property="cardExpirationDate"
          id="cc-exp"
          name="cc-exp"
          autocomplete="cc-exp"
          v-model="card.cardExpirationDate"
          v-mask="'##/####'"
          :readonly="isAppLoading ? true : null"
          :disabled="fetchingInstallments"
          :class="{
            error: validateFields.cardExpirationDate === false,
          }"
          @focus="handleFocus($event)"
          @blur="
            validateExpirationDate($event);
            validateCard();
            showCheckout();
          "
        />
      </label>
      <label class="card-security-code">
        <span>CVV</span>
        <input
          type="text"
          inputmode="numeric"
          data-property="cardSecurityCode"
          id="cc-csc"
          name="cc-csc"
          autocomplete="cc-csc"
          v-model="card.cardSecurityCode"
          v-mask="'####'"
          :readonly="isAppLoading ? true : null"
          :disabled="fetchingInstallments"
          :class="{
            error: validateFields.cardSecurityCode === false,
          }"
          @focus="handleFocus($event)"
          @blur="
            validateSecurityCode($event);
            validateCard();
            showCheckout();
          "
        />
      </label>
      <label class="card-name">
        <span>Nome impresso no cartão</span>
        <input
          type="text"
          data-property="cardHolderName"
          id="cc-name"
          name="cc-name"
          autocomplete="cc-name"
          v-model="card.cardHolderName"
          :readonly="isAppLoading ? true : null"
          :disabled="fetchingInstallments"
          :class="{
            error: validateFields.cardHolderName === false,
          }"
          @focus="handleFocus($event)"
          @blur="
            validateCardHolderName($event);
            validateCard();
            showCheckout();
          "
        />
      </label>
      <label class="card-document">
        <span v-if="accountType === 'F'">CPF do titular</span>
        <span v-else>Documento do titular</span>
        <input
          type="text"
          inputmode="numeric"
          data-property="cardHolderDocument"
          v-model="card.cardHolderDocument"
          v-mask="
            accountType === 'J'
              ? ['###.###.###-##', '##.###.###/####-##']
              : '###.###.###-##'
          "
          :placeholder="accountType === 'F' ? '000.000.000-00' : 'CPF ou CNPJ'"
          :readonly="isAppLoading ? true : null"
          :disabled="fetchingInstallments"
          :class="{
            error: validateFields.cardHolderDocument === false,
          }"
          @focus="handleFocus($event)"
          @blur="
            validateCardHolderDocument($event);
            validateCard();
            showCheckout();
          "
        />
      </label>
      <label class="card-installments">
        <span>Parcelamento</span>
        <div class="select-wrapper">
          <select
            v-model="card.purchaseInstallments"
            :disabled="fetchingInstallments"
            :class="{ readonly: isAppLoading }"
            @change="updateCartValue()"
            @focus="hideCheckout()"
            @blur="showCheckout()"
          >
            <option
              disabled
              :value="card.purchaseInstallments"
              v-if="fetchingInstallments"
            >
              Carregando...
            </option>
            <option
              v-for="(value, key) in card.installments"
              :value="key"
              :key="key"
              :selected="key == 1"
            >
              {{ key }}x de {{ value.valor | currency }}
              <template v-if="value.porcentagem">
                com juros ({{ value.total | currency }})
              </template>
            </option>
          </select>
          <span class="arrow" :class="{ disabled: fetchingInstallments }">
            <SelectArrow />
          </span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validateText from '@/helpers/validateText.js';
import validateCPF from '@/helpers/validateCPF.js';
import validateCNPJ from '@/helpers/validateCNPJ.js';
import toggleCheckout from '@/mixins/toggleCheckout.js';
import SelectArrow from '@/components/icons/input/SelectArrow';

export default {
  name: 'getnet',
  mixins: [toggleCheckout],
  components: { SelectArrow },
  data() {
    return {
      fetchingInstallments: false,
      card: {
        cardNumber: null,
        formattedCardNumber: null,
        cardExpirationDate: null,
        cardExpirationMonth: null,
        cardExpirationYear: null,
        cardSecurityCode: null,
        cardHolderName: null,
        cardHolderDocumentType: null,
        cardHolderDocument: null,
        cardCompany: null,
        purchasePrice: 0,
        purchaseInstallments: 1,
        installments: {},
      },
      validateFields: {
        cardNumber: null,
        cardExpirationDate: null,
        cardSecurityCode: null,
        cardHolderName: null,
        cardHolderDocument: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'cartTotal',
      'selectedPayment',
      'isAppLoading',
      'accountType',
      'selectedInstallments',
    ]),
  },
  watch: {
    cartTotal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.card.purchasePrice = value.total_prazo.toFixed(2);
        this.card.installments = this.cartTotal.parcelas;
      },
    },
    'card.purchaseInstallments': {
      handler: function () {
        this.validateCard();

        this.updateSelectedInstallments({
          quantity: +this.card.purchaseInstallments,
          value: +this.card.installments[this.card.purchaseInstallments]?.valor,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
      updateSelectedInstallments: 'UPDATE_SELECTED_INSTALLMENTS',
    }),
    ...mapActions({
      updateCart: 'UPDATE_CART',
    }),
    handleFocus(element) {
      this.hideCheckout();

      this.validateFields[element.target.dataset.property] = null;
    },
    validateCardNumber(element) {
      const value = element.target.value.trim();
      const formattedValue = value.replace(/[^0-9]+/g, '');
      let cardCompany = null;

      if (formattedValue.length >= 15) {
        cardCompany = this.getCardCompany(formattedValue);

        this.card.cardCompany = cardCompany;

        this.card.formattedCardNumber = formattedValue;

        this.validateFields[element.target.dataset.property] = true;

        return true;
      }

      this.card.cardCompany = null;

      this.card.formattedCardNumber = null;

      this.validateFields[element.target.dataset.property] = false;

      return false;
    },
    getCardCompany(value) {
      const companies = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex: /^3[47][0-9]{13}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      };

      for (let company in companies) {
        if (companies[company].test(value)) {
          return company;
        }
      }

      return null;
    },
    validateExpirationDate(element = null) {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (
        !this.card[element.target.dataset.property] ||
        this.card[element.target.dataset.property].length < 7
      ) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      let [expirationMonth, expirationYear] =
        this.card[element.target.dataset.property].split('/');

      expirationMonth = +expirationMonth;
      expirationYear = +expirationYear;

      if (expirationMonth < 1 || expirationMonth > 12) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      if (expirationYear < year) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      if (expirationYear === year && expirationMonth < month) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      this.card['cardExpirationMonth'] = expirationMonth
        .toString()
        .padStart(2, '0');
      this.card['cardExpirationYear'] = expirationYear
        .toString()
        .substring(2, 4);

      this.validateFields[element.target.dataset.property] = true;
      return true;
    },
    validateSecurityCode(element) {
      const regex = /^[0-9]{3,4}$/;

      if (regex.test(element.target.value.trim())) {
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      this.validateFields[element.target.dataset.property] = false;
      return false;
    },
    validateCardHolderName(element) {
      if (validateText(element.target.value.trim(), '^[a-zA-ZÀ-ú ]*$')) {
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      this.validateFields[element.target.dataset.property] = false;
      return false;
    },
    validateCardHolderDocument(element) {
      if (validateCPF(element.target.value.trim())) {
        this.card.cardHolderDocumentType = 'cpf';
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      if (validateCNPJ(element.target.value.trim())) {
        this.card.cardHolderDocumentType = 'cnpj';
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      this.card.cardHolderDocumentType = null;
      this.validateFields[element.target.dataset.property] = false;
      return false;
    },
    validateCard() {
      const error = [];

      Object.entries(this.validateFields).forEach((entry) => {
        const [property, value] = entry;

        if (!value) {
          error.push(property);
        }
      });

      if (error.length) {
        this.validatePayment({
          isValid: false,
          paymentData: {},
        });

        return false;
      }

      this.validatePayment({
        isValid: true,
        paymentData: {
          cardNumber: this.card.formattedCardNumber,
          cardCompany: this.card.cardCompany,
          cardExpirationMonth: this.card.cardExpirationMonth,
          cardExpirationYear: this.card.cardExpirationYear,
          cardSecurityCode: this.card.cardSecurityCode,
          cardHolderName: this.card.cardHolderName,
          cardHolderDocumentType: this.card.cardHolderDocumentType,
          cardHolderDocument: this.card.cardHolderDocument,
          purchaseInstallments: this.card.purchaseInstallments,
        },
      });
      return true;
    },
    loadScript() {
      return new Promise((resolve, reject) => {
        const scriptAlreadyAdded = document.head.querySelectorAll(
          'script[src="https://h.online-metrix.net/fp/tags.js?org_id={AMBIENTE}&session_id={NUMERO DE SESSÃO}"]'
        );

        if (scriptAlreadyAdded.length) {
          return resolve();
        }

        let script = document.createElement('script');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute(
          'src',
          'https://h.online-metrix.net/fp/tags.js?org_id={AMBIENTE}&session_id={NUMERO DE SESSÃO}'
        );
        script.defer = true;
        script.async = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject('Failed to load gateway library');
        };

        document.head.appendChild(script);
      });
    },
    async updateCartValue() {
      if (!this.cartTotal.juros) return;

      this.fetchingInstallments = true;

      await this.updateCart({
        parcelas: this.card.purchaseInstallments,
      });

      this.fetchingInstallments = false;
    },
  },
  async mounted() {
    this.card.purchasePrice = this.cartTotal.total_prazo.toFixed(2);

    try {
      await this.loadScript();
      this.importStatus = true;
    } catch (error) {
      console.warn(error);
      this.importStatus = false;
    }
  },
  activated() {
    this.validateCard();
  },
};
</script>

<style scoped lang="scss">
.credit-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem 1.25rem;
  max-width: 35rem;

  .card-number,
  .card-name,
  .card-document,
  .card-installments {
    grid-column: span 2;
  }

  .card-expiration-date,
  .card-security-code {
    grid-column: 1fr;
  }

  @media screen and (max-width: $sm) {
    .card-number,
    .card-name,
    .card-document,
    .card-installments {
      grid-column: span 4;
    }

    .card-expiration-date,
    .card-security-code {
      grid-column: span 2;
    }
  }
}
</style>