<template>
  <div class="loading" :style="fontSize ? `font-size: ${fontSize}` : null">
    <span :style="dotColor ? `background-color: ${dotColor}` : null"></span>
    <span :style="dotColor ? `background-color: ${dotColor}` : null"></span>
    <span :style="dotColor ? `background-color: ${dotColor}` : null"></span>
    <span :style="dotColor ? `background-color: ${dotColor}` : null"></span>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    fontSize: {
      style: String,
    },
    dotColor: {
      style: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.loading {
  flex-grow: 1;
  width: 100%;
  height: 1em;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: 0.75em;

  span {
    display: block;
    width: 0.625em;
    height: 0.625em;
    background-color: var(--default-loading-color);
    border-radius: 50%;
    transform: translate3d(0, -0.5em, 0);
    animation: dots 0.6s cubic-bezier(0.6, 0.1, 1, 0.4) infinite alternate;

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes dots {
  from {
    transform: translate3d(0, -0.5em, 0);
  }
  to {
    transform: translate3d(0, 0.5em, 0);
  }
}
</style>