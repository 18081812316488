<template>
  <keep-alive>
    <component :is="component">
      <p><strong>Componente não encontrado</strong></p>
    </component>
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';
import toggleMethodAvailability from '@/mixins/toggleMethodAvailability.js';
import UnavailableMethod from '@/components/payment/UnavailableMethod.vue';
import boleto from '@/components/payment/no_gateway/Boleto.vue';

export default {
  name: 'BoletoContainer',
  mixins: [toggleMethodAvailability],
  components: {
    UnavailableMethod,
    boleto,
  },
  computed: {
    ...mapGetters(['selectedPayment']),
    component() {
      if (!this.isMethodAvailable) return 'UnavailableMethod';

      return 'boleto';
    },
  },
};
</script>