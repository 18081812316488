import axios from 'axios'
import Vue from 'vue'
import validateCEP from './validateCEP'

function requestViaCep(value) {
  return new Promise((resolve, reject) => {
    axios.get(`https://viacep.com.br/ws/${value.replace('-', '')}/json/`)
      .then(response => {
        if (response.data.erro) {
          reject()
        }

        const { cep, bairro, localidade, logradouro, uf } = response.data

        resolve({
          cep,
          bairro,
          uf,
          cidade: localidade,
          endereco: logradouro,
          numero: '',
          complemento: ''
        })
      })
      .catch(() => {
        reject()
      })
  })
}

function requestOpenCep(value) {
  return new Promise((resolve, reject) => {
    axios.get(`https://opencep.com/v1/${value.replace('-', '')}`)
      .then(response => {
        if (response.data.error) {
          reject()
        }

        const { cep, bairro, localidade, logradouro, uf } = response.data

        resolve({
          cep,
          bairro,
          uf,
          cidade: localidade,
          endereco: logradouro,
          numero: '',
          complemento: ''
        })
      })
      .catch(() => {
        reject()
      })
  })
}

function requestApiCep(value) {
  return new Promise((resolve, reject) => {
    axios.get(`https://ws.apicep.com/cep/${value}.json`)
      .then(response => {
        if (!response.data.ok) {
          reject()
        }

        resolve({
          cep: response.data.code,
          bairro: response.data.district,
          uf: response.data.state,
          cidade: response.data.city,
          endereco: response.data.address.split(' - ')[0],
          numero: '',
          complemento: ''
        })
      })
      .catch(() => {
        reject()
      })
  })
}

export default async function (inputValue) {
  if (inputValue === undefined || inputValue === '') return false

  if (!validateCEP(inputValue)) return false

  try {
    return await Promise.any([
      requestViaCep(inputValue),
      requestOpenCep(inputValue),
      requestApiCep(inputValue)
    ])
  } catch (err) {
    Vue.$toast.error('Falha na consulta do CEP')

    return null
  }
}