<template>
  <div class="main-input-container">
    <form class="main-input" @submit="(event) => event.preventDefault()">
      <input
        type="text"
        class="main-input"
        v-mask="mask"
        v-model="localInputValue"
        :id="inputId"
        :name="inputName"
        :autocomplete="inputAutocomplete"
        :disabled="isLoading || isDisabled || isAppLoading"
        :inputmode="inputMode"
        v-on:keydown.enter="handleClick()"
        @focus="hideCheckout()"
        @blur="showCheckout()"
      />
      <button
        type="button"
        :disabled="isLoading || isDisabled || isAppLoading"
        @click="handleClick()"
      >
        <transition mode="out-in" name="opacity">
          <Loading v-if="isLoading" fontSize="8px" />
          <span v-else>{{ buttonText }}</span>
        </transition>
      </button>
    </form>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toggleCheckout from '@/mixins/toggleCheckout.js';

export default {
  name: 'TextButton',
  mixins: [toggleCheckout],
  data() {
    return {
      localInputValue: '',
    };
  },
  props: {
    buttonText: {
      type: String,
      require: true,
    },
    inputValue: {
      type: String,
      require: false,
    },
    inputMode: {
      type: String,
      default: null,
    },
    inputName: {
      type: String,
      default: null,
    },
    inputId: {
      type: String,
      default: null,
    },
    inputAutocomplete: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
    },
    updateInputValue: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters(['isAppLoading']),
  },
  watch: {
    inputValue(value) {
      this.localInputValue = value;
    },
    updateInputValue(value) {
      if (value) {
        this.localInputValue = this.inputValue;
        this.$emit('returnUpdate', false);
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit('updateInputValue', this.localInputValue);
    },
  },
  created() {
    if (this.inputValue) {
      this.localInputValue = this.inputValue;
    }
  },
};
</script>

<style scoped lang="scss">
.main-input-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .main-input {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;

    input {
      border-radius: 0.25rem 0 0 0.25rem;
      font-weight: 500;
      width: 7.5rem;
      min-height: 31px;
      z-index: 1;
      border: 1px solid var(--input-border-color) !important;
      box-shadow: 0 0 0 0 var(--input-border-color-hover);
      transition: 150ms ease-out;
      transition-property: box-shadow, filter, padding;

      &:focus {
        box-shadow: 0 0 0 0.125rem var(--input-border-color-hover);
      }

      &:disabled {
        filter: brightness(0.98);
        padding: 0.25rem 0.5rem;
      }
    }

    button {
      position: relative;
      background-color: var(--input-button-background);
      border: 1px solid var(--input-border-color);
      border-left: none;
      border-radius: 0 0.25rem 0.25rem 0;
      font-size: 0.75rem;
      font-weight: 600;
      padding: 0.25rem 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: filter 150ms ease-out;
      width: 5rem;
      outline: none;

      span {
        color: var(--input-button-color);
        text-transform: lowercase;
      }

      &:focus,
      &:hover {
        filter: brightness(0.95);
      }

      &:active {
        filter: brightness(0.9);
      }

      &:disabled {
        cursor: initial;

        &:hover {
          filter: none;
        }
      }
    }
  }
}
</style>