<template>
  <div class="method-content">
    <transition mode="out-in" name="opacity">
      <div class="credit-container" v-if="importStatus">
        <label class="card-number">
          <span>Número do cartão</span>
          <input
            type="text"
            inputmode="numeric"
            data-property="cardNumber"
            id="cc-number"
            name="cc-number"
            autocomplete="cc-number"
            v-model="card.cardNumber"
            v-mask="['#### ###### ##### ', '#### #### #### ####']"
            :readonly="isAppLoading ? true : null"
            :disabled="fetchingInstallments"
            :class="{ error: validateFields.cardNumber === false }"
            @focus="handleFocus($event)"
            @blur="
              validateCard();
              validateCardNumber($event);
              showCheckout();
            "
          />
        </label>
        <label class="card-expiration-date">
          <span>Validade</span>
          <input
            type="text"
            inputmode="numeric"
            placeholder="MM/AAAA"
            data-property="cardExpirationDate"
            id="cc-exp"
            name="cc-exp"
            autocomplete="cc-exp"
            v-model="card.cardExpirationDate"
            v-mask="'##/####'"
            :readonly="isAppLoading ? true : null"
            :disabled="fetchingInstallments"
            :class="{
              error: validateFields.cardExpirationDate === false,
            }"
            @focus="handleFocus($event)"
            @blur="
              validateExpirationDate($event);
              validateCard();
              showCheckout();
            "
          />
        </label>
        <label class="card-security-code">
          <span>CVV</span>
          <input
            type="text"
            inputmode="numeric"
            data-property="cardSecurityCode"
            id="cc-csc"
            name="cc-csc"
            autocomplete="cc-csc"
            v-model="card.cardSecurityCode"
            v-mask="'####'"
            :readonly="isAppLoading ? true : null"
            :disabled="fetchingInstallments"
            :class="{
              error: validateFields.cardSecurityCode === false,
            }"
            @focus="handleFocus($event)"
            @blur="
              validateSecurityCode($event);
              validateCard();
              showCheckout();
            "
          />
        </label>
        <label class="card-name">
          <span>Nome impresso no cartão</span>
          <input
            type="text"
            data-property="cardHolderName"
            id="cc-name"
            name="cc-name"
            autocomplete="cc-name"
            v-model="card.cardHolderName"
            :readonly="isAppLoading ? true : null"
            :disabled="fetchingInstallments"
            :class="{
              error: validateFields.cardHolderName === false,
            }"
            @focus="handleFocus($event)"
            @blur="
              validateCardHolderName($event);
              validateCard();
              showCheckout();
            "
          />
        </label>
        <label class="card-installments">
          <span>Parcelamento</span>
          <div class="select-wrapper">
            <select
              v-model="card.purchaseInstallments"
              :disabled="fetchingInstallments || !installmentsQuantity"
              :class="{ readonly: isAppLoading }"
              @change="updateCartValue()"
              @focus="hideCheckout()"
              @blur="showCheckout()"
            >
              <option
                disabled
                :value="card.purchaseInstallments"
                v-if="fetchingInstallments"
              >
                Carregando...
              </option>
              <option
                v-for="(value, key) in card.installments"
                :value="key"
                :key="key"
                :selected="key == 1"
              >
                {{ key }}x de {{ value.valor | currency }}
                <template v-if="value.porcentagem">
                  com juros ({{ value.total | currency }})
                </template>
              </option>
            </select>
            <span
              class="arrow"
              :class="{
                disabled: fetchingInstallments || !installmentsQuantity,
              }"
            >
              <SelectArrow />
            </span>
          </div>
        </label>
      </div>
      <p v-else>
        Método de pagamento indisponível no momento. Tente novamente em alguns
        minutos ou
        <a :href="$baseURL + '/central-de-atendimento'">fale conosco</a>.
      </p>
    </transition>
  </div>
</template>

<script>
import { api } from '@/services.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validateText from '@/helpers/validateText.js';
import toggleCheckout from '@/mixins/toggleCheckout.js';
import SelectArrow from '@/components/icons/input/SelectArrow';

export default {
  name: 'pagseguro',
  mixins: [toggleCheckout],
  components: { SelectArrow },
  data() {
    return {
      importStatus: true,
      transactionToken: null,
      fetchingInstallments: false,
      card: {
        cardNumber: null,
        formattedCardNumber: null,
        cardExpirationDate: null,
        cardExpirationMonth: null,
        cardExpirationYear: null,
        cardSecurityCode: null,
        cardHolderName: null,
        purchasePrice: 0,
        purchaseInstallments: null,
        installments: {},
      },
      validateFields: {
        cardNumber: null,
        cardExpirationDate: null,
        cardSecurityCode: null,
        cardHolderName: null,
        installments: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'cartTotal',
      'selectedPayment',
      'isAppLoading',
      'invalidateCardToken',
      'selectedInstallments',
    ]),
    installmentsQuantity: function () {
      return typeof this.card.installments == 'object'
        ? Object.keys(this.card.installments).length
        : 0;
    },
  },
  watch: {
    cartTotal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.card.purchasePrice = value.total_prazo.toFixed(2);
        this.getInstallments();
      },
    },
    'card.purchaseInstallments': function () {
      this.validateCard();
    },
    invalidateCardToken(value) {
      if (value) {
        this.validateCard();
      }
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
      resetInvalidationStatus: 'INVALIDATE_CARD_TOKEN',
      updateNavigation: 'UPDATE_NAVIGATION',
      updateSelectedInstallments: 'UPDATE_SELECTED_INSTALLMENTS',
    }),
    ...mapActions({
      updateCart: 'UPDATE_CART',
    }),
    handleFocus(element) {
      this.hideCheckout();

      this.validateFields[element.target.dataset.property] = null;
    },
    async validateCardNumber(element) {
      const value = element.target.value.trim();
      const formattedValue = value.replace(/[^0-9]+/g, '');

      this.card.cardCompany = null;
      this.card.formattedCardNumber = null;
      this.validateFields[element.target.dataset.property] = null;

      if (formattedValue.length < 15) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      this.card.formattedCardNumber = formattedValue;

      this.$toast.info('Calculando parcelas');

      this.updateNavigation({ isLoading: true });
      const installmentsStatus = await this.getInstallments();
      this.updateNavigation({ isLoading: false });

      if (!installmentsStatus) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      this.validateFields[element.target.dataset.property] = true;

      this.validateCard();

      return true;
    },
    validateExpirationDate(element = null) {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      this.card['cardExpirationMonth'] = null;
      this.card['cardExpirationYear'] = null;

      if (
        !this.card[element.target.dataset.property] ||
        this.card[element.target.dataset.property].length < 7
      ) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      let [expirationMonth, expirationYear] =
        this.card[element.target.dataset.property].split('/');

      expirationMonth = +expirationMonth;
      expirationYear = +expirationYear;

      if (expirationMonth < 1 || expirationMonth > 12) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      if (expirationYear < year) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      if (expirationYear === year && expirationMonth < month) {
        this.validateFields[element.target.dataset.property] = false;
        return false;
      }

      this.card['cardExpirationMonth'] = expirationMonth
        .toString()
        .padStart(2, '0');
      this.card['cardExpirationYear'] = expirationYear.toString();

      this.validateFields[element.target.dataset.property] = true;
      return true;
    },
    validateSecurityCode(element) {
      const regex = /^[0-9]{3,4}$/;

      if (regex.test(element.target.value.trim())) {
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      this.validateFields[element.target.dataset.property] = false;
      return false;
    },
    validateCardHolderName(element) {
      if (validateText(element.target.value.trim(), '^[a-zA-ZÀ-ú ]*$')) {
        this.validateFields[element.target.dataset.property] = true;
        return true;
      }

      this.validateFields[element.target.dataset.property] = false;
      return false;
    },
    validateInstallments() {
      if (!this.card.purchaseInstallments) {
        this.validateFields.installments = false;
        return false;
      }

      if (
        !Object.keys(this.card.installments).some(
          this.card.purchaseInstallments
        )
      ) {
        this.validateFields.installments = false;
        return false;
      }

      this.validateFields.installments = true;
      return true;
    },
    async getInstallments() {
      if (
        !this.card.formattedCardNumber ||
        this.selectedPayment.method !== 'credito'
      )
        return;

      const cardBin = this.card.formattedCardNumber.substring(0, 6);

      this.fetchingInstallments = true;

      try {
        return await api
          .get(
            `/checkout/calculo_parcelas?bin=${cardBin}&parcelas=${this.card.purchaseInstallments}`
          )
          .then((res) => {
            this.card.installments = res.data.installments;

            if (this.card.purchaseInstallments === null) {
              this.card.purchaseInstallments = 1;
            }

            this.updateSelectedInstallments({
              quantity: +this.card.purchaseInstallments,
              value:
                +this.card.installments[this.card.purchaseInstallments]?.valor,
            });

            this.validateFields.installments = true;
            this.validateFields.cardNumber = true;
            this.fetchingInstallments = false;

            return true;
          });
      } catch (error) {
        this.$toast.error(error.response.data.message);
        this.validateFields.cardNumber = false;
        this.card.installments = Array();
        this.card.purchaseInstallments = null;
        this.fetchingInstallments = false;

        return false;
      }
    },
    validateCard() {
      const error = [];

      this.validatePayment({
        isValid: false,
        paymentData: {},
      });

      Object.entries(this.validateFields).forEach((entry) => {
        const [property, value] = entry;

        if (!value) {
          error.push(property);
        }
      });

      if (error.length) return false;

      this.transactionToken = this.createCardToken();
      this.resetInvalidationStatus(false);

      if (!this.transactionToken) {
        this.$toast.error('Verifique os dados do cartão');

        return false;
      }

      this.validatePayment({
        isValid: true,
        paymentData: {
          token: this.transactionToken,
          installments: this.card.purchaseInstallments,
          bin: this.card.formattedCardNumber.substring(0, 6),
          cvc: this.card.cardSecurityCode,
          holder: this.card.cardHolderName,
        },
      });
      return true;
    },
    createCardToken() {
      const data = {
        publicKey: this.selectedPayment.public_key,
        holder: this.card.cardHolderName,
        number: this.card.formattedCardNumber,
        expMonth: this.card.cardExpirationMonth,
        expYear: this.card.cardExpirationYear,
        securityCode: this.card.cardSecurityCode,
      };

      for (let key of Object.keys(data)) {
        if (!data[key]) return false;
      }

      const encryptedData = window.PagSeguro.encryptCard(data);

      if (encryptedData.hasErrors) {
        console.error(encryptedData.errors);
        this.$toast.error('Verifique os dados do cartão');

        return null;
      }

      return encryptedData.encryptedCard;
    },
    loadScript() {
      return new Promise((resolve, reject) => {
        const scriptAlreadyAdded = document.head.querySelectorAll(
          'script[src="https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js"]'
        );

        if (scriptAlreadyAdded.length) {
          return resolve();
        }

        let script = document.createElement('script');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute(
          'src',
          'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js'
        );
        script.defer = true;
        script.async = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject('Failed to load gateway library');
        };

        document.head.appendChild(script);
      });
    },
    async updateCartValue() {
      if (!this.card.formattedCardNumber) {
        this.$toast.error('Cartão inválido');
        return;
      }

      this.fetchingInstallments = true;

      await this.updateCart({
        parcelas: this.card.purchaseInstallments,
        bin: this.card.formattedCardNumber.substring(0, 6),
      });

      this.fetchingInstallments = false;

      this.updateSelectedInstallments({
        quantity: +this.card.purchaseInstallments,
        value: +this.card.installments[this.card.purchaseInstallments]?.valor,
      });
    },
  },
  async mounted() {
    this.card.purchasePrice = this.cartTotal.total_prazo.toFixed(2);

    try {
      await this.loadScript();
      this.importStatus = true;
    } catch (error) {
      console.warn(error);
      this.importStatus = false;
    }
  },
  activated() {
    this.validateCard();
  },
};
</script>

<style scoped lang="scss">
.credit-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem 1.25rem;
  max-width: 35rem;

  .card-number,
  .card-name,
  .card-document,
  .card-installments {
    grid-column: span 2;
  }

  .card-expiration-date,
  .card-security-code {
    grid-column: 1fr;
  }

  @media screen and (max-width: $sm) {
    .card-number,
    .card-name,
    .card-document,
    .card-installments {
      grid-column: span 4;
    }

    .card-expiration-date,
    .card-security-code {
      grid-column: span 2;
    }
  }
}
</style>