<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.5"
    height="9"
    viewBox="0 0 16.5 9"
  >
    <path
      d="M12,16.5a.748.748,0,0,1-.53-.22l-7.5-7.5A.75.75,0,0,1,5.03,7.72L12,14.689l6.97-6.97A.75.75,0,0,1,20.03,8.78l-7.5,7.5A.748.748,0,0,1,12,16.5Z"
      transform="translate(-3.75 -7.5)"
    />
  </svg>
</template>

<script>
export default {
  name: 'SelectArrow',
};
</script>