<template>
  <div class="container centered">
    <h2>Erro de conexão</h2>
    <p>
      Não conseguimos processar estas informações no momento. <br />
      Tente novamente mais tarde ou entre em contato conosco.
    </p>
    <p>Pedimos desculpas pelo inconveniente.</p>
    <div>
      <MainButton
        target="_self"
        buttonType="link"
        class="main-button"
        :buttonLink="$baseURL + '/central-de-atendimento'"
      >
        <span>Fale conosco</span>
      </MainButton>
      <MainButton
        target="_self"
        buttonType="link"
        :isSecondary="true"
        :buttonLink="$baseURL"
      >
        <span>Retornar à loja</span>
      </MainButton>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'ConnectionIssue',
  components: {
    MainButton,
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
  },
  created() {
    this.updateModal({
      isVisible: false,
      component: null,
      canClose: null,
    });
  },
};
</script>

<style scoped lang="scss">
.container {
  gap: 2rem;

  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    .link {
      font-size: 0.875rem;
      opacity: 0.9;
      color: var(--secondary-link-color);
      font-weight: 600;
      transition: all 150ms ease-out;

      &:hover,
      &:focus {
        opacity: 1;
        color: var(--secondary-link-color-hover);
      }
    }

    .main-button {
      margin: 1rem 1rem 0.25rem;
      padding: 0.25rem 1rem;
    }
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  text-align: center;
  color: var(--dark-text-color);

  @media screen and (max-width: $sm) {
    font-size: 2rem;
  }
}

p {
  line-height: 1.75rem;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  display: inline-block;
  max-width: 35rem;
  text-align: center;
  color: var(--normal-text-color);
}
</style>