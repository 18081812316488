<template>
  <div>
    <transition mode="out-in">
      <div v-if="!coupon" key="input">
        <TextButton
          buttonText="Adicionar"
          inputName="coupon"
          :isLoading="isLoading"
          @updateInputValue="handleAdd($event)"
        />
      </div>
      <div v-else class="coupon-container" key="coupon">
        <p class="coupon-title">
          {{ coupon.titulo }}
          <span v-if="coupon.valor">{{ coupon.valor }}</span>
        </p>
        <p class="coupon-description">{{ coupon.descricao }}</p>
        <button
          class="delete-button"
          @click="handleDelete()"
          :disabled="isAppLoading"
        >
          <transition mode="out-in" name="opacity">
            <Loading v-if="isLoading" fontSize="8px" />
            <span v-else>remover</span>
          </transition>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import TextButton from '@/components/input/TextButton.vue';

export default {
  name: 'CouponContent',
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    TextButton,
  },
  computed: {
    ...mapGetters(['cartDiscounts', 'isAppLoading']),
    coupon() {
      return this.cartDiscounts.descontos_aplicados?.cupom;
    },
  },
  methods: {
    ...mapMutations({
      updateNavigation: 'UPDATE_NAVIGATION',
    }),
    ...mapActions({
      addCoupon: 'ADD_COUPON',
      removeCoupon: 'REMOVE_COUPON',
    }),
    handleAdd(event) {
      if (event.trim() === '') {
        this.$toast.error('Nenhum cupom informado');
        return;
      }

      this.isLoading = true;
      this.updateNavigation({ couponLoading: true });

      this.addCoupon(event).then((response) => {
        this.isLoading = false;
        this.updateNavigation({ couponLoading: false });

        if (!response.success) {
          this.$toast.error(response.message);
        }
      });
    },
    handleDelete() {
      this.isLoading = true;

      this.removeCoupon().then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.coupon-container {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  gap: 0.5rem 1rem;
  overflow-y: hidden;

  .coupon-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--dark-text-color);

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--discount-badge-background);
      font-weight: 700;
      color: var(--discount-badge-color);
      line-height: 0.9;
      padding: 0 0.3em;
      border-radius: 0.1875rem;
      font-size: 0.71428571em;
      transform: translate(0.2857em, -0.1428em);
      height: 1.625em;
    }
  }

  .coupon-description {
    font-size: 0.75rem;
    color: var(--light-text-color);
    grid-column: 1 / 3;

    strong {
      color: var(--normal-text-color);
    }
  }

  .delete-button {
    grid-row: 1 / span 2;
    grid-column: 2 / 3;
    width: max-content;
    margin-left: auto;
    align-self: center;
    text-transform: lowercase;
    align-self: start;
    display: block;
    min-width: 4.1875rem;
  }
}
</style>