<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    viewBox="0 0 17 20"
  >
    <path
      d="M4.25,5V2.5A2.48,2.48,0,0,1,6.8,0h3.4a2.48,2.48,0,0,1,2.55,2.5V5h1.7A2.48,2.48,0,0,1,17,7.5v10A2.48,2.48,0,0,1,14.45,20H2.55A2.48,2.48,0,0,1,0,17.5V7.5A2.48,2.48,0,0,1,2.55,5Zm1.7,3.333a.8.8,0,0,1-.85.833.8.8,0,0,1-.85-.833V6.667H2.55A.8.8,0,0,0,1.7,7.5v10a.8.8,0,0,0,.85.833h11.9a.8.8,0,0,0,.85-.833V7.5a.8.8,0,0,0-.85-.833h-1.7V8.333a.85.85,0,0,1-1.7,0V6.667H5.95ZM11.05,5V2.5a.8.8,0,0,0-.85-.833H6.8a.8.8,0,0,0-.85.833V5Z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'CartButtonIcon',
};
</script>