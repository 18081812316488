<template>
  <ModalContentContainer class="content-container">
    <h2>Você foi atendido por um vendedor?</h2>
    <p>
      Antes de finalizar a compra, selecione uma das opções disponíveis no campo
      abaixo.
    </p>
    <div class="component-container">
      <SellerSelector font-size="1rem" />
    </div>
    <MainButton
      class="main-button"
      :isDisabled="!purchaseSeller.id"
      @click.native="closeModal"
    >
      <span>Continuar</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import SellerSelector from '@/components/SellerSelector.vue';
import MainButton from '../input/MainButton.vue';

export default {
  name: 'ModalSellerSelector',
  components: {
    ModalContentContainer,
    SellerSelector,
    MainButton,
  },
  computed: {
    ...mapGetters(['navigationData', 'purchaseSeller']),
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    closeModal() {
      if (this.purchaseSeller.id) {
        this.updateModal({
          isVisible: false,
          component: null,
          canClose: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  flex-flow: column;
  max-width: 25rem;

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    color: var(--dark-text-color);
  }

  p {
    font-size: 0.875rem;
    color: var(--light-text-color);
    margin-bottom: 2rem;
  }

  .component-container {
    width: 100%;
    margin-bottom: 2.5rem;
  }

  .main-button {
    width: 7.5rem;
    align-self: end;
  }
}
</style>