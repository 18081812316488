<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="22"
    viewBox="0 0 16 22"
  >
    <path
      d="M2.011,8.547h.223V5.633A5.338,5.338,0,0,1,4.961.762,6.263,6.263,0,0,1,8,0a6.263,6.263,0,0,1,3.039.762,5.338,5.338,0,0,1,2.726,4.871V8.547h.223A2.005,2.005,0,0,1,16,10.539v9.468A2.005,2.005,0,0,1,13.989,22H2.011A2.018,2.018,0,0,1,0,20V10.539A2.005,2.005,0,0,1,2.011,8.547Zm6.695,7.112v1.709a.711.711,0,0,1-1.421,0V15.659a1.591,1.591,0,0,1-.9-1.435,1.618,1.618,0,1,1,2.324,1.435ZM4.112,8.547h7.777V5.633a3.591,3.591,0,0,0-1.9-3.339,4.356,4.356,0,0,0-3.969,0,3.591,3.591,0,0,0-1.9,3.339V8.547Z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'PadlockIcon',
};
</script>