<template>
  <div class="main-payment-container">
    <div class="switch-method">
      <MainButton
        v-if="paymentList.some((el) => el === 'pix')"
        buttonType="switch"
        class="payment-button"
        :isDisabled="isAppLoading"
        :isActive="selectedPayment.method === 'pix'"
        @click.native="setPaymentMethod('pix')"
      >
        <PixIcon />
        <span>Pix</span>
      </MainButton>
      <MainButton
        v-if="paymentList.some((el) => el === 'boleto')"
        buttonType="switch"
        class="payment-button"
        :isDisabled="isAppLoading"
        :isActive="selectedPayment.method === 'boleto'"
        @click.native="setPaymentMethod('boleto')"
      >
        <BoletoIcon />
        <span>Boleto</span>
      </MainButton>
      <MainButton
        v-if="paymentList.some((el) => el === 'deposito')"
        buttonType="switch"
        class="payment-button"
        :isDisabled="isAppLoading"
        :isActive="selectedPayment.method === 'deposito'"
        @click.native="setPaymentMethod('deposito')"
      >
        <DepositoIcon />
        <span>Depósito</span>
      </MainButton>
      <MainButton
        v-if="paymentList.some((el) => el === 'credito')"
        buttonType="switch"
        class="payment-button"
        :isDisabled="isAppLoading"
        :isActive="selectedPayment.method === 'credito'"
        @click.native="setPaymentMethod('credito')"
      >
        <CreditoIcon />
        <span>Crédito</span>
      </MainButton>
      <MainButton
        v-if="paymentList.some((el) => el === 'dois_cartoes')"
        buttonType="switch"
        class="payment-button"
        :isDisabled="isAppLoading"
        :isActive="selectedPayment.method === 'dois_cartoes'"
        @click.native="setPaymentMethod('dois_cartoes')"
      >
        <DoisCartoesIcon />
        <span>2 Cartões</span>
      </MainButton>
    </div>
    <transition mode="out-in">
      <keep-alive>
        <component :is="selectedPayment.method" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MainButton from '@/components/input/MainButton.vue';
import credito from '@/components/payment/CreditoContainer.vue';
import dois_cartoes from '@/components/payment/DoisCartoesContainer.vue';
import boleto from '@/components/payment/BoletoContainer.vue';
import deposito from '@/components/payment/DepositoContainer.vue';
import pix from '@/components/payment/PixContainer.vue';
import PixIcon from '@/components/icons/paymentBox/PixIcon.vue';
import BoletoIcon from '@/components/icons/paymentBox/BoletoIcon.vue';
import DepositoIcon from '@/components/icons/paymentBox/DepositoIcon.vue';
import CreditoIcon from '@/components/icons/paymentBox/CreditoIcon.vue';
import DoisCartoesIcon from '@/components/icons/paymentBox/DoisCartoesIcon.vue';

export default {
  name: 'PaymentBox',
  components: {
    MainButton,
    credito,
    dois_cartoes,
    boleto,
    deposito,
    pix,
    PixIcon,
    BoletoIcon,
    DepositoIcon,
    CreditoIcon,
    DoisCartoesIcon,
  },
  computed: {
    ...mapGetters([
      'selectedPayment',
      'paymentOptions',
      'cartTotal',
      'isAppLoading',
      'selectedInstallments',
    ]),
    paymentList() {
      return Object.keys(this.paymentOptions);
    },
  },
  methods: {
    ...mapMutations({
      updateNavigation: 'UPDATE_NAVIGATION',
    }),
    ...mapActions({
      updatePaymentMethod: 'UPDATE_PAYMENT_METHOD',
    }),
    async setPaymentMethod(method) {
      if (this.selectedPayment.method === method) return;

      let selectedMethod = { method };

      this.updateNavigation({
        isLoading: true,
        paymentLoading: true,
      });

      await this.updatePaymentMethod(selectedMethod);

      this.updateNavigation({
        isLoading: false,
        paymentLoading: false,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main-payment-container {
  display: flex;
  flex-flow: column;
  gap: 2.5rem;
  flex-grow: 1;
}

.switch-method {
  display: flex;
  flex-flow: row wrap;
  gap: 1.25rem;

  @media screen and (max-width: $sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem 10vw;
  }
}

.payment-button {
  min-width: 7.5rem;
  min-height: 2.5rem;
}

.method-content {
  flex-grow: 1;

  ::v-deep h2 {
    color: var(--dark-text-color);
  }

  ::v-deep p {
    font-size: 0.875rem;
    color: var(--dark-text-color);

    strong {
      color: inherit;
    }

    a {
      font-weight: 600;
      color: var(--main-link-color);
      transition: color 150ms ease-out;

      &:hover,
      &:focus {
        color: var(--main-link-color-hover);
      }
    }
  }

  ::v-deep input,
  ::v-deep select {
    &:disabled {
      box-shadow: 0 0 0 0.06125rem var(--input-border-color);
      filter: brightness(0.98);
      padding: 0.25rem 0.5rem;
    }
  }

  ::v-deep p + p {
    margin-top: 0.75rem;
  }

  ::v-deep label {
    span {
      color: var(--dark-text-color);
    }

    input,
    select {
      min-height: 1.8125rem;
    }

    input,
    select,
    option {
      color: var(--normal-text-color);

      &.error {
        box-shadow: 0 0 0 0.06125rem var(--alert-color);
      }
    }

    .select-wrapper {
      position: relative;

      select {
        padding-right: 1.75rem;
      }

      .arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1.5rem;
        background: var(--select-arrow-background);
        border-radius: 0 0.25rem 0.25rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        svg {
          width: 50%;
          height: auto;
          fill: var(--select-arrow-fill);
          transition: opacity 150ms;
        }

        &.disabled svg {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>