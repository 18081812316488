import axios from 'axios';
import store from '../store/index.js';

export default async function (paymentData) {
  const selectedPayment = store.getters.selectedPayment

  if (selectedPayment.gateway !== 'pagarme') return

  try {
    const response = await axios.post(`https://api.pagar.me/core/v5/tokens?appId=${selectedPayment.public_key}`, {
      "type": "card",
      "card": {
        "number": paymentData.cardNumber,
        "holder_name": paymentData.cardHolderName,
        "holder_document": paymentData.cardHolderDocument,
        "exp_month": +paymentData.cardExpirationMonth,
        "exp_year": +paymentData.cardExpirationYear,
        "cvv": paymentData.cardSecurityCode
      }
    })

    return {
      token: response.data.id,
      installments: paymentData.purchaseInstallments
    }

  } catch (err) {
    const errors = err.response.data.errors

    console.log(errors)

    if (errors['request.card.number']) {
      throw new Error('Verifique o número do cartão')
    }

    if (errors['request.card.holder_name']) {
      throw new Error('Verifique o nome impresso no cartão')
    }

    if (errors['request.card.exp_month'] || errors['request.card.exp_year']) {
      throw new Error('Verifique o vencimento do cartão')
    }

    if (errors['request.card.cvv']) {
      throw new Error('Código de segurança do cartão inválido')
    }

    if (errors['request.card']) {
      throw new Error('Verifique os dados do cartão')
    }

    throw new Error('Erro ao processar pagamento')
  }

}