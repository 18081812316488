<template>
  <div class="unitary">
    <p class="unitary-text" v-if="item.valores.valor_unitario">
      {{ item.valores.valor_unitario | currency }} por unidade
    </p>

    <div class="highlight-container" v-if="cartDiscounts.descontos_aplicados">
      <p
        class="highlight-discount payment-method"
        v-if="cartDiscounts.descontos_aplicados.metodo_pagamento"
      >
        <PaymentMethodDiscountIcon />
        <span
          v-if="cartDiscounts.descontos_aplicados.metodo_pagamento.descricao"
        >
          {{ cartDiscounts.descontos_aplicados.metodo_pagamento.descricao }}
        </span>
        <span v-else>
          {{ cartDiscounts.descontos_aplicados.metodo_pagamento.valor }} de
          desconto via {{ selectedPayment.nome_formatado }}
        </span>
      </p>
      <p
        class="highlight-discount progressive"
        v-if="
          item.valores.regra_utilizada == 'desconto_progressivo' &&
          cartDiscounts.descontos_aplicados.progressivo.descricao
        "
      >
        <ProgressiveDiscountIcon />
        <span v-if="cartDiscounts.descontos_aplicados.progressivo.descricao">
          {{ cartDiscounts.descontos_aplicados.metodo_pagamento.descricao }}
        </span>
        <span v-else>
          {{ cartDiscounts.progressivo.porcentagem }}% de desconto progressivo
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import PaymentMethodDiscountIcon from '@/components/icons/cartBox/PaymentMethodDiscountIcon.vue';
import ProgressiveDiscountIcon from '@/components/icons/cartBox/ProgressiveDiscountIcon.vue';

export default {
  name: 'ItemDescription',
  components: {
    PaymentMethodDiscountIcon,
    ProgressiveDiscountIcon,
  },
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters(['cartDiscounts', 'selectedPayment']),
  },
  methods: {
    ...mapMutations({
      openModal: 'UPDATE_MODAL',
    }),
  },
};
</script>

<style lang="scss" scoped>
.unitary {
  margin: 0.5rem 0;
  width: max-content;

  .unitary-text {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--light-text-color);
  }
}

.highlight-container {
  display: flex;
  flex-flow: column;
  gap: 0.375rem;
  margin: 0.5rem 0;

  .highlight-discount {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.375rem;
    width: max-content;
    font-size: 0.75rem;
    font-weight: 600;

    &.progressive {
      svg {
        fill: var(--progressive-discount-color);
      }

      span {
        color: var(--progressive-discount-color);
      }
    }

    &.payment-method {
      padding: 0.125rem 0.5rem;
      border-radius: 0.25rem;
      background-color: var(--payment-method-discount-background);

      svg {
        fill: var(--payment-method-discount-color);
      }

      span {
        color: var(--payment-method-discount-color);
      }
    }
  }
}
</style>