<template>
  <div class="checkout-item" :class="{ highlight: isHighlight }">
    <div class="main-text">
      <p class="item-title">
        {{ itemTitle }}
        <slot name="header"></slot>
      </p>
      <p
        class="item-value"
        :class="{ price: isHighlight }"
        v-if="itemValue !== null"
      >
        {{ typeof itemValue == 'string' ? itemValue : itemValue | currency }}
      </p>
    </div>
    <div class="item-info">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutItem',
  props: {
    itemTitle: {
      type: String,
      require: true,
    },
    itemValue: {
      type: [Number, String],
      require: false,
      default: null,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.checkout-item {
  & + .checkout-item {
    margin-top: 1rem;
  }

  .main-text {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 1rem;

    p {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--dark-text-color);
    }
  }

  .item-info p {
    display: inline-block;
    font-size: 0.75rem;
    color: var(--light-text-color);
    padding-top: 0.25rem;
    width: 100%;
  }

  &.highlight {
    .main-text p {
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: -0.01em;
      color: var(--dark-text-color);
    }

    .item-info {
      p {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
        color: var(--light-text-color);
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-weight: 500;
        text-decoration: underline;
        letter-spacing: -0.01em;
        transition: color 150ms ease-out;
        color: var(--main-link-color);
        font-size: 0.75rem;

        &:hover,
        &:focus {
          color: var(--main-link-color-hover);
        }
      }
    }
  }
}
</style>