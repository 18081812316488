import store from '../store/index.js'

export default async function () {
  const websiteKey = store.state.config && store.state.config.recaptcha ? store.state.config.recaptcha.chave_site : null

  if (!websiteKey) return null

  const token = await window.grecaptcha.execute(websiteKey)

  return { token_recaptcha: token }
}