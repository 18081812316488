<template>
  <div class="container centered finish">
    <component :is="selectedPayment.method" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import credito from '@/components/finish/Credito.vue';
import boleto from '@/components/finish/Boleto.vue';
import deposito from '@/components/finish/Deposito.vue';
import pix from '@/components/finish/Pix.vue';
import dois_cartoes from '@/components/finish/DoisCartoes.vue';

export default {
  name: 'Finish',
  components: {
    credito,
    boleto,
    deposito,
    pix,
    dois_cartoes,
  },
  computed: {
    ...mapGetters(['selectedPayment']),
  },
};
</script>

<style lang="scss">
.finish {
  .box {
    padding: 2rem 4rem 3rem;
    background-color: var(--content-box-background);
    max-width: 60rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 0.25rem;
    gap: 2rem;

    @media screen and (max-width: $sm) {
      padding: 2rem 1.5rem;
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      color: var(--dark-text-color);

      @media screen and (max-width: $sm) {
        font-size: 1.75rem;
      }
    }

    p {
      text-align: center;
      line-height: 1.75rem;
      color: var(--normal-text-color);
    }

    a:not(.main-button) {
      font-weight: 600;
      color: var(--main-link-color);
      transition: color 150ms ease-out;

      &:hover,
      &:focus {
        color: var(--main-link-color-hover);
      }
    }

    ul {
      list-style: none;

      li {
        position: relative;
        color: var(--normal-text-color);

        &:before {
          position: relative;
          content: '- ';
        }

        & + li {
          margin-top: 0.375rem;
        }
      }
    }

    strong {
      color: inherit;
    }

    .button-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 0.75rem;
    }

    .link {
      color: var(--secondary-link-color);
      text-decoration: underline;
      font-size: 0.875rem;
      font-weight: 500;
      transition: color 150ms ease-out;

      &:hover,
      &:focus {
        color: var(--secondary-link-color-hover);
      }
    }

    .payment-data-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 0.25rem;

      div {
        background-color: var(--content-box-background);
        padding: 2rem 1.5rem;
        border-radius: 0.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 1rem;
        box-shadow: var(--payment-data-container-box-shadow);
        border: 1px solid var(--content-box-background);
        width: 100%;
        max-width: 25rem;

        img {
          max-width: 15rem;
          height: auto;
        }

        p {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      .expires {
        font-weight: 600;
        color: var(--alert-color);
        font-size: 0.75rem;
      }
    }
  }
}
</style>