<template>
  <div class="box-container">
    <ContentBox
      ref="userData"
      :title="
        accountType === 'F' ? 'Dados do destinatário' : 'Dados da empresa'
      "
      :isEditing="!isUserDisabled"
    >
      <template #action>
        <button
          v-if="isUserDisabled"
          class="action-button"
          :disabled="isAppLoading"
          @click="isUserDisabled = false"
        >
          Alterar
        </button>
      </template>
      <UserBox
        v-if="accountType === 'F'"
        :isDisabled="isUserDisabled"
        @update:isUserDisabled="isUserDisabled = $event"
      />
      <CompanyBox
        v-else
        :isDisabled="isUserDisabled"
        @update:isUserDisabled="isUserDisabled = $event"
      />
    </ContentBox>

    <ContentBox
      ref="deliveryData"
      title="Endereço de entrega"
      :isEditing="!isDeliveryDisabled.isClosed"
    >
      <template #action>
        <button
          v-if="isDeliveryDisabled.isClosed && accountType === 'F'"
          class="action-button"
          :disabled="isAppLoading"
          @click="isDeliveryDisabled.isClosed = false"
        >
          Alterar
        </button>
      </template>
      <DeliveryBox
        :isDisabled="isDeliveryDisabled.isClosed"
        @update:isDeliveryDisabled="isDeliveryDisabled = $event"
      />
    </ContentBox>

    <ContentBox
      title="Métodos de pagamento"
      :isLarge="true"
      :isLoading="navigationData.paymentLoading"
      style="min-height: 25rem"
    >
      <template #action>
        <div class="security-badge">
          <PadlockIcon />
          <span>Transação segura</span>
        </div>
      </template>
      <PaymentBox />
    </ContentBox>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ContentBox from '@/components/ContentBox.vue';
import UserBox from '@/components/UserBox.vue';
import CompanyBox from '@/components/CompanyBox.vue';
import DeliveryBox from '@/components/DeliveryBox.vue';
import PaymentBox from '@/components/payment/PaymentBox.vue';
import PadlockIcon from '@/components/icons/paymentBox/PadlockIcon.vue';

export default {
  name: 'Payment',
  data() {
    return {
      isUserDisabled: true,
      isDeliveryDisabled: {
        isClosed: true,
        keepModalOpen: false,
      },
    };
  },
  components: {
    ContentBox,
    UserBox,
    CompanyBox,
    DeliveryBox,
    PaymentBox,
    PadlockIcon,
  },
  computed: {
    ...mapGetters(['isAppLoading', 'navigationData', 'accountType']),
  },
  watch: {
    isUserDisabled(value) {
      this.openFilter(!value);

      const userData = this.$refs.userData.$el;
      this.scrollToElement(userData);
    },
    isDeliveryDisabled: {
      deep: true,
      handler(value) {
        if (!value.isClosed) {
          this.openFilter(true);

          const deliveryData = this.$refs.deliveryData.$el;
          this.scrollToElement(deliveryData);

          return;
        }

        if (value.isClosed && !value.keepModalOpen) {
          this.openFilter(false);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    openFilter(value) {
      if (value) {
        this.updateModal({
          isVisible: true,
          component: null,
          canClose: false,
        });

        return;
      }

      this.updateModal({
        isVisible: false,
        component: null,
        canClose: null,
      });
    },
    scrollToElement(el) {
      const yPosition =
        window.pageYOffset + el.getBoundingClientRect().top - 32;
      window.scrollTo({
        top: yPosition,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.action-button {
  background-color: transparent;
  padding: 0.25rem;
  border-radius: 0.125rem;
  border: none;
  font-weight: 600;
  color: var(--main-link-color);
  text-decoration: underline;
  font-size: 0.75rem;
  cursor: pointer;
  transition: color 0.2s ease-out;

  &:hover,
  &:focus {
    color: var(--main-link-color-hover);
  }
}

.security-badge {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: 0.325rem;

  @media screen and (max-width: $sm) {
    display: none;
  }

  svg {
    height: 0.875rem;
    width: auto;
    fill: var(--security-badge-color);
  }

  span {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1;
    display: block;
    padding-top: 0.125rem;
    color: var(--security-badge-color);
  }
}
</style>