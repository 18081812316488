<template>
  <ModalContentContainer
    class="content-container"
    modalTitle="Frete a combinar"
  >
    <p class="text">
      Nossa tabela de frete não possui uma opção calculada para o CEP informado.
      Entre em contato conosco através do WhatsApp abaixo para calcularmos uma
      opção de entrega para você.
    </p>
    <MainButton
      :buttonLink="`https://wa.me/+${storeConfig.contact.whatsapp.raw}`"
      buttonTarget="_blank"
      buttonType="link"
    >
      <WhatsAppIcon />
      <span>Fale conosco</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import MainButton from '@/components/input/MainButton.vue';
import WhatsAppIcon from '@/components/icons/modal/WhatsAppIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalContentFob',
  components: {
    ModalContentContainer,
    MainButton,
    WhatsAppIcon,
  },
  computed: {
    ...mapGetters(['storeConfig']),
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  max-width: 40rem;

  .text {
    max-width: 25rem;
    font-size: 0.875rem;
    color: var(--light-text-color);
    margin-bottom: 1rem;
  }

  ::v-deep .main-button {
    width: fit-content;

    svg {
      width: auto;
      height: 1.125rem;
    }
  }
}
</style>