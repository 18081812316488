<template>
  <div class="box">
    <h2>Compra concluída</h2>
    <p>
      <span v-html="deliveryDate"></span>.
      <br />
      Agradecemos a preferência
    </p>
    <MainButton
      buttonType="link"
      buttonTarget="_self"
      :buttonLink="$baseURL + '/minha-conta'"
    >
      <span>Painel do usuário</span>
    </MainButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ebitFunctions from '@/mixins/ebitFunctions.js';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'dois_cartoes',
  mixins: [ebitFunctions],
  components: {
    MainButton,
  },
  computed: {
    ...mapGetters(['selectedFreight', 'storeConfig']),
    deliveryDate() {
      if (this.selectedFreight.mensagem) return this.selectedFreight.mensagem;

      let date = new Date();
      date.setDate(date.getDate() + +this.selectedFreight.entrega + 1);

      const formattedDate = date.toLocaleDateString('pt-BR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      });

      const deadline =
        this.selectedFreight.entrega > 1
          ? `${this.selectedFreight.entrega} dias úteis`
          : `${this.selectedFreight.entrega} dia útil`;

      if (this.selectedFreight.alias == 'retirar') {
        return `Você poderá retirar sua encomenda em <strong>${deadline}</strong>.`;
      } else if (this.selectedFreight.alias == 'fob') {
        return `Caso o frete ainda não tenha sido combinado, entre em contato pelo número 
        <a href=https://wa.me/+${this.storeConfig.contact.whatsapp.raw} target=_blank>
          ${this.storeConfig.contact.whatsapp.pretty}
        </a>`;
      }

      return `Sua encomenda chegará <strong>até ${formattedDate}</strong>`;
    },
  },
  mounted() {
    this.ebitInit();
  },
};
</script>

<style>
</style>