<template>
  <div
    class="box"
    :class="{ 'large-box': isLarge, editing: isEditing, sticky: isSticky }"
    :style="{
      height: checkoutHeight ? `${checkoutHeight}px` : null,
      'border-radius': checkoutHeight ? '0.25rem 0.25rem 0 0' : null,
      'z-index': zIndex ? '10' : '1',
    }"
  >
    <div class="box-content">
      <div class="box-header">
        <p>
          {{ title }}
        </p>
        <slot name="action"></slot>
      </div>
      <slot></slot>
    </div>
    <slot name="button"></slot>
    <transition name="opacity">
      <div class="component-loading" v-if="isLoading">
        <Loading />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ContentBox',
  data() {
    return {
      zIndex: false,
      localTimeout: 0,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    checkoutHeight: {
      type: Number,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isEditing(value) {
      clearTimeout(this.localTimeout);

      if (!value) {
        this.localTimeout = setTimeout(() => {
          this.zIndex = false;
        }, 150);

        return;
      }

      this.zIndex = true;
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  position: relative;
  background-color: var(--content-box-background);
  border-radius: 0.25rem;
  padding: 0px;
  box-shadow: var(--content-box-shadow);
  transition: box-shadow 150ms ease;

  @media screen and (min-width: $lg) {
    &.sticky {
      position: relative;
      position: sticky;
      top: 2rem;
    }
  }

  .box-content {
    padding: 1.25rem 1.25rem 1.5rem;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-flow: column;

    .box-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      p {
        font-size: 1.25rem;
        font-weight: 600;
        letter-spacing: -0.01em;
        display: block;
        width: max-content;
        color: var(--dark-text-color);
      }
    }
  }

  &.large-box {
    grid-column: span 2;

    @media screen and (max-width: $md) {
      grid-column: unset;
    }
  }

  .component-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-filter-color);
    z-index: 10;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 0.25rem;
  }
}
</style>