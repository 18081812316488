<template>
  <div
    class="checkout-container"
    :class="{ hidden: navigationData.hideMobileCheckout || hiddenCheckout }"
    :style="{ height: isCheckoutVisible ? '120vh' : null }"
    @click="closeCheckoutFromFilter($event)"
  >
    <ContentBox
      title="Resumo"
      :checkoutHeight="isCheckoutVisible ? checkoutHeight : null"
      :isSticky="true"
    >
      <template>
        <CheckoutItem itemTitle="Subtotal" :itemValue="cartTotal.subtotal" />

        <CheckoutItem
          v-if="cartDiscounts.descontos_aplicados"
          itemTitle="Descontos"
          :itemValue="cartDiscounts.total > 0 ? -cartDiscounts.total : null"
        >
          <p v-for="discount in discountList" :key="discount.titulo">
            {{ discount.titulo }}
            <span v-if="discount.valor"> - {{ discount.valor }}</span>
          </p>
        </CheckoutItem>

        <transition>
          <CheckoutItem
            itemTitle="Juros"
            :itemValue="cartTotal.total_juros"
            v-if="selectedPayment.pagamento == 'prazo' && cartTotal.total_juros"
          >
            <p>Pagamento via {{ selectedPayment.nome_formatado }}</p>
          </CheckoutItem>
        </transition>

        <transition mode="out-in">
          <CheckoutItem
            v-if="selectedFreight && selectedFreight.alias == 'fob'"
            itemTitle="Frete"
            key="hasFreight"
            itemValue="Não incluso"
          >
            <template #header>
              <button
                @click="changeFreight"
                class="item-button"
                :disabled="isAppLoading"
              >
                Alterar
              </button>
            </template>
            <button class="fob-button" @click="showFobModal">
              Clique aqui para combinar
            </button>
          </CheckoutItem>
          <CheckoutItem
            v-else-if="selectedFreight"
            itemTitle="Frete"
            key="hasFreight"
            :itemValue="
              selectedFreight.gratis ? 'Grátis' : selectedFreight.valor
            "
          >
            <template #header>
              <button
                @click="changeFreight"
                class="item-button"
                :disabled="isAppLoading"
              >
                Alterar
              </button>
            </template>
            <p>{{ deliveryText }}</p>
          </CheckoutItem>
          <CheckoutItem v-else itemTitle="Frete" key="calculateFreight">
            <template #header>
              <button
                @click="changeFreight"
                class="item-button"
                :disabled="isAppLoading"
              >
                Calcular
              </button>
            </template>
          </CheckoutItem>
        </transition>

        <span class="sep" v-if="purchaseSeller"></span>

        <SellerSelector v-if="purchaseSeller" />

        <span class="sep"></span>

        <DesktopTotalText @toggleCheckout="() => toggleCheckout()" />
      </template>

      <template #button>
        <div class="checkout-data">
          <div class="mobile" style="display: none">
            <button class="mobile-toggler" @click="toggleCheckout">
              <ArrowIcon
                :style="{
                  transform: isCheckoutVisible ? 'rotate(180deg)' : null,
                }"
              />
              <MobileTotalText :totalValues="cartTotal" />
            </button>
          </div>
          <router-link
            tag="button"
            class="checkout-button"
            v-if="navigationData.nextStep"
            :disabled="isAppLoading"
            :to="navigationData.nextStep"
            @click.native="isCheckoutVisible = false"
            custom
          >
            <transition mode="out-in" name="opacity">
              <component :is="navigationData.buttonComponent" />
            </transition>
          </router-link>
        </div>
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ContentBox from '@/components/ContentBox.vue';
import CheckoutItem from '@/components/checkoutBox/CheckoutItem.vue';
import DesktopTotalText from '@/components/checkoutBox/DesktopTotalText.vue';
import MobileTotalText from '@/components/checkoutBox/MobileTotalText.vue';
import CartButton from '@/components/checkoutBox/CartButton.vue';
import LoadingButton from '@/components/checkoutBox/LoadingButton.vue';
import FinishButton from '@/components/checkoutBox/FinishButton.vue';
import SellerSelector from '@/components/SellerSelector.vue';
import ArrowIcon from '@/components/icons/checkoutBox/ArrowIcon.vue';

export default {
  name: 'CheckoutBox',
  data() {
    return {
      checkoutHeight: null,
      isCheckoutVisible: false,
      hiddenCheckout: true,
    };
  },
  components: {
    ContentBox,
    CheckoutItem,
    DesktopTotalText,
    MobileTotalText,
    CartButton,
    LoadingButton,
    FinishButton,
    SellerSelector,
    ArrowIcon,
  },
  computed: {
    ...mapGetters([
      'cartTotal',
      'cartDiscounts',
      'selectedPayment',
      'navigationData',
      'selectedFreight',
      'purchaseSeller',
      'isAppLoading',
      'navigationData',
      'accountType',
      'paymentOptions',
    ]),
    deliveryText() {
      if (this.selectedFreight.mensagem) return this.selectedFreight.mensagem;

      if (this.selectedFreight.transportadora === 'retirar') {
        return `Retirar na loja`;
      }

      if (this.selectedFreight.entrega > 1) {
        return `Entrega em até ${this.selectedFreight.entrega} dias úteis`;
      }

      return `Entrega em até ${this.selectedFreight.entrega} dia útil`;
    },
    lastInstallmentValue() {
      return this.cartTotal.parcelas[
        Object.keys(this.cartTotal.parcelas).length
      ].valor;
    },
    hasCardMethod() {
      const methods = Object.keys(this.paymentOptions);

      return methods.some((method) => method === 'credito') ||
        methods.some((method) => method === 'dois_cartoes')
        ? true
        : false;
    },
    discountList() {
      let list = [];

      Object.keys(this.cartDiscounts.descontos_aplicados).forEach((el) => {
        if (this.cartDiscounts.descontos_aplicados[el]) {
          list.push(this.cartDiscounts.descontos_aplicados[el]);
        }
      });

      return list.length ? list : null;
    },
  },
  methods: {
    ...mapMutations({
      openModal: 'UPDATE_MODAL',
    }),
    changeFreight() {
      const component =
        this.accountType === 'J' ? 'CompanyFreight' : 'FreightContent';

      this.toggleCheckout();

      this.openModal({
        isVisible: true,
        component: component,
      });
    },
    getCheckoutSize() {
      if (window.matchMedia('(max-width: 991px)').matches) {
        const boxContent = document.querySelector(
          '.checkout-container .box-content'
        );
        const checkoutData = document.querySelector(
          '.checkout-container .checkout-data'
        );

        this.checkoutHeight =
          boxContent && checkoutData
            ? boxContent.scrollHeight + checkoutData.scrollHeight
            : null;
      } else {
        this.checkoutHeight = null;
      }
    },
    toggleCheckout() {
      if (window.matchMedia('(max-width: 991px)').matches) {
        this.getCheckoutSize();
        this.isCheckoutVisible = !this.isCheckoutVisible;
      } else {
        this.isCheckoutVisible = false;
      }
    },
    closeCheckoutFromFilter(event) {
      const target = event.target;
      const currentTarget = event.currentTarget;

      if (
        window.matchMedia('(max-width: 991px)').matches &&
        target === currentTarget
      ) {
        this.isCheckoutVisible = false;
      }
    },
    showFobModal() {
      this.toggleCheckout();

      this.openModal({
        isVisible: true,
        component: 'ContentFob',
      });
    },
  },
  mounted() {
    window.addEventListener('resize', this.getCheckoutSize);
    setTimeout(() => {
      this.getCheckoutSize();
      this.hiddenCheckout = false;
    }, 1000);
  },
  unmounted() {
    window.removeEventListener('resize', this.getCheckoutSize);
  },
};
</script>

<style scoped lang="scss">
.checkout-button-enter-active,
.checkout-button-leave-active {
  transition: 400ms;
}

.checkout-button-enter,
.checkout-button-leave-to {
  opacity: 0;
}

.checkout-button-enter-to,
.checkout-button-leave {
  opacity: 1;
}

.checkout-container {
  position: relative;
  width: 21.25rem;
  flex-shrink: 0;
  transition: opacity 150ms ease-out, transform 150ms ease-out;

  @media screen and (max-width: $lg) {
    background-color: var(--dark-filter-color);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 20;
    will-change: height, transform;
    box-shadow: var(--mobile-checkout-box-shadow);

    &.hidden {
      transform: translateY(3rem);
      opacity: 0;
    }
  }

  .item-button {
    background-color: transparent;
    padding: 0.25rem;
    border-radius: 0.125rem;
    border: none;
    font-weight: 600;
    color: var(--main-link-color);
    text-decoration: underline;
    font-size: 0.75rem;
    cursor: pointer;
    transition: color 150ms ease-out;

    &:hover,
    &:focus {
      color: var(--main-link-color-hover);
    }
  }

  .fob-button {
    background-color: transparent;
    padding: 0.25rem;
    margin: -0.25rem;
    border-radius: 0.125rem;
    border: none;
    color: var(--light-text-color);
    text-decoration: underline;
    font-size: 0.75rem;
    cursor: pointer;
    transition: color 150ms ease-out;

    &:hover,
    &:focus {
      color: var(--secondary-button-hover);
    }
  }

  ::v-deep .box {
    @media screen and (max-width: $lg) {
      width: 100%;
      padding: 0;
      z-index: 5;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      overflow: hidden;
      max-height: 85vh;
      height: 3.5625rem;
      box-shadow: var(--content-box-shadow);
      border-radius: 0px;
      will-change: height, border-radius;
      transition: height 300ms cubic-bezier(0.7, 0.1, 0.5, 1.1),
        border-radius 300ms cubic-bezier(0.7, 0.1, 0.5, 1.1);
    }

    @media screen and (max-width: $sm) {
      height: 3.390625rem;
    }
  }

  .sep {
    display: block;
    width: 100%;
    background-color: var(--checkout-box-line);
    height: 1px;
    margin: 1.5rem 0;
  }

  .checkout-data {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    @media screen and (max-width: $lg) {
      display: flex !important;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0.5rem;
      height: 3.5625rem;
    }

    @media screen and (max-width: $sm) {
      height: 3.390625rem;
    }

    .mobile {
      @media screen and (max-width: $lg) {
        display: flex !important;
        align-items: center;
        gap: 0.5rem;
      }

      .mobile-toggler {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.75rem;

        ::v-deep svg {
          width: 1.25rem;
          height: 1.25rem;
          opacity: 0.25;
          fill: var(--dark-text-color);
          transition: transform 300ms cubic-bezier(0.7, 0.1, 0.5, 1.1);
        }
      }
    }

    .checkout-button {
      width: 100%;
      min-width: 8rem;
      height: 3.25rem;
      margin: 0;
      background-color: var(--checkout-button-background);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      gap: 0.625rem;
      border-radius: 0 0 0.25rem 0.25rem;
      z-index: 5;
      transition: 150ms ease-out;
      transition-property: box-shadow;
      cursor: pointer;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 0.175rem var(--checkout-button-hover);
      }

      &:disabled {
        cursor: initial;
        pointer-events: none;
        touch-action: none;
        user-select: none;
        box-shadow: 0 0 0 0 var(--checkout-button-hover);
      }

      ::v-deep svg {
        fill: var(--checkout-button-color);
      }

      ::v-deep .button-text {
        font-size: 1rem;
        color: var(--checkout-button-color);

        @media screen and (max-width: $sm) {
          font-size: 0.825rem;
        }
      }

      ::v-deep .loading {
        font-size: 0.75rem;

        @media screen and (max-width: $sm) {
          font-size: 0.5rem;
        }

        span {
          background-color: var(--checkout-button-color);
        }
      }

      @media screen and (max-width: $lg) {
        width: max-content;
        height: min-content;
        border-radius: 0.25rem;
        height: 100%;
        min-height: 2.5rem;
      }

      @media screen and (max-width: $sm) {
        padding: 0.5rem;
        width: 7.5rem;

        ::v-deep svg {
          display: none;
        }
      }
    }
  }
}
</style>
