<template>
  <InputContainer :textLabel="textLabel" :hasError="hasError && required">
    <input
      type="email"
      inputmode="email"
      v-model="localInputValue"
      :readonly="readonly ? true : null"
      :disabled="isDisabled"
      :placeholder="placeholderText"
      @keyup="handleKeyup()"
      @focus="
        handleFocus();
        hideCheckout();
      "
      @blur="
        handleBlur();
        showCheckout();
      "
    />
  </InputContainer>
</template>

<script>
import validateEmail from '@/helpers/validateEmail.js';
import InputContainer from '@/components/input/InputContainer.vue';

export default {
  name: 'TextInput',
  data() {
    return {
      hasError: false,
      localInputValue: this.inputValue,
    };
  },
  components: {
    InputContainer,
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    inputValue: {
      type: String,
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
    validateField: Boolean,
    placeholderText: String,
    isDisabled: Boolean,
  },
  watch: {
    inputValue(value) {
      this.localInputValue = value;
    },
    validateField(value) {
      if (value) {
        const status = this.validate();

        this.hasError = !status;

        this.$emit('validateField', status);
        return status;
      }
    },
  },
  methods: {
    handleFocus() {
      if (this.readonly) return false;

      this.hasError = false;
    },
    handleBlur() {
      if (this.readonly) return false;
      this.$emit('update:inputValue', this.localInputValue);

      const response = this.validate(this.localInputValue);

      this.hasError = !response;

      this.$emit('validateField', this.validate());
    },
    validate() {
      let status = true;

      if (this.required) {
        status = validateEmail(this.localInputValue);
      }

      return status;
    },
    handleKeyup() {
      if (this.readonly) return false;

      this.$emit('update:inputValue', this.localInputValue);
    },
  },
};
</script>

<style>
</style>