export default function (inputValue) {
  const regex = /^[0-9]{8}$/

  if (inputValue === undefined || inputValue === '') return false

  inputValue = inputValue.replace('-', '');

  if (!regex.test(inputValue)) return false

  return true
}