<template>
  <div class="box-table">
    <div class="item-flex item-header">
      <p class="details">Itens</p>
      <p class="amount">Quantidade</p>
      <p class="price">Subtotal</p>
    </div>
    <transition-group tag="div" name="cart-items" class="items-list">
      <div class="item-container" v-for="item in cartItems" :key="item.id">
        <div class="item-flex item">
          <div class="details">
            <div class="image-container">
              <ItemImage :item="item" />
            </div>
            <div>
              <a :href="item.url" target="_blank" class="item-title">
                {{ item.titulo }}
              </a>
              <ItemDescription :item="item" />
            </div>
          </div>
          <div class="amount">
            <SelectInput
              v-if="validateItemQuantity(item)"
              :selectItems="validateItemQuantity(item)"
              :inputValue="item.quantidade"
              :readonly="item.isLoading || item.isDeleting || isAppLoading"
              @update:inputValue="addAmount(item, $event)"
            />
            <div class="counter" v-else>
              <button
                class="remove"
                @click="removeAmount(item)"
                :disabled="
                  +item.quantidade <= 1 || item.isLoading || isAppLoading
                "
              >
                -
              </button>
              <span class="amount-field">{{ item.quantidade }}</span>
              <button
                class="increase"
                @click="addAmount(item, item.quantidade + 1)"
                :disabled="
                  +item.quantidade >= +item.estoque ||
                  item.isLoading ||
                  isAppLoading
                "
              >
                +
              </button>
            </div>
            <button
              class="delete-button"
              @click="REMOVE_ITEM(item.id)"
              :disabled="
                item.isDeleting || isAppLoading || navigationData.productLoading
              "
            >
              <transition mode="out-in" name="opacity">
                <template v-if="item.isDeleting || item.isLoading">
                  <Loading fontSize="8px" />
                </template>
                <span v-else> remover </span>
              </transition>
            </button>
            <p v-if="item.message">
              {{ item.message }}
            </p>
          </div>
          <div class="price">
            <ItemSubtotal :item="item" />
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ItemImage from '@/components/cartBox/ItemImage.vue';
import ItemDescription from '@/components/cartBox/ItemDescription.vue';
import ItemSubtotal from '@/components/cartBox/ItemSubtotal.vue';
import SelectInput from '@/components/input/SelectInput.vue';

export default {
  name: 'CartBox',
  data() {
    return {
      hasImage: true,
    };
  },
  components: {
    ItemImage,
    ItemDescription,
    ItemSubtotal,
    SelectInput,
  },
  computed: {
    ...mapGetters([
      'cartItems',
      'isAppLoading',
      'storeConfig',
      'navigationData',
    ]),
  },
  methods: {
    ...mapActions(['REMOVE_ITEM', 'ADD_AMOUNT', 'REMOVE_AMOUNT']),
    removeAmount(el) {
      if (+el.quantidade > 1) {
        this.REMOVE_AMOUNT(el.id);
      }
    },
    addAmount(el, amount) {
      if (amount <= el.estoque) {
        this.ADD_AMOUNT({
          id_produto: el.id,
          quantidade: amount,
        });

        return true;
      }

      this.$toast.error('Selecione uma quantidade válida');
    },
    validateItemQuantity(item) {
      if (!item.unidades_fixas) return null;

      const units = item.unidades_fixas;
      const stock = item.estoque;

      return units.map((item) => {
        return {
          ...item,
          isDisabled: item.valor > stock,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cart-items-enter-active,
.cart-items-leave-active {
  transition: 300ms ease-out;
  transition-property: max-height, opacity, transform;
}

.cart-items-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
  max-height: 0 !important;
}

.items-list {
  display: flex;
  flex-flow: column;
}

.item-container {
  height: auto;
  max-height: 30rem;
  overflow: hidden;

  .item {
    padding: 1.5rem 0;
  }
}

.item-flex {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;

  @media screen and (max-width: $md) {
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: flex-start;
  }

  @media screen and (max-width: $sm) {
    gap: 1rem;
    justify-content: space-between;
  }

  &.item-header {
    @media screen and (max-width: $md) {
      display: none !important;
    }

    p {
      font-size: 0.75rem;
      letter-spacing: -0.01em;
      font-weight: 500;
      color: var(--normal-text-color);
      line-height: 1;

      &.amount {
        text-align: center;
      }

      &.price {
        text-align: right;
      }
    }
  }

  .details {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    gap: 1.25rem;

    @media screen and (max-width: $md) {
      width: 100%;
      gap: 0.75rem;
    }

    .image-container {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 0.3125rem;

      @media screen and (max-width: $sm) {
        width: 4rem;
        height: 5rem;
        padding: 0;
      }
    }

    .item-title {
      display: block;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: -0.01em;
      margin: 0 0 0.5rem 0;
      line-height: 1.4;
      transition: color 150ms ease-out, box-shadow 100ms ease-out;
      border-radius: 0.125rem;
      outline: none;
      color: var(--product-link);

      &:hover,
      &:focus {
        color: var(--product-link-hover);
      }
    }
  }

  .amount,
  .price {
    width: 7.5rem;
    flex-shrink: 0;

    @media screen and (max-width: $sm) {
      width: max-content;
    }
  }

  .amount {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5rem;

    @media screen and (max-width: $md) {
      margin-left: 5rem;
    }

    @media screen and (max-width: $sm) {
      margin-left: 4rem;
    }

    ::v-deep select {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--dark-text-color);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      transition: box-shadow 150ms ease-out, padding 100ms ease-out,
        opacity 150ms ease-out;

      &.readonly {
        opacity: 0.5;
        user-select: none;

        & ~ .arrow {
          opacity: 0.5;
        }
      }
    }

    ::v-deep .arrow {
      transition: opacity 150ms ease-out;
    }

    .counter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.25rem;

      .amount-field {
        display: block;
        width: 2rem;
        text-align: center;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 600;
        color: var(--dark-text-color);
        padding: 0.25rem 0;
        border-radius: 0.125rem;
        transition: box-shadow 100ms ease-out, opacity 200ms ease-out;
        box-shadow: 0 0 0 0 transparentize(#ddd, 1);
        user-select: none;
        opacity: 1;

        &:focus {
          box-shadow: 0 0 0 0.2rem transparentize(#ddd, 0.25);
        }
      }

      button {
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
        font-weight: 600;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 0.125rem;
        transition: opacity 150ms ease-out, box-shadow 100ms ease-out;
        box-shadow: 0 0 0 0 transparentize(#ddd, 1);
        user-select: none;
        color: var(--dark-text-color);

        &:focus {
          box-shadow: 0 0 0 0.2rem transparentize(#ddd, 0.25);
        }

        &:hover {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.35;
          cursor: initial;
        }
      }
    }
  }
}
</style>