<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g id="noun-discount-1561006" transform="translate(-70.761 -11.455)">
      <path
        d="M94.381,16.645a.858.858,0,0,1-.409-1.051L94,15.477a2.029,2.029,0,0,0-1.314-2.569,1.854,1.854,0,0,0-1.255,0l-.117.029a.89.89,0,0,1-1.051-.409,2.068,2.068,0,0,0-2.8-.817,2,2,0,0,0-.818.817.858.858,0,0,1-1.051.409l-.117-.029a2.029,2.029,0,0,0-2.569,1.314,1.854,1.854,0,0,0,0,1.255l.029.117a.89.89,0,0,1-.409,1.051,2.068,2.068,0,0,0-.818,2.8,2,2,0,0,0,.817.818.858.858,0,0,1,.409,1.051l-.029.117A2.029,2.029,0,0,0,84.221,24a1.854,1.854,0,0,0,1.255,0l.117-.029a.89.89,0,0,1,1.051.409,2.068,2.068,0,0,0,2.8.817,2,2,0,0,0,.817-.817.857.857,0,0,1,1.051-.409l.117.029A2.029,2.029,0,0,0,94,22.688a1.853,1.853,0,0,0,0-1.255l-.029-.117a.89.89,0,0,1,.409-1.051,2.068,2.068,0,0,0,.817-2.8,2,2,0,0,0-.817-.817Zm-.555,2.6a2.005,2.005,0,0,0-.963,2.423l.029.117a.887.887,0,0,1-.584,1.109.811.811,0,0,1-.526,0l-.117-.029a2.073,2.073,0,0,0-2.423.963.878.878,0,0,1-1.2.35,1.007,1.007,0,0,1-.35-.35,2.031,2.031,0,0,0-1.81-1.08,2.224,2.224,0,0,0-.613.088l-.117.029a.887.887,0,0,1-1.109-.584.811.811,0,0,1,0-.525l.029-.088a2.073,2.073,0,0,0-.963-2.423.878.878,0,0,1-.35-1.2,1.006,1.006,0,0,1,.35-.35,2.073,2.073,0,0,0,.963-2.423l-.029-.117a.887.887,0,0,1,.584-1.109.811.811,0,0,1,.525,0l.117.029a2.073,2.073,0,0,0,2.423-.963.878.878,0,0,1,1.2-.35,1.006,1.006,0,0,1,.35.35,2.053,2.053,0,0,0,2.423.993l.117-.029a.887.887,0,0,1,1.109.584.811.811,0,0,1,0,.526l-.029.059a2.073,2.073,0,0,0,.963,2.423.878.878,0,0,1,.35,1.2.64.64,0,0,1-.35.379Z"
        transform="translate(-10.694 0)"
      />
      <path
        d="M264.155,190.575a.564.564,0,0,0-.817,0l-3.5,3.5a.579.579,0,0,0,.759.876c.029-.029.058-.029.058-.058l3.5-3.5A.564.564,0,0,0,264.155,190.575Z"
        transform="translate(-184.233 -174.28)"
      />
      <path
        d="M239.748,191.274a.876.876,0,1,0-.876.876A.86.86,0,0,0,239.748,191.274Z"
        transform="translate(-163.155 -174.279)"
      />
      <path
        d="M395.674,302.4a.876.876,0,1,0,.876.876A.86.86,0,0,0,395.674,302.4Z"
        transform="translate(-315.869 -283.361)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PaymentMethodDiscountIcon',
};
</script>