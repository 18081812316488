<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76.849"
    height="44.026"
    viewBox="0 0 76.849 44.026"
  >
    <path
      d="M62.4,38.3c-4.136-.5-8.126-3.616-9.794-5.065a1.242,1.242,0,0,0-1.63,0c-1.665,1.45-5.658,4.551-9.8,5.065a1.269,1.269,0,0,0-1.124,1.3c.525,12.6,8.8,18.133,11.133,19.445a1.229,1.229,0,0,0,1.218,0C54.735,57.736,63.02,52.2,63.532,39.6A1.269,1.269,0,0,0,62.4,38.3ZM51.782,52.816a6.57,6.57,0,1,1,.008,0Z"
      transform="translate(-29.257 -24.057)"
    />
    <path
      d="M76.544,68.146h-.808v-.655a2.791,2.791,0,0,0-5.583,0v.655h-.808a.329.329,0,0,0-.345.329v4.6a.329.329,0,0,0,.329.331h7.216a.326.326,0,0,0,.329-.329v-4.6A.329.329,0,0,0,76.544,68.146Zm-2.463,0h-2.29v-.655a1.15,1.15,0,1,1,2.3,0Z"
      transform="translate(-50.409 -47.267)"
    />
    <path
      d="M152.006,61.03a3.25,3.25,0,0,1-1.326-2.584h3.5c.048.6.315.9.808.9a.781.781,0,0,0,.45-.124.445.445,0,0,0,.186-.393.682.682,0,0,0-.4-.6,7.862,7.862,0,0,0-1.245-.539,13.471,13.471,0,0,1-1.668-.7A3.233,3.233,0,0,1,151.184,56a2.694,2.694,0,0,1-.474-1.654,2.869,2.869,0,0,1,.539-1.741,3.233,3.233,0,0,1,1.439-1.078,5.389,5.389,0,0,1,2.05-.372,4.824,4.824,0,0,1,3.061.889,3.163,3.163,0,0,1,1.191,2.5h-3.554a.887.887,0,0,0-.221-.636.714.714,0,0,0-.5-.191.447.447,0,0,0-.342.14.539.539,0,0,0-.132.391.674.674,0,0,0,.391.587,9.16,9.16,0,0,0,1.258.539,13.625,13.625,0,0,1,1.638.711,3.465,3.465,0,0,1,1.113.951,2.406,2.406,0,0,1,.482,1.522,3.112,3.112,0,0,1-1.854,2.918,4.966,4.966,0,0,1-2.155.428A5.181,5.181,0,0,1,152.006,61.03Z"
      transform="translate(-110.081 -37.374)"
    />
    <path
      d="M186.486,61.016a3.24,3.24,0,0,1-1.326-2.584h3.5c.049.6.315.9.808.9a.789.789,0,0,0,.453-.124.45.45,0,0,0,.183-.393.682.682,0,0,0-.41-.6,7.862,7.862,0,0,0-1.245-.539,13.584,13.584,0,0,1-1.665-.7A3.209,3.209,0,0,1,185.656,56a2.694,2.694,0,0,1-.458-1.673,2.869,2.869,0,0,1,.525-1.741,3.233,3.233,0,0,1,1.436-1.078,5.389,5.389,0,0,1,2.053-.372,4.827,4.827,0,0,1,3.058.889,3.155,3.155,0,0,1,1.191,2.5h-3.548a.873.873,0,0,0-.221-.636.709.709,0,0,0-.5-.191.442.442,0,0,0-.339.14.539.539,0,0,0-.135.391.679.679,0,0,0,.393.587,9.1,9.1,0,0,0,1.25.555,13.625,13.625,0,0,1,1.638.711,3.438,3.438,0,0,1,1.118.94,2.406,2.406,0,0,1,.474,1.525,3.115,3.115,0,0,1-1.851,2.918,4.985,4.985,0,0,1-2.155.428A5.178,5.178,0,0,1,186.486,61.016Z"
      transform="translate(-135.271 -37.359)"
    />
    <path
      d="M224.065,59.76h3.171v2.49H220.77V51.76h3.3Z"
      transform="translate(-161.287 -37.814)"
    />
    <path
      d="M150.584,98.712q.6-.5.948-.808a3.991,3.991,0,0,0,.59-.671,1.218,1.218,0,0,0,.24-.682.6.6,0,0,0-.119-.4.488.488,0,0,0-.717.035.808.808,0,0,0-.132.5H150.38a1.733,1.733,0,0,1,.226-.876,1.3,1.3,0,0,1,.558-.515,1.778,1.778,0,0,1,.773-.167,1.49,1.49,0,0,1,1.1.375,1.331,1.331,0,0,1,.372.978,1.916,1.916,0,0,1-.45,1.223,6.569,6.569,0,0,1-1.142,1.1h1.66v.857h-3.088v-.795Z"
      transform="translate(-109.862 -69.494)"
    />
    <path
      d="M167.421,96.364H165.5v.843a.857.857,0,0,1,.345-.221,1.274,1.274,0,0,1,.48-.086,1.347,1.347,0,0,1,.765.207,1.229,1.229,0,0,1,.447.539,1.835,1.835,0,0,1,.14.722,1.576,1.576,0,0,1-.4,1.14,1.522,1.522,0,0,1-1.145.423,2.01,2.01,0,0,1-.857-.17,1.3,1.3,0,0,1-.558-.472,1.347,1.347,0,0,1-.216-.7h1.027a.539.539,0,0,0,.566.445.507.507,0,0,0,.431-.186.808.808,0,0,0,.143-.493.666.666,0,0,0-.148-.461.566.566,0,0,0-.431-.159.558.558,0,0,0-.345.1.461.461,0,0,0-.178.269h-1.016V95.44h2.872Z"
      transform="translate(-120.178 -69.725)"
    />
    <path
      d="M180.741,96.1a.493.493,0,0,0-.353-.116.539.539,0,0,0-.5.3,2.3,2.3,0,0,0-.159.965.929.929,0,0,1,.391-.339,1.3,1.3,0,0,1,.563-.121,1.277,1.277,0,0,1,.967.38,1.471,1.471,0,0,1,.366,1.064,1.649,1.649,0,0,1-.181.789,1.3,1.3,0,0,1-.539.539,1.7,1.7,0,0,1-.822.191,1.421,1.421,0,0,1-1.318-.6,3.252,3.252,0,0,1-.369-1.706,3.09,3.09,0,0,1,.391-1.738,1.409,1.409,0,0,1,1.253-.571,1.549,1.549,0,0,1,.779.183,1.256,1.256,0,0,1,.48.477,1.644,1.644,0,0,1,.194.644H180.9A.633.633,0,0,0,180.741,96.1Zm-.754,1.679a.563.563,0,0,0-.175.447.571.571,0,0,0,.628.628.539.539,0,0,0,.426-.164.612.612,0,0,0,.159-.445.641.641,0,0,0-.154-.455.571.571,0,0,0-.445-.167A.63.63,0,0,0,179.987,97.779Z"
      transform="translate(-130.61 -69.507)"
    />
    <path
      d="M201.447,98.6a1.126,1.126,0,0,1-.151,1.525,1.582,1.582,0,0,1-1.054.318H198.23V96.133h1.945a1.617,1.617,0,0,1,1.029.3,1,1,0,0,1,.372.808.989.989,0,0,1-.2.633,1.008,1.008,0,0,1-.539.35A1,1,0,0,1,201.447,98.6Zm-2.155-.73h.69a.609.609,0,0,0,.4-.113.407.407,0,0,0,.14-.337.412.412,0,0,0-.14-.337.6.6,0,0,0-.4-.119h-.69Zm1.186,1.617a.426.426,0,0,0,.145-.348.442.442,0,0,0-.151-.356.609.609,0,0,0-.415-.129h-.765V99.6h.762a.62.62,0,0,0,.412-.124Z"
      transform="translate(-144.82 -70.23)"
    />
    <path
      d="M214.331,96.11v4.311H213.28V96.11Z"
      transform="translate(-155.815 -70.214)"
    />
    <path
      d="M222.491,96.11v.841h-1.145v3.478H220.3V96.951H219.15V96.11Z"
      transform="translate(-160.103 -70.214)"
    />
    <path
      d="M233.9,100.139a1.38,1.38,0,0,1-.6-.455,1.242,1.242,0,0,1-.237-.727h1.118a.5.5,0,0,0,.536.493.59.59,0,0,0,.369-.108.366.366,0,0,0,.135-.3.348.348,0,0,0-.108-.269.846.846,0,0,0-.269-.172,3.917,3.917,0,0,0-.447-.154,4.85,4.85,0,0,1-.682-.269,1.234,1.234,0,0,1-.455-.383,1.078,1.078,0,0,1-.191-.657,1.137,1.137,0,0,1,.436-.946,1.805,1.805,0,0,1,1.159-.323,1.827,1.827,0,0,1,1.15.339,1.218,1.218,0,0,1,.466.951H235.14a.445.445,0,0,0-.154-.329.539.539,0,0,0-.364-.121.455.455,0,0,0-.307.1.393.393,0,0,0,.081.62,3.047,3.047,0,0,0,.614.251,5.42,5.42,0,0,1,.679.269,1.28,1.28,0,0,1,.453.375,1.008,1.008,0,0,1,.191.633,1.223,1.223,0,0,1-.189.671,1.3,1.3,0,0,1-.539.48,1.83,1.83,0,0,1-.843.181A2.226,2.226,0,0,1,233.9,100.139Z"
      transform="translate(-170.265 -70.035)"
    />
    <path
      d="M64.993,44.026H11.855A11.855,11.855,0,0,1,0,32.171V11.855A11.855,11.855,0,0,1,11.855,0H64.993A11.855,11.855,0,0,1,76.849,11.855V32.181A11.855,11.855,0,0,1,64.993,44.026ZM11.855.911A10.953,10.953,0,0,0,.911,11.855V32.181A10.953,10.953,0,0,0,11.855,43.11H64.993A10.953,10.953,0,0,0,75.933,32.171V11.855A10.953,10.953,0,0,0,64.993.916Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SSLIcon',
};
</script>