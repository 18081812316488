<template>
  <div class="method-content">
    <p>
      Ao clicar em Finalizar, o QR Code e código serão exibidos na tela para que
      o pagamento seja efetuado.
    </p>
    <p v-if="discount">
      Pagando com PIX você ganha
      <strong>{{ discount }} de desconto em itens selecionados.</strong>
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Pix',
  computed: {
    ...mapGetters(['cartDiscounts']),
    discount() {
      if (!this.cartDiscounts.descontos_aplicados?.metodo_pagamento?.valor) {
        return null;
      }

      return this.cartDiscounts.descontos_aplicados.metodo_pagamento.valor;
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
    }),
  },
  activated() {
    this.validatePayment({
      isValid: true,
    });
  },
};
</script>