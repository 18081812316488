<template>
  <div class="method-content">
    <p>
      Ao clicar em Finalizar, os dados para depósito serão exibidos na sua tela
      para que você possa efetuar o pagamento. Também enviaremos as informações
      para o seu e-mail cadastrado.
    </p>
    <p v-if="discount">
      Pagamentos via depósito têm
      <strong>{{ discount }} de desconto em itens selecionados.</strong>
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Deposito',
  computed: {
    ...mapGetters(['cartDiscounts']),
    discount() {
      if (!this.cartDiscounts.descontos_aplicados?.metodo_pagamento?.valor) {
        return null;
      }

      return this.cartDiscounts.descontos_aplicados.metodo_pagamento.valor;
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
    }),
  },
  activated() {
    this.validatePayment({
      isValid: true,
    });
  },
};
</script>