export default function (value) {
  let sum = 0
  let mod

  value = value.replace(/\.|-|\//g, '');

  if (value.length !== 11) return false

  if (typeof (+value) !== 'number') return false

  if (value.length === 0) return null

  if (/^(.)\1*$/.test(value)) return false

  for (let i = 1; i <= 9; i++) {
    sum += +value.substring(i - 1, i) * (11 - i)
  }

  mod = (sum * 10) % 11

  if (mod === 10 || mod === 11) mod = 0
  if (mod != +value.substring(9, 10)) return false

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum += +value.substring(i - 1, i) * (12 - i)
  }

  mod = (sum * 10) % 11

  if (mod === 10 || mod === 11) mod = 0
  if (mod != +value.substring(10, 11)) return false

  return true
}