<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <path
      id="whatsapp"
      d="M55.057,9.531A31.9,31.9,0,0,0,.649,31.942,31.54,31.54,0,0,0,4.9,47.8L.382,64.23l16.893-4.41A31.954,31.954,0,0,0,32.5,63.68h.013A31.836,31.836,0,0,0,64.382,31.967,31.442,31.442,0,0,0,55.057,9.531ZM32.516,58.325H32.5A26.549,26.549,0,0,1,19.024,54.65l-.967-.571L8.033,56.7l2.676-9.727-.63-1A26.35,26.35,0,0,1,32.526,5.587a26.369,26.369,0,1,1-.01,52.738ZM47.044,38.583c-.8-.4-4.711-2.313-5.441-2.578s-1.261-.4-1.791.4-2.057,2.578-2.521,3.107-.929.6-1.725.2a21.769,21.769,0,0,1-6.4-3.933,23.916,23.916,0,0,1-4.429-5.489c-.464-.794,0-1.181.349-1.617a22.418,22.418,0,0,0,1.99-2.711,1.451,1.451,0,0,0-.067-1.388c-.2-.4-1.791-4.3-2.454-5.884-.647-1.545-1.3-1.336-1.792-1.36-.464-.023-.995-.028-1.526-.028a2.931,2.931,0,0,0-2.124.992A8.87,8.87,0,0,0,16.324,24.9c0,3.9,2.853,7.668,3.251,8.2s5.615,8.533,13.6,11.965a45.733,45.733,0,0,0,4.539,1.67,10.965,10.965,0,0,0,5.015.314c1.53-.228,4.711-1.917,5.375-3.768a6.593,6.593,0,0,0,.464-3.768C48.371,39.178,47.84,38.98,47.044,38.583Z"
      transform="translate(-0.382 -0.23)"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'WhatsAppIcon',
};
</script>