<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.63 315.63">
    <path
      d="M246.15,241.44a46.12,46.12,0,0,1-32.78-13.53L166,180.53a9,9,0,0,0-12.44,0L106.07,228a46.12,46.12,0,0,1-32.78,13.57H64l60,60a48,48,0,0,0,67.81,0L252,241.48Z"
      fill="#32bcad"
    />
    <path
      d="M73.3,74a46,46,0,0,1,32.78,13.57l47.51,47.52a8.81,8.81,0,0,0,12.44,0l47.34-47.34a46,46,0,0,1,32.78-13.58h5.7L191.73,14.05a47.93,47.93,0,0,0-67.79,0l0,0L64,74Z"
      fill="#32bcad"
    />
    <path
      d="M301.58,123.91,265.25,87.58a7.11,7.11,0,0,1-2.58.52H246.15a32.61,32.61,0,0,0-22.93,9.5l-47.34,47.31a22.74,22.74,0,0,1-32.13,0L96.23,97.42A32.59,32.59,0,0,0,73.3,87.91H53a7.31,7.31,0,0,1-2.44-.49L14,123.91a48,48,0,0,0,0,67.81L50.5,228.2a6.85,6.85,0,0,1,2.44-.49H73.3a32.67,32.67,0,0,0,22.93-9.51l47.51-47.51c8.59-8.58,23.56-8.58,32.14,0L223.22,218a32.66,32.66,0,0,0,22.93,9.5h16.52a6.72,6.72,0,0,1,2.58.52l36.33-36.33a48,48,0,0,0,0-67.8h0"
      fill="#32bcad"
    />
  </svg>
</template>

<script>
export default {
  name: 'PixIcon',
};
</script>