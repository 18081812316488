<template>
  <p>{{ item.valores.total.subtotal | currency }}</p>
</template>

<script>
export default {
  name: 'ItemSubtotal',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
  color: var(--dark-text-color);
}
</style>