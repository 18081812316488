<template>
  <p class="mobile-value">
    <!-- In cash only -->
    <template v-if="selectedPayment.pagamento == 'vista'">
      {{ totalValues.total_vista | currency }}
      <span>à vista</span>
    </template>

    <!-- In cash and on term -->
    <template v-else>
      <template v-if="selectedInstallments">
        <template v-if="selectedInstallments?.quantity == 1">
          {{ totalValues.total_prazo | currency }}
          <span>à vista no cartão</span>
        </template>
        <template v-else>
          {{ totalValues.total_prazo | currency }}
          <span>
            em {{ selectedInstallments.quantity }}x de
            {{ selectedInstallments.value | currency }}
          </span>
        </template>
      </template>
      <template v-else>
        <template v-if="!totalValues.juros">
          <template v-if="installments > 1">
            {{ totalValues.total_prazo | currency }}
            <span>em até {{ installments }} parcelas</span>
          </template>
          <template v-else>
            {{ totalValues.total_prazo | currency }}
            <span>sem juros</span>
          </template>
        </template>
        <template v-else>
          {{ totalValues.total_prazo | currency }}
          <span>com juros no cartão</span>
        </template>
      </template>
    </template>
  </p>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileTotalText',
  props: {
    totalValues: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters(['selectedPayment', 'selectedInstallments']),
    installments() {
      return this.totalValues.parcelas
        ? Object.keys(this.totalValues.parcelas).length
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-value {
  letter-spacing: -0.01em;
  line-height: 1.125;
  text-align: left;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--dark-text-color);

  span {
    font-weight: 500;
    font-size: 0.625rem;
    color: var(--normal-text-color);
    display: block;
  }
}
</style>