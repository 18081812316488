import { mapGetters } from "vuex";

const toggleMethodAvailability = {
  computed: {
    ...mapGetters(['selectedPayment', 'cartTotal']),
    currentValue() {
      return this.selectedPayment.pagamento == 'vista'
        ? this.cartTotal.total_vista
        : this.cartTotal.total_prazo;
    },
    minValue() {
      return this.selectedPayment.valor_minimo_compra;
    },
    isMethodAvailable() {
      return this.currentValue >= this.minValue
    }
  },
}

export default toggleMethodAvailability