<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="10"
    viewBox="0 0 20 10"
  >
    <path
      d="M2514.3,2034.238a.905.905,0,0,1-.608-.233l-8.483-7.634-8.483,7.634a.909.909,0,1,1-1.216-1.352l9.091-8.181a.909.909,0,0,1,1.216,0l9.091,8.181a.909.909,0,0,1-.608,1.585Z"
      transform="translate(-2495.206 -2024.238)"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
};
</script>