<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.7 107.28">
    <path
      d="M43.64,23.69a31.64,31.64,0,0,1,41,23.65l21.64-4.41A53.69,53.69,0,0,0,36.64,2.76Z"
      fill="#ffcb05"
    />
    <path
      d="M18.1,93.83,32.74,77.29a31.58,31.58,0,0,1,0-47.28L18.1,13.45a53.67,53.67,0,0,0,0,80.38"
      fill="#00a4e0"
    />
    <path
      d="M84.59,60A31.61,31.61,0,0,1,43.64,83.58l-7,21a53.76,53.76,0,0,0,69.59-40.13Z"
      fill="#ef4123"
    />
    <path
      d="M127.87,78a37.62,37.62,0,0,1-2.26-4.34,31.86,31.86,0,0,1-.7-24.1A31.82,31.82,0,0,1,142,30.84,33,33,0,0,1,172.3,33c6,3.45,10.27,8.77,13.5,16.31.41,1,.77,2,1.14,2.87Zm19.72-34.34c-7,3-10.63,9.6-9.87,17.3l29.7-12.79c-5.11-6-11.76-8-19.83-4.51Zm23.53,27.83s0,0,0,0l-.61-.42a17.54,17.54,0,0,1-8,6.73c-6.64,2.88-12.79,2.14-17.22-1.74l-.41.62s0,0,0,0l-7.54,11.28a32.8,32.8,0,0,0,6,3.28,32.68,32.68,0,0,0,39.32-12.08Zm36.46-57.21V76.94l9.75,4-5.54,12.92L201,89.32a12.17,12.17,0,0,1-5.29-4.45,14,14,0,0,1-2.08-7.77V14.25ZM232.82,61a17.71,17.71,0,0,1,6.1-13.38l-10-11.16a32.75,32.75,0,0,0,0,49l10-11.15A17.71,17.71,0,0,1,232.82,61Zm17.77,17.78a17.77,17.77,0,0,1-5.63-.93L240.18,92a32.8,32.8,0,0,0,42.51-24.45l-14.68-3a17.77,17.77,0,0,1-17.42,14.18Zm0-50.53a33,33,0,0,0-10.38,1.67L245,44.11a17.77,17.77,0,0,1,23,13.34l14.68-3a32.77,32.77,0,0,0-32.07-26.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'EloIcon',
};
</script>