<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="15"
    viewBox="0 0 24 15"
  >
    <path
      d="M0,0H1.5V15H0ZM9,0h1.5V15H9Zm7.5,0H18V15H16.5Zm3,0H21V15H19.5Zm3,0H24V15H22.5ZM12,0h3V15H12ZM3,0H7.5V15H3Z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'BoletoIcon',
};
</script>