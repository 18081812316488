import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import { mask } from 'vue-the-mask'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"
import App from './App.vue'
import Loading from './components/Loading.vue'
import getCustomerFromUrl from './helpers/getCustomerFromUrl.js'
import router from './router'
import store from './store'

const customerData = getCustomerFromUrl()

Vue.prototype.$baseURL = customerData.url

Vue.use(VueClipboard)

Vue.use(Toast, {
  position: "bottom-right",
  transition: "Vue-Toastification__fade",
  maxToasts: 3,
  timeout: 4000,
  newestOnTop: true,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      return false;
    }
    return toast;
  }
})

Vue.config.productionTip = false

Vue.component('Loading', Loading)

Vue.filter('currency', function (value) {
  if (typeof value != 'number' || !value) return value

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
})

Vue.directive('mask', {
  bind(el, binding) {
    if (!binding.value) return
    mask(el, binding)
  },
  unbind() {}
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')