<template>
  <div>
    <PageHeader
      id="pageTitle"
      v-if="navigationData.pageTitle"
      :pageTitle="pageTitle"
      :isChanging="isChanging"
    ></PageHeader>
    <div class="container content-container">
      <transition mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
      <CheckoutBox />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader';
import CheckoutBox from '@/components/checkoutBox/CheckoutBox.vue';

export default {
  name: 'ShoppingCart',
  data: function () {
    return {
      pageTitle: 'Carrinho',
      isChanging: false,
    };
  },
  components: {
    PageHeader,
    CheckoutBox,
  },
  computed: {
    ...mapGetters(['navigationData']),
  },
  watch: {
    'navigationData.pageTitle': function () {
      this.isChanging = true;
      setTimeout(() => {
        this.pageTitle = this.navigationData.pageTitle;
        this.isChanging = false;
      }, 400);
    },
  },
  created() {
    this.pageTitle = this.navigationData.pageTitle;
  },
};
</script>

<style scoped lang="scss">
.content-container {
  display: flex;
  gap: 2rem;
  flex-flow: row;
  align-items: stretch;

  .box-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    @media screen and (max-width: $md) {
      grid-template-columns: minmax(10rem, 1fr);
    }
  }
}
</style>