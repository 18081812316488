<template>
  <CheckoutItem
    :itemTitle="cartTotal.pagamento == 'vista' ? 'Total à vista' : 'Total'"
    :itemValue="
      selectedPayment.pagamento == 'vista'
        ? cartTotal.total_vista
        : cartTotal.total_prazo
    "
    :isHighlight="true"
  >
    <!-- In cash and on term -->
    <template v-if="selectedPayment.pagamento == 'prazo'">
      <template v-if="selectedInstallments">
        <p v-if="selectedInstallments?.quantity == 1">à vista no cartão</p>
        <p v-else>
          em {{ selectedInstallments.quantity }}x de
          {{ selectedInstallments.value | currency }}
        </p>
        <button v-if="installments > 1" @click="showInstallments">
          Ver parcelas
        </button>
      </template>
      <template v-else>
        <template v-if="!cartTotal.juros">
          <template v-if="cartTotal.total_vista === cartTotal.total_prazo">
            <p v-if="installments > 1">
              Em até {{ installments }} parcelas<br />
            </p>
            <button @click="showInstallments">Ver parcelas</button>
          </template>
          <template v-else>
            <template v-if="installments > 1">
              <p>
                ou {{ cartTotal.total_prazo | currency }} em até
                {{ installments }} parcelas<br />
              </p>
              <button @click="showInstallments">Ver parcelas</button>
            </template>
            <p v-else>ou {{ cartTotal.total_prazo | currency }} no cartão</p>
          </template>
        </template>
        <template v-else>
          <template>
            <p>com juros no cartão</p>
            <button @click="showInstallments" v-if="installments > 1">
              Ver parcelas
            </button>
          </template>
        </template>
      </template>
    </template>
  </CheckoutItem>
</template>

<script>
import CheckoutItem from '@/components/checkoutBox/CheckoutItem.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DesktopTotalText',
  components: {
    CheckoutItem,
  },
  computed: {
    ...mapGetters(['selectedPayment', 'cartTotal', 'selectedInstallments']),
    installments() {
      return this.cartTotal.parcelas
        ? Object.keys(this.cartTotal.parcelas).length
        : null;
    },
  },
  methods: {
    ...mapMutations({
      openModal: 'UPDATE_MODAL',
    }),
    showInstallments() {
      this.$emit('toggleCheckout', true);

      this.openModal({
        isVisible: true,
        component: 'InstallmentsList',
        canClose: true,
        content: {
          isProduct: false,
          hasFees: this.cartTotal.juros,
          installments: this.cartTotal.parcelas,
        },
      });
    },
  },
};
</script>