export default function (value) {
  let isValid

  const regex = /^[0-9]*$/

  isValid = regex.test(value);

  return {
    isValid
  }
}