import axios from 'axios'
import getCustomerFromUrl from './helpers/getCustomerFromUrl.js'

const customerData = getCustomerFromUrl()

if (!customerData) {
  console.error('Customer API not found')
}

const axiosInstance = axios.create({
  baseURL: customerData.api,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint)
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  }
}
export { api }