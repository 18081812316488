<template>
  <a
    :href="buttonLink"
    :target="buttonTarget"
    :class="{ secondary: isSecondary, disabled: isDisabled }"
    class="main-button"
    v-if="buttonType === 'link'"
  >
    <div class="button-content">
      <slot></slot>
    </div>
  </a>
  <button
    v-else
    :disabled="isLoading || isDisabled"
    :type="buttonType === 'submit' ? 'submit' : 'button'"
    :class="{
      secondary: isSecondary,
      disabled: isDisabled,
      switch: buttonType === 'switch',
      selected: buttonType === 'switch' && isActive,
    }"
    class="main-button"
  >
    <transition mode="out-in">
      <Loading fontSize="8px" v-if="isLoading" />
      <div class="button-content" v-else>
        <slot></slot>
      </div>
    </transition>
  </button>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    isDisabled: Boolean,
    isLoading: Boolean,
    isActive: Boolean,
    isSecondary: Boolean,
    buttonLink: String,
    buttonTarget: {
      type: String,
      default: '_blank',
    },
    buttonType: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-button-background);
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
  transition: 0.15s ease-out;
  transition-property: background-color, box-shadow, opacity;

  .button-content {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      fill: var(--main-button-color);
      transition: fill 100ms ease-out;
    }

    ::v-deep span {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--main-button-color);
      transition: color 100ms ease-out;
      white-space: nowrap;
      user-select: none;
    }
  }

  ::v-deep .loading span {
    background-color: var(--main-button-color);
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    box-shadow: 0 0 0 0.175rem var(--main-button-hover);
  }

  &.secondary {
    background-color: var(--secondary-button-background);

    .button-content {
      ::v-deep svg {
        fill: var(--secondary-button-color);
      }

      ::v-deep span {
        color: var(--secondary-button-color);
      }
    }

    ::v-deep .loading span {
      background-color: var(--default-loading-color);
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: none;

      .button-content {
        ::v-deep svg {
          fill: var(--secondary-button-hover);
        }

        ::v-deep span {
          color: var(--secondary-button-hover);
        }
      }
    }
  }

  &.disabled {
    opacity: 0.75;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: none;
    }
  }

  &:disabled {
    cursor: auto;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: none;
    }
  }

  &.switch {
    background-color: var(--switch-button-background);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      box-shadow: 0 0 0 0.175rem var(--switch-button-hover);
    }

    .button-content {
      ::v-deep svg {
        fill: var(--switch-button-color);
      }

      ::v-deep span {
        color: var(--switch-button-color);
      }
    }

    ::v-deep .loading span {
      background-color: var(--switch-button-background);
    }

    &.selected {
      background-color: var(--selected-switch-button-background);

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        box-shadow: 0 0 0 0.175rem var(--selected-switch-button-hover);
      }

      .button-content {
        ::v-deep svg {
          fill: var(--selected-switch-button-color);
        }

        ::v-deep span {
          color: var(--selected-switch-button-color);
        }
      }

      ::v-deep .loading span {
        background-color: var(--selected-switch-button-color);
      }
    }
  }
}
</style>