<template>
  <div class="container centered">
    <h2>Seu carrinho está vazio</h2>
    <p>
      Você ainda não tem nenhum item adicionado ao seu carrinho. <br />Escolha
      alguns produtos e retorne para esta página.
    </p>
    <MainButton
      target="_self"
      buttonType="link"
      class="main-button"
      :buttonLink="$baseURL"
    >
      <span>Escolher produtos</span>
    </MainButton>
  </div>
</template>

<script>
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'EmptyMessage',
  components: {
    MainButton,
  },
};
</script>

<style scoped lang="scss">
.container {
  gap: 2rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  text-align: center;
  color: var(--dark-text-color);

  @media screen and (max-width: $sm) {
    font-size: 2rem;
  }
}

p {
  line-height: 1.75rem;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  display: inline-block;
  max-width: 35rem;
  text-align: center;
  color: var(--normal-text-color);
}

.main-button {
  margin: 1rem;
}
</style>