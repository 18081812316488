export default function (value) {
  let sum
  let result

  value = value.replace(/[^\d]+/g, '');

  if (typeof (+value) !== 'number') return false
  if (value == '') return false;
  if (value.length != 14) return false;

  if (value == "00000000000000" ||
    value == "11111111111111" ||
    value == "22222222222222" ||
    value == "33333333333333" ||
    value == "44444444444444" ||
    value == "55555555555555" ||
    value == "66666666666666" ||
    value == "77777777777777" ||
    value == "88888888888888" ||
    value == "99999999999999")
    return false;

  let documentLength = value.length - 2
  let documentNumbers = value.substring(0, documentLength);
  let documentDigits = value.substring(documentLength);
  let pos = documentLength - 7;

  sum = 0;

  for (let i = documentLength; i >= 1; i--) {
    sum += documentNumbers.charAt(documentLength - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (result != documentDigits.charAt(0))
    return false;

  documentLength = documentLength + 1;
  documentNumbers = value.substring(0, documentLength);
  sum = 0;
  pos = documentLength - 7;
  for (let i = documentLength; i >= 1; i--) {
    sum += documentNumbers.charAt(documentLength - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (result != documentDigits.charAt(1))
    return false;

  return true;
}