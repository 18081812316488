<template>
  <div>
    <div class="freight-input-container" key="input" v-if="!listOnly">
      <TextButton
        buttonText="Calcular"
        mask="#####-###"
        inputMode="numeric"
        inputName="postal-code"
        inputId="postal-code"
        inputAutocomplete="postal-code"
        :isLoading="isLoading"
        :isDisabled="isComponentLoading"
        :inputValue="inputValue"
        :updateInputValue="updateInputValue"
        @returnUpdate="updateInputValue = false"
        @updateInputValue="handleAdd($event)"
      >
        <a
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="_blank"
          class="location-link"
          >Não sei meu CEP</a
        >
      </TextButton>
    </div>
    <transition mode="out-in">
      <div
        v-if="$store.getters.freightOptions !== null"
        class="freight-container"
        key="freight"
      >
        <div
          class="freight-option-container"
          v-for="item in $store.getters.freightOptions"
          :key="item.id"
        >
          <label class="freight-option">
            <input
              type="radio"
              v-model="chosenFreight"
              :id="item.id"
              :value="item.id"
              :disabled="isComponentLoading || navigationData.freightLoading"
            />
            <p class="name">{{ item.nome }}</p>
            <template>
              <p class="deadline" v-if="item.alias == 'fob'">
                <button class="fob-button" @click="showFobModal">
                  Clique aqui para combinar
                </button>
              </p>
              <p class="deadline" v-else>
                {{ formatText(item) }}
              </p>
            </template>
            <NoteContainer class="note" v-if="item.retirada">
              <p class="title">Endereço de retirada</p>
              <p v-html="item.retirada"></p>
            </NoteContainer>
            <p class="price" v-if="item.alias == 'fob'">Não incluso</p>
            <p class="price" v-else>
              {{
                item.valor == 0 && item.gratis
                  ? 'Grátis'
                  : item.valor | currency
              }}
            </p>
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TextButton from '@/components/input/TextButton.vue';
import NoteContainer from '@/components/NoteContainer.vue';

export default {
  name: 'FreightContent',
  data() {
    return {
      updateInputValue: false,
      isComponentLoading: false,
      isLoading: false,
      inputValue: '',
    };
  },
  components: {
    TextButton,
    NoteContainer,
  },
  props: {
    listOnly: {
      type: Boolean,
      default: false,
    },
    fetchCep: {
      type: String,
      default: null,
      require: false,
    },
  },
  computed: {
    ...mapGetters([
      'userAddress',
      'selectedFreightId',
      'freightCep',
      'freightOptions',
      'navigationData',
    ]),
    chosenFreight: {
      get() {
        return this.selectedFreightId;
      },
      async set(value) {
        this.isComponentLoading = true;

        for (let index in this.freightOptions) {
          let el = this.freightOptions[index];
          if (el.id === value) {
            await this.setFreight(el.id);
            this.isComponentLoading = false;
          }
        }
      },
    },
  },
  watch: {
    userAddress(value) {
      this.inputValue = value.cep;
    },
  },
  methods: {
    ...mapMutations({
      updateNavigation: 'UPDATE_NAVIGATION',
      updateUserAddress: 'UPDATE_USER_ADDRESS',
      validateUserAddress: 'VALIDATE_USER_ADDRESS',
      setInitAction: 'SET_INIT_ACTION',
      openModal: 'UPDATE_MODAL',
    }),
    ...mapActions({
      calcFreight: 'CALC_FREIGHT',
      setFreight: 'SET_FREIGHT',
    }),
    async handleAdd(event) {
      let regex = /^[0-9]{8}$/;

      if (event.trim() === '') {
        this.$toast.info('Nenhum CEP informado');
        return;
      }

      if (!regex.test(event.replace('-', ''))) {
        this.$toast.info('CEP inválido');
        return;
      }

      if (this.fetchCep && this.fetchCep === this.freightCep) {
        return;
      }

      this.isLoading = true;
      this.validateUserAddress(false);
      this.$emit('update:isFetching', true);
      this.updateNavigation({ freightLoading: true });

      const response = await this.calcFreight(event);

      if (response) {
        if (!this.fetchCep) {
          this.updateUserAddress(response);
        }
      } else {
        this.inputValue = this.freightCep ? this.freightCep : null;
        this.updateInputValue = true;

        if (this.fetchCep) {
          console.error('CEP cadastrado inválido');
          setTimeout(() => {
            this.setInitAction('ConnectionIssue');
          }, 2500);
        }
      }

      this.isLoading = false;
      this.$emit('update:isFetching', false);
      this.updateNavigation({ freightLoading: false });
    },
    formatText(item) {
      if (item.mensagem) {
        return item.mensagem;
      }

      if (typeof item.entrega !== 'number' || item.entrega < 0) return false;

      let response;

      switch (item.entrega) {
        case 0:
          response = 'Disponível para retirada';
          break;
        case 1:
          response = 'Em média 1 dia útil';
          break;
        default:
          response = `Em média ${item.entrega} dias úteis`;
          break;
      }

      if (item.nome === 'Frete Grátis') response += ` (${item.transportadora})`;

      return response;
    },
    showFobModal() {
      this.openModal({
        isVisible: true,
        component: 'ContentFob',
      });
    },
  },
  created() {
    if (this.userAddress) {
      if (this.freightCep) {
        this.inputValue = this.userAddress.cep;
      }
    }

    if (this.fetchCep) {
      this.handleAdd(this.fetchCep);
    }
  },
};
</script>

<style lang="scss">
.freight-input-container {
  margin-bottom: 1rem;
}

.location-link {
  color: var(--secondary-link-color);
  font-size: 0.75rem;
  text-decoration: underline;
  font-weight: 600;
  transition: color 150ms ease-out;

  &:hover,
  &:focus {
    color: var(--secondary-link-color-hover);
  }
}

.freight-container {
  .freight-option {
    display: grid;
    grid-template-columns: 1rem 1fr auto;
    gap: 0 0.5rem;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    user-select: none;
    padding: 0.625rem 0;
  }

  input[type='radio'] {
    grid-column: 1;
    grid-row: 1;
  }

  .name {
    grid-column: 2 / 3;
    grid-row: 1;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--dark-text-color);
  }

  .deadline {
    grid-column: 2 / 3;
    grid-row: 2;
    font-size: 0.75rem;
    color: var(--light-text-color);
  }

  .fob-button {
    background-color: transparent;
    padding: 0.25rem;
    margin: -0.25rem;
    border-radius: 0.125rem;
    border: none;
    color: var(--light-text-color);
    text-decoration: underline;
    font-size: 0.75rem;
    cursor: pointer;
    transition: color 150ms ease-out;

    &:hover,
    &:focus {
      color: var(--secondary-button-hover);
    }
  }

  .price {
    grid-column: 3;
    grid-row: 1 / span;
    font-size: 0.875rem;
    color: var(--freight-value-color);
    font-weight: 600;
  }

  .note {
    grid-column: 2 / 3;
    grid-row: 3;
    margin-top: 0.75rem;
    width: 100%;
    max-width: 100%;

    @media screen and (max-width: $sm) {
      grid-column: 1 / -1;
    }
  }
}
</style>