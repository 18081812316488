import Vue from 'vue'
import VueRouter from 'vue-router'
import getPagarmeData from '../helpers/createPagarmeToken.js'
import createReCaptchaToken from '../helpers/createReCaptchaToken.js'
import fetchCEP from '../helpers/fetchCEP.js'
import importThemeStyle from '../helpers/importThemeStyle.js'
import { api } from '../services.js'
import store from '../store/index.js'
import Finish from '../views/Finish.vue'
import MainView from '../views/MainView.vue'
import Payment from '../views/Payment.vue'
import ShoppingCart from '../views/ShoppingCart.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainView,
    children: [
      {
        path: '/',
        component: ShoppingCart,
        name: 'ShoppingCart',
        meta: {
          requiresAuth: false,
          navigation: {
            pageTitle: 'Carrinho de compras',
            nextStep: '/pagamento',
            buttonComponent: 'CartButton'
          }
        }, beforeEnter: (to, from, next) => {
          resetModal()
          updateNavigation(to, from)
          next()
        }
      },
      {
        path: '/pagamento',
        component: Payment,
        name: 'Payment',
        meta: {
          requiresAuth: true,
          navigation: {
            pageTitle: 'Finalização da compra',
            nextStep: '/compra-concluida',
            buttonComponent: 'FinishButton'
          }
        },
        beforeEnter: async (to, from, next) => {
          if (store.getters.navigationData.productLoading) {
            Vue.$toast.info('Aguarde a atualização do produto')
            return false
          }

          if (store.getters.navigationData.couponLoading) {
            Vue.$toast.info('Aguarde o processamento do cupom')
            return false
          }

          if (store.getters.navigationData.freightLoading) {
            Vue.$toast.info('Aguarde o processamento do frete')
            return false
          }

          if (!store.getters.selectedFreight) {
            next({ name: 'ShoppingCart' })
            Vue.$toast.info('Selecione um frete para continuar')
            return false
          }

          store.commit('UPDATE_NAVIGATION', { isLoading: true })

          const response = await getUserValidation(to)

          store.commit('UPDATE_NAVIGATION', { isLoading: false })

          if (response) {
            updateNavigation(to, from)
            next()
          }
        }
      }
    ]
  },
  {
    path: '/compra-concluida',
    component: Finish,
    name: 'Finish',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      if (from.path !== '/pagamento') {
        next('/')

        return false
      }

      if (!store.getters.cartId) {
        next('/')

        return false
      }

      if (store.getters.isPaymentBeingProcessed) {
        Vue.$toast.info('O pagamento está sendo processado')
        return false
      }

      if (store.getters.navigationData.paymentLoading) {
        Vue.$toast.info('Alterando forma de pagamento')
        return false
      }

      const selectedPayment = store.getters.selectedPayment
      const freightStep = store.getters.selectedFreight
      const userStep = store.getters.isUserDataValid
      const deliveryStep = store.getters.isUserAddressValid

      const sellerList = store.getters.purchaseSeller
      const seller = sellerList ? store.getters.purchaseSeller.id : null

      if (!freightStep) {
        Vue.$toast.error('Selecione uma opção de frete')

        store.commit('UPDATE_MODAL', {
          isVisible: true,
          component: store.getters.accountType === 'J' ? 'CompanyFreight' : 'FreightContent'
        })

        return false
      }

      if (!userStep) {
        Vue.$toast.error('Verifique os dados do destinatário')

        return false
      }

      if (!deliveryStep) {
        Vue.$toast.error('Verifique o endereço de entrega')

        return false
      }

      if (sellerList && !seller) {
        store.commit('UPDATE_SELLER', {
          id: null,
          alert: true
        })

        store.commit('UPDATE_MODAL', {
          isVisible: true,
          component: 'SellerSelector'
        })


        Vue.$toast.error('Selecione um vendedor')

        return false
      }

      if (!selectedPayment.isValid) {
        Vue.$toast.error('Verifique as informações de pagamento')

        return false
      }

      if (!await getUserValidation(to)) {
        Vue.$toast.error('Falha ao validar usuário')

        return false
      }

      store.commit('UPDATE_NAVIGATION', {
        buttonComponent: 'LoadingButton',
        isLoading: true
      })

      store.commit('UPDATE_PAYMENT', {
        processingPayment: true
      })

      const gateway = store.getters.selectedPayment.gateway
      const paymentData = await selectedPayment.paymentData

      let data = {
        cartId: store.getters.cartId,
        paymentMethod: selectedPayment.method,
        paymentData: paymentData,
        ...await createReCaptchaToken()
      }

      if (gateway == 'pagarme' && store.getters.selectedPayment.method === 'credito') {
        try {
          const pagarmeData = await getPagarmeData(paymentData)
          data.paymentData = pagarmeData
        } catch (error) {
          store.commit('INVALIDATE_CARD_TOKEN', true)

          resetModal()

          store.commit('UPDATE_PAYMENT', {
            processingPayment: false
          })

          store.commit('UPDATE_NAVIGATION', {
            buttonComponent: 'FinishButton',
            isLoading: false
          })

          Vue.$toast.error(error.message)
          return false
        }
      }

      try {
        return await api.post('/checkout/processa_pagamento', data)
          .then(res => {
            if (res.data.success) {
              store.commit('UPDATE_CART', res.data.carrinho)
              store.commit('INVALIDATE_CARD_TOKEN', false)

              if (store.getters.selectedPayment.method !== 'pix' && window.dataLayer) {
                window.dataLayer.push({
                  'event': 'conversion',
                  'value': res.data?.carrinho?.valor_transacao ? res.data?.carrinho?.valor_transacao : 1.0,
                  'currency': 'BRL',
                  'transaction_id': store.getters.cartId
                })
              }

              next()
              return true
            }
          })
      } catch (err) {
        if (typeof err.response.data.redirect !== "undefined" && err.response.data.redirect !== null) {
          window.location.assign(err.response.data.redirect)
        }

        console.error('Erro no pagamento', err.response.data)

        Vue.$toast.error(err.response.data.message ? err.response.data.message : 'Erro ao processar pagamento')

        store.commit('INVALIDATE_CARD_TOKEN', true)

        resetModal()

        store.commit('UPDATE_PAYMENT', {
          processingPayment: false
        })

        store.commit('UPDATE_NAVIGATION', {
          buttonComponent: 'FinishButton',
          isLoading: false
        })

        return false
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
})

router.beforeEach(async (to, from, next) => {
  importThemeStyle()

  if (!store.getters.storeConfig) {
    await store.dispatch('GET_CONFIG')
  }

  if (!store.getters.cartId) {
    await store.dispatch('FETCH_DATA')
  }

  next()
})

function resetModal() {
  store.commit('UPDATE_MODAL', {
    isVisible: false,
    component: null,
    canClose: null
  })
}

function updateNavigation(to, from) {
  let navigation = null

  if (from.meta.navigation || to.meta.navigation) {
    navigation = from.meta.navigation
    navigation = to.meta.navigation
  }

  if (navigation) {
    store.commit('UPDATE_NAVIGATION', navigation)
  }
}

async function validateAddressFromDB(address) {
  const freightCEP = store.getters.userAddress.cep
  const accountType = store.getters.accountType

  function saveFromDB() {
    store.commit('UPDATE_USER_ADDRESS', {
      bairro: address.bairro,
      cep: address.cep,
      cidade: address.cidade,
      complemento: address.complemento,
      endereco: address.endereco,
      numero: address.numero,
      uf: address.uf
    })
  }

  async function fetchAddress() {
    const response = await fetchCEP(store.getters.freightCep)

    store.commit('UPDATE_USER_ADDRESS', response)

    return response
  }

  if (accountType === 'J') {
    saveFromDB()

    if (address.cep && store.getters.selectedFreight.destino !== address.cep) {
      store.commit('UPDATE_MODAL', {
        isVisible: true,
        component: 'CompanyFreight'
      })
    }

    return true
  }

  if (address.cep == freightCEP && address.bairro && address.cep && address.cidade && address.endereco && address.numero && address.uf) {
    saveFromDB()
    return true
  } else {
    return await fetchAddress()
  }
}

function filterUserData(data) {
  switch (data.tipo) {
    case 'F':
      store.commit('UPDATE_USER_DATA', {
        tipo: data.tipo,
        nome: data.nome,
        sobrenome: data.sobrenome,
        telefone: data.telefone,
        nascimento: data.nascimento,
        cpf_cnpj: data.cpf_cnpj,
        email: data.email
      })
      break
    case 'J':
      store.commit('UPDATE_USER_DATA', {
        tipo: data.tipo,
        email: data.email,
        razao_social: data.razao_social,
        nome_fantasia: data.nome_fantasia,
        cpf_cnpj: data.cpf_cnpj,
        inscricao_estadual: data.inscricao_estadual,
        telefone: data.telefone,
        responsavel: data.responsavel,
        tipo_tributacao: data.tipo_tributacao,
        simples_nacional: data.simples_nacional
      })
      break
    default:
      store.commit('SET_INIT_ACTION', 'ConnectionIssue')
      console.error('Dados de usuário inválidos')
  }
}

async function getUserValidation(to) {
  if (!to.meta.requiresAuth) {
    return true
  }

  store.commit('UPDATE_NAVIGATION', { buttonComponent: 'LoadingButton' })
  try {
    return await api.get('/auth')
      .then(async res => {
        if (res.data) {
          filterUserData(res.data)

          if (!res.data.endereco) {
            router.push('/')

            store.commit('UPDATE_NAVIGATION', { buttonComponent: 'CartButton' })

            console.warn('Nenhum CEP informado')

            Vue.$toast.info('Calcule o frete novamente')

            return false
          }

          await validateAddressFromDB(res.data.endereco)

          return true
        }

        window.location.assign(`${Vue.prototype.$baseURL}/minha-conta?redirect=${window.location.origin}/pagamento`)
        return false
      })
  } catch (err) {
    console.error(err)
    store.commit('UPDATE_NAVIGATION', { buttonComponent: 'CartButton' })
    store.commit('SET_INIT_ACTION', 'ConnectionIssue')
    return false
  }
}

export default router