<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.236"
    height="16"
    viewBox="0 0 19.236 16"
  >
    <path
      d="M21.906,967.31a.251.251,0,0,0-.221.25v2.981h-6.44a.251.251,0,0,0-.25.25v3.445a.251.251,0,0,0,.25.25h6.44v2.981a.251.251,0,0,0,.428.171l4.906-4.949a.251.251,0,0,0,0-.349l-4.906-4.956A.251.251,0,0,0,21.906,967.31Zm-8.921,5.6a.251.251,0,0,0-.15.071l-4.906,4.95a.251.251,0,0,0,0,.357l4.906,4.949a.251.251,0,0,0,.428-.178v-2.974H19.7a.251.251,0,0,0,.25-.25v-3.445a.251.251,0,0,0-.25-.257h-6.44v-2.974a.251.251,0,0,0-.278-.25Z"
      transform="translate(-7.854 -967.308)"
    />
  </svg>
</template>

<script>
export default {
  name: 'DepositoIcon',
};
</script>