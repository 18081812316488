<template>
  <div class="box">
    <h2>Pedido efetuado</h2>

    <p>Utilize os dados abaixo para realizar o depósito.</p>

    <div class="payment-data-container">
      <div>
        <p v-if="deposit.banco">
          <strong>
            Banco {{ deposit.banco }}<br />
            Agência {{ deposit.agencia }}<br />
            Conta {{ deposit.conta }}<br />
            {{ deposit.titular }}<br />
            CNPJ {{ deposit.cnpj }}
          </strong>
        </p>
        <p v-else>
          Entre em contato para obter a conta de depósito -
          <a
            :href="`https://wa.me/+${this.storeConfig.contact.whatsapp.raw}?text=Olá, procuro os dados da conta bancária para depósito do pedido número ${this.cartId}`"
            target="_blank"
            >{{ this.storeConfig.contact.whatsapp.pretty }}
          </a>
        </p>
      </div>
    </div>

    <p v-if="deposit.telefone_whatsapp">
      Após efetuar a transação, encaminhe o comprovante para o WhatsApp
      <a
        :href="`https://wa.me/+${deposit.telefone_whatsapp}`"
        target="_blank"
        >{{ deposit.telefone_comprovante }}</a
      >.
    </p>
    <p v-html="deliveryDate"></p>
    <MainButton
      buttonType="link"
      buttonTarget="_self"
      :buttonLink="$baseURL + '/minha-conta'"
    >
      <span>Painel do usuário</span>
    </MainButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ebitFunctions from '@/mixins/ebitFunctions.js';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'deposito',
  mixins: [ebitFunctions],
  components: {
    MainButton,
  },
  computed: {
    ...mapGetters(['selectedFreight', 'storeConfig', 'cartId']),
    deposit() {
      return this.storeConfig.metodo_pagamento.metodos.deposito;
    },
    deliveryDate() {
      if (this.selectedFreight.mensagem)
        return this.selectedFreight.mensagem + '.';

      if (+this.selectedFreight.entrega < 1) return null;

      const deadline =
        this.selectedFreight.entrega > 1
          ? `${this.selectedFreight.entrega} dias úteis`
          : `${this.selectedFreight.entrega} dia útil`;

      if (this.selectedFreight.alias == 'retirar') {
        return `Você poderá retirar sua encomenda em <strong>${deadline}</strong> após confirmarmos o pagamento.`;
      } else if (this.selectedFreight.alias == 'fob') {
        return `Caso o frete ainda não tenha sido combinado, entre em contato pelo número 
        <a href=https://wa.me/+${this.storeConfig.contact.whatsapp.raw} target=_blank>
          ${this.storeConfig.contact.whatsapp.pretty}
        </a>`;
      }

      return `Sua compra chegará em <strong>até ${deadline}</strong> após
      confirmarmos o pagamento.`;
    },
  },
  mounted() {
    this.ebitInit();
  },
};
</script>

<style lang="scss" scoped>
.payment-data-container div {
  width: max-content !important;
  padding: 1.5rem 3rem !important;
}
</style>