<template>
  <div class="method-content">
    <div class="dois-cartoes">
      <div class="card-container">
        <transition mode="out-in">
          <div class="blocker done" v-if="isSecondCardAvailable">
            <p class="main">Cartão com final {{ firstCardLastDigits }}</p>
            <p class="text">
              A utilização deste cartão para o pagamento de
              <strong>{{ firstCardValues.total | currency }}</strong> em
              <strong>
                {{ firstCardValues.selectedInstallment }}x de
                {{ firstCardValues.value | currency }}
              </strong>
              foi autorizada.
            </p>
            <MainButton
              class="sync-button"
              :isDisabled="isLoading || isAppLoading"
              @click.native="isSecondCardAvailable = false"
            >
              <span>Alterar</span>
            </MainButton>
          </div>
        </transition>

        <h2 class="card-title">Cartão 1</h2>
        <div class="credit-container">
          <label class="card-number">
            <span>Número do cartão</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="firstCard"
              data-property="cardNumber"
              id="cc-number-first-card"
              name="cc-number-first-card"
              autocomplete="cc-number"
              v-model="firstCard.cardNumber"
              v-mask="['#### ###### ##### ', '#### #### #### ####']"
              :class="{
                error: validateFields.firstCard.cardNumber === false,
              }"
              :readonly="isAppLoading ? true : null"
              :disabled="isLoading"
              @focus="handleFocus($event)"
              @blur="
                validateCardNumber($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-expiration-date">
            <span>Validade</span>
            <input
              type="text"
              inputmode="numeric"
              placeholder="MM/AAAA"
              data-reference="firstCard"
              data-property="cardExpirationDate"
              id="cc-exp-first-card"
              name="cc-exp-first-card"
              autocomplete="cc-exp"
              v-model="firstCard.cardExpirationDate"
              v-mask="'##/####'"
              :class="{
                error: validateFields.firstCard.cardExpirationDate === false,
              }"
              :readonly="isAppLoading ? true : null"
              :disabled="isLoading"
              @focus="handleFocus($event)"
              @blur="
                validateExpirationDate($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-security-code">
            <span>CVV</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="firstCard"
              data-property="cardSecurityCode"
              id="cc-csc-first-card"
              name="cc-csc-first-card"
              autocomplete="cc-csc"
              v-model="firstCard.cardSecurityCode"
              v-mask="'####'"
              :class="{
                error: validateFields.firstCard.cardSecurityCode === false,
              }"
              :disabled="isLoading"
              :readonly="isAppLoading ? true : null"
              @focus="handleFocus($event)"
              @blur="
                validateSecurityCode($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-name">
            <span>Nome impresso no cartão</span>
            <input
              type="text"
              data-reference="firstCard"
              data-property="cardHolderName"
              id="cc-name-first-card"
              name="cc-name-first-card"
              autocomplete="cc-name"
              v-model="firstCard.cardHolderName"
              :class="{
                error: validateFields.firstCard.cardHolderName === false,
              }"
              :readonly="isAppLoading ? true : null"
              :disabled="isLoading"
              @focus="handleFocus($event)"
              @blur="
                validateCardHolderName($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-document">
            <span v-if="accountType === 'F'">CPF do titular</span>
            <span v-else>Documento do titular</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="firstCard"
              data-property="cardHolderDocument"
              v-mask="
                accountType === 'J'
                  ? ['###.###.###-##', '##.###.###/####-##']
                  : '###.###.###-##'
              "
              v-model="firstCard.cardHolderDocument"
              :placeholder="
                accountType === 'F' ? '000.000.000-00' : 'CPF ou CNPJ'
              "
              :class="{
                error: validateFields.firstCard.cardHolderDocument === false,
              }"
              :readonly="isAppLoading ? true : null"
              :disabled="isLoading"
              @focus="handleFocus($event)"
              @blur="
                validateCardHolderDocument($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-value">
            <span>Valor</span>
            <money
              inputmode="numeric"
              data-reference="firstCard"
              data-property="purchasePrice"
              v-bind="money"
              v-model="firstCard.purchasePrice"
              :class="{
                error: validateFields.firstCard.purchasePrice === false,
              }"
              :readonly="isAppLoading ? true : null"
              :disabled="isLoading"
              @change.native="validateCardValue()"
              @focus.native="
                handleFocus($event);
                ($event) => {
                  firstCardCurrentValue = $event;
                };
              "
              @blur.native="
                validateCardValue();
                showCheckout();
                updateCartValue();
              "
            />
            <span
              class="message red"
              v-if="firstCard.purchasePrice < firstCardMinValue"
            >
              Valor mínimo de
              {{ (minPurchaseValue / 2) | currency }}
            </span>
            <span
              class="message red"
              v-else-if="firstCard.purchasePrice > firstCardMaxValue"
            >
              Valor máximo de
              {{
                +(
                  cartTotal.total_prazo_sem_juros -
                  minPurchaseValue / 2
                ).toFixed(2) | currency
              }}
            </span>
            <span class="message" v-else>
              Restante:
              {{
                (cartTotal.total_prazo_sem_juros - firstCard.purchasePrice)
                  | currency
              }}
              <template v-if="cartTotal.juros"> com juros</template>
            </span>
          </label>
          <label class="card-installments">
            <span>Parcelamento</span>
            <div class="select-wrapper">
              <select
                v-model.number="firstCard.purchaseInstallments"
                :disabled="isLoading"
                :class="{ readonly: isAppLoading }"
                @change="updateCartValue()"
                @focus="hideCheckout()"
                @blur="showCheckout()"
              >
                <option
                  disabled
                  :value="firstCard.purchaseInstallments"
                  v-if="fetchingInstallments"
                >
                  Carregando...
                </option>
                <option
                  v-else
                  v-for="(installment, key) in firstCard.installments"
                  :value="key"
                  :key="key"
                  :selected="key == 1"
                >
                  {{ key }}x de {{ installment.valor | currency }}
                  {{ installment.porcentagem ? 'com' : 'sem' }} juros
                </option>
              </select>
              <span class="arrow" :class="{ disabled: fetchingInstallments }">
                <SelectArrow />
              </span>
            </div>
            <div class="interest-note" v-if="cartTotal.juros">
              <transition mode="out-in" name="opacity">
                <div
                  class="interest-skeleton-container"
                  v-if="fetchingInstallments"
                >
                  <span></span>
                  <span></span>
                </div>
                <p v-else>
                  {{ firstCardValues.selectedInstallment }}x de
                  {{ firstCardValues.value | currency }}
                  com juros de
                  {{ firstCardValues.interest }}% a.m.<br />
                  <strong>
                    Total
                    {{ firstCardValues.total | currency }}
                  </strong>
                </p>
              </transition>
            </div>
          </label>
        </div>
      </div>

      <div class="card-container">
        <transition mode="out-in">
          <div class="blocker" v-if="!isSecondCardAvailable">
            <p class="main">Cartão 2</p>
            <p class="text">
              Preencha os dados do primeiro cartão e valide as informações
              através do botão abaixo para liberar o segundo cartão.
            </p>
            <MainButton
              class="sync-button"
              :isDisabled="isAppLoading || isLoading"
              :isLoading="validatingCard"
              @click.native="validateFirstCard"
            >
              <span>Validar cartão</span>
            </MainButton>
          </div>
        </transition>

        <h2 class="card-title">Cartão 2</h2>
        <div class="credit-container">
          <label class="card-number">
            <span>Número do cartão</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="secondCard"
              data-property="cardNumber"
              id="cc-number-second-card"
              name="cc-number-second-card"
              autocomplete="cc-number"
              v-model="secondCard.cardNumber"
              v-mask="['#### ###### ##### ', '#### #### #### ####']"
              :readonly="isAppLoading ? true : null"
              :class="{
                error: validateFields.secondCard.cardNumber === false,
              }"
              @focus="handleFocus($event)"
              @blur="
                validateCardNumber($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-expiration-date">
            <span>Validade</span>
            <input
              type="text"
              inputmode="numeric"
              placeholder="MM/AAAA"
              data-reference="secondCard"
              data-property="cardExpirationDate"
              id="cc-exp-second-card"
              name="cc-exp-second-card"
              autocomplete="cc-exp"
              v-model="secondCard.cardExpirationDate"
              v-mask="'##/####'"
              :readonly="isAppLoading ? true : null"
              :class="{
                error: validateFields.secondCard.cardExpirationDate === false,
              }"
              @focus="handleFocus($event)"
              @blur="
                validateExpirationDate($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-security-code">
            <span>CVV</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="secondCard"
              data-property="cardSecurityCode"
              id="cc-csc-second-card"
              name="cc-csc-second-card"
              autocomplete="cc-csc"
              v-model="secondCard.cardSecurityCode"
              v-mask="'####'"
              :readonly="isAppLoading ? true : null"
              :class="{
                error: validateFields.secondCard.cardSecurityCode === false,
              }"
              @focus="handleFocus($event)"
              @blur="
                validateSecurityCode($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-name">
            <span>Nome impresso no cartão</span>
            <input
              type="text"
              data-reference="secondCard"
              data-property="cardHolderName"
              id="cc-name-second-card"
              name="cc-name-second-card"
              autocomplete="cc-name"
              v-model="secondCard.cardHolderName"
              :readonly="isAppLoading ? true : null"
              :class="{
                error: validateFields.secondCard.cardHolderName === false,
              }"
              @focus="handleFocus($event)"
              @blur="
                validateCardHolderName($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-document">
            <span v-if="accountType === 'F'">CPF do titular</span>
            <span v-else>Documento do titular</span>
            <input
              type="text"
              inputmode="numeric"
              data-reference="secondCard"
              data-property="cardHolderDocument"
              v-mask="
                accountType === 'J'
                  ? ['###.###.###-##', '##.###.###/####-##']
                  : '###.###.###-##'
              "
              v-model="secondCard.cardHolderDocument"
              :readonly="isAppLoading ? true : null"
              :placeholder="
                accountType === 'F' ? '000.000.000-00' : 'CPF ou CNPJ'
              "
              :class="{
                error: validateFields.secondCard.cardHolderDocument === false,
              }"
              @focus="handleFocus($event)"
              @blur="
                validateCardHolderDocument($event);
                validateStep();
                showCheckout();
              "
            />
          </label>
          <label class="card-value">
            <span>Valor</span>
            <money
              inputmode="numeric"
              readonly="true"
              disabled="true"
              data-reference="secondCard"
              data-property="purchasePrice"
              v-bind="money"
              v-model="secondCard.purchasePrice"
              :class="{
                error: validateFields.secondCard.purchasePrice === false,
              }"
              @focus.native="handleFocus($event)"
              @change.native="validateCardValue()"
              @blur.native="
                validateCardValue();
                showCheckout();
                updateCartValue();
              "
            />
          </label>
          <label class="card-installments">
            <span>Parcelamento</span>
            <div class="select-wrap">
              <select
                v-model.number="secondCard.purchaseInstallments"
                :class="{ readonly: isAppLoading }"
                @change="updateCartValue()"
                @focus="hideCheckout()"
                @blur="showCheckout()"
              >
                <option
                  disabled
                  :value="secondCard.purchaseInstallments"
                  v-if="fetchingInstallments"
                >
                  Carregando...
                </option>
                <option
                  v-else
                  v-for="(installment, key) in secondCard.installments"
                  :value="key"
                  :key="key"
                  :selected="key == 1"
                >
                  {{ key }}x de {{ installment.valor | currency }}
                  {{ installment.porcentagem ? 'com' : 'sem' }} juros
                </option>
              </select>
              <span class="arrow" :class="{ disabled: fetchingInstallments }">
                <SelectArrow />
              </span>
            </div>
            <div class="interest-note" v-if="cartTotal.juros">
              <transition mode="out-in" name="opacity">
                <div
                  class="interest-skeleton-container"
                  v-if="fetchingInstallments"
                >
                  <span></span>
                  <span></span>
                </div>
                <p v-else>
                  {{ secondCardValues.selectedInstallment }}x de
                  {{ secondCardValues.value | currency }}
                  com juros de
                  {{ secondCardValues.interest }}% a.m.<br />
                  <strong>
                    Total
                    {{ secondCardValues.total | currency }}
                  </strong>
                </p>
              </transition>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Money } from 'v-money';
import { api } from '@/services.js';
import validateText from '@/helpers/validateText.js';
import validateCPF from '@/helpers/validateCPF.js';
import validateCNPJ from '@/helpers/validateCNPJ.js';
import MainButton from '@/components/input/MainButton.vue';
import toggleCheckout from '@/mixins/toggleCheckout.js';
import SelectArrow from '@/components/icons/input/SelectArrow';

export default {
  name: 'dois_cartoes',
  mixins: [toggleCheckout],
  data() {
    return {
      isLoading: false,
      fetchingInstallments: false,
      validatingCard: false,
      isSecondCardAvailable: false,
      firstCardCurrentValue: 0,
      firstCard: {
        cardNumber: null,
        formattedCardNumber: null,
        cardExpirationDate: null,
        cardExpirationMonth: null,
        cardExpirationYear: null,
        cardSecurityCode: null,
        cardHolderName: null,
        cardHolderDocumentType: null,
        cardHolderDocument: null,
        cardCompany: null,
        purchasePrice: 0,
        purchaseInstallments: 1,
        installments: {},
      },
      secondCard: {
        cardNumber: null,
        formattedCardNumber: null,
        cardExpirationDate: null,
        cardExpirationMonth: null,
        cardExpirationYear: null,
        cardSecurityCode: null,
        cardHolderName: null,
        cardHolderDocumentType: null,
        cardHolderDocument: null,
        cardCompany: null,
        purchasePrice: 0,
        purchaseInstallments: 1,
        installments: {},
      },
      validateFields: {
        firstCard: {
          cardNumber: null,
          cardExpirationDate: null,
          cardSecurityCode: null,
          cardHolderName: null,
          cardHolderDocument: null,
          purchasePrice: null,
        },
        secondCard: {
          cardNumber: null,
          cardExpirationDate: null,
          cardSecurityCode: null,
          cardHolderName: null,
          cardHolderDocument: null,
          purchasePrice: null,
        },
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  components: {
    Money,
    MainButton,
    SelectArrow,
  },
  computed: {
    ...mapGetters([
      'cartTotal',
      'selectedPayment',
      'isAppLoading',
      'accountType',
      'storeConfig',
    ]),
    firstCardLastDigits() {
      const card = this.firstCard.formattedCardNumber;

      if (card) {
        return card.slice(card.length - 4);
      }

      return null;
    },
    minPurchaseValue() {
      return this.storeConfig.metodo_pagamento.metodos.dois_cartoes
        .valor_minimo_compra;
    },
    firstCardValues() {
      const selectedInstallment = this.firstCard.purchaseInstallments;
      const value = this.firstCard.installments
        ? this.firstCard.installments[selectedInstallment]?.valor
        : null;
      const interest = this.firstCard.installments
        ? this.firstCard.installments[selectedInstallment]?.porcentagem
        : null;
      const total = this.firstCard.installments
        ? this.firstCard.installments[selectedInstallment]?.total
        : null;

      return {
        selectedInstallment,
        value,
        interest,
        total,
      };
    },
    secondCardValues() {
      const selectedInstallment = this.secondCard.purchaseInstallments;
      const value = this.firstCard.installments
        ? this.secondCard.installments[selectedInstallment].valor
        : null;
      const interest = this.firstCard.installments
        ? this.secondCard.installments[selectedInstallment].porcentagem
        : null;
      const total = this.firstCard.installments
        ? this.secondCard.installments[selectedInstallment].total
        : null;

      return {
        selectedInstallment,
        value,
        interest,
        total,
      };
    },
    firstCardMinValue() {
      return this.minPurchaseValue / 2;
    },
    firstCardMaxValue() {
      return this.cartTotal.total_prazo_sem_juros - this.minPurchaseValue / 2;
    },
  },
  watch: {
    'firstCard.purchasePrice': function (value) {
      this.secondCard.purchasePrice = this.cartTotal.total_prazo - value;

      if (!this.cartTotal.juros) {
        this.calculateInstallments();
      }
    },
    'firstCard.purchaseInstallments': function () {
      this.validateStep();
    },
    'secondCard.purchaseInstallments': function () {
      this.validateStep();
    },
    isSecondCardAvailable() {
      this.validateStep();
    },
    cartTotal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (this.cartTotal.juros) {
          this.firstCard.installments = value?.dois_cartoes?.parcelas?.cartao1;
          this.secondCard.installments = value?.dois_cartoes?.parcelas?.cartao2;

          this.firstCardCurrentValue = value?.dois_cartoes?.valores?.cartao1;
          this.firstCard.purchasePrice = value?.dois_cartoes?.valores?.cartao1;
          this.secondCard.purchasePrice = value?.dois_cartoes?.valores?.cartao2;
        } else {
          this.firstCard.purchasePrice = (value.total_prazo / 2).toFixed(2);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
    }),
    ...mapActions({
      updateCart: 'UPDATE_CART',
    }),
    handleFocus(element) {
      this.hideCheckout();

      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = null;
    },
    validateCardNumber(element) {
      const value = element.target.value.trim();
      const formattedValue = value.replace(/[^0-9]+/g, '');
      let cardCompany = null;

      if (formattedValue.length >= 15) {
        cardCompany = this.getCardCompany(formattedValue);

        this[element.target.dataset.reference].cardCompany = cardCompany;

        this[element.target.dataset.reference].formattedCardNumber =
          formattedValue;

        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = true;

        return true;
      }

      this[element.target.dataset.reference].cardCompany = null;

      this[element.target.dataset.reference].formattedCardNumber = null;

      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = false;

      return false;
    },
    getCardCompany(value) {
      const companies = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex: /^3[47][0-9]{13}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      };

      for (let company in companies) {
        if (companies[company].test(value)) {
          return company;
        }
      }

      return null;
    },
    validateExpirationDate(element = null) {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (
        !this[element.target.dataset.reference][
          element.target.dataset.property
        ] ||
        this[element.target.dataset.reference][element.target.dataset.property]
          .length < 7
      ) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = false;
        return false;
      }

      let [expirationMonth, expirationYear] =
        this[element.target.dataset.reference][
          element.target.dataset.property
        ].split('/');

      expirationMonth = +expirationMonth;
      expirationYear = +expirationYear;

      if (expirationMonth < 1 || expirationMonth > 12) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = false;
        return false;
      }

      if (expirationYear < year) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = false;
        return false;
      }

      if (expirationYear === year && expirationMonth < month) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = false;
        return false;
      }

      this[element.target.dataset.reference]['cardExpirationMonth'] =
        expirationMonth.toString().padStart(2, '0');
      this[element.target.dataset.reference]['cardExpirationYear'] =
        expirationYear.toString().substring(2, 4);

      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = true;
      return true;
    },
    validateSecurityCode(element) {
      const regex = /^[0-9]{3,4}$/;

      if (regex.test(element.target.value.trim())) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = true;
        return true;
      }

      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = false;
      return false;
    },
    validateCardHolderName(element) {
      if (validateText(element.target.value.trim(), '^[a-zA-ZÀ-ú ]*$')) {
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = true;
        return true;
      }

      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = false;
      return false;
    },
    validateCardHolderDocument(element) {
      if (validateCPF(element.target.value.trim())) {
        this[element.target.dataset.reference].cardHolderDocumentType = 'cpf';
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = true;
        return true;
      }

      if (validateCNPJ(element.target.value.trim())) {
        this[element.target.dataset.reference].cardHolderDocumentType = 'cnpj';
        this.validateFields[element.target.dataset.reference][
          element.target.dataset.property
        ] = true;
        return true;
      }

      this[element.target.dataset.reference].cardHolderDocumentType = null;
      this.validateFields[element.target.dataset.reference][
        element.target.dataset.property
      ] = false;
      return false;
    },
    validateCardValue() {
      const isValid =
        this.firstCard.purchasePrice >= this.firstCardMinValue &&
        this.firstCard.purchasePrice <= this.firstCardMaxValue;

      this.validateFields.firstCard.purchasePrice = isValid;
      this.validateFields.secondCard.purchasePrice = isValid;

      return isValid;
    },
    calculateInstallments() {
      const minValue = +this.selectedPayment.valor_minimo_parcela;
      const maxInstallments = +this.selectedPayment.numero_maximo_parcelas;

      this.firstCard.installments = {};
      this.secondCard.installments = {};

      for (let i = 1; i <= maxInstallments; i++) {
        if (this.firstCard.purchasePrice / i >= minValue) {
          this.firstCard.installments[i] = {
            valor: +(this.firstCard.purchasePrice / i).toFixed(2),
            porcentagem: 0,
            total: +this.firstCard.purchasePrice,
          };
        }

        if (this.secondCard.purchasePrice / i >= minValue) {
          this.secondCard.installments[i] = {
            valor: +(this.secondCard.purchasePrice / i).toFixed(2),
            porcentagem: 0,
            total: +this.secondCard.purchasePrice,
          };
        }
      }

      if (!this.firstCard.installments.length) {
        this.firstCard.installments[1] = {
          valor: +this.firstCard.purchasePrice,
          porcentagem: 0,
          total: +this.firstCard.purchasePrice,
        };
      }

      if (!this.secondCard.installments.length) {
        this.secondCard.installments[1] = {
          valor: +this.secondCard.purchasePrice,
          porcentagem: 0,
          total: +this.secondCard.purchasePrice,
        };
      }

      if (
        this.firstCard.purchaseInstallments > this.firstCard.installments.length
      ) {
        this.firstCard.purchaseInstallments =
          this.firstCard.installments.length;
      }

      if (
        this.secondCard.purchaseInstallments >
        this.secondCard.installments.length
      ) {
        this.secondCard.purchaseInstallments =
          this.secondCard.installments.length;
      }
    },
    validateCard(card, showFeedback = false) {
      const error = [];

      Object.entries(this.validateFields[card]).forEach((entry) => {
        const [property, value] = entry;

        if (!value) {
          error.push(property);
        }
      });

      if (error.length) {
        if (showFeedback) {
          error.forEach((property) => {
            this.validateFields[card][property] = false;
          });
        }
        return false;
      }

      return true;
    },
    validateStep() {
      const firstCard = this.validateCard('firstCard');
      const secondCard = this.validateCard('secondCard');

      if (firstCard && secondCard && this.isSecondCardAvailable) {
        this.validatePayment({
          isValid: true,
          paymentData: {
            firstCard: {
              cardNumber: this.firstCard.formattedCardNumber,
              cardCompany: this.firstCard.cardCompany,
              cardExpirationMonth: this.firstCard.cardExpirationMonth,
              cardExpirationYear: this.firstCard.cardExpirationYear,
              cardSecurityCode: this.firstCard.cardSecurityCode,
              cardHolderName: this.firstCard.cardHolderName,
              cardHolderDocumentType: this.firstCard.cardHolderDocumentType,
              cardHolderDocument: this.firstCard.cardHolderDocument,
              purchasePrice: this.firstCard.purchasePrice,
              purchaseInstallments: this.firstCard.purchaseInstallments,
            },
            secondCard: {
              cardNumber: this.secondCard.formattedCardNumber,
              cardCompany: this.secondCard.cardCompany,
              cardExpirationMonth: this.secondCard.cardExpirationMonth,
              cardExpirationYear: this.secondCard.cardExpirationYear,
              cardSecurityCode: this.secondCard.cardSecurityCode,
              cardHolderName: this.secondCard.cardHolderName,
              cardHolderDocumentType: this.secondCard.cardHolderDocumentType,
              cardHolderDocument: this.secondCard.cardHolderDocument,
              purchasePrice: this.secondCard.purchasePrice,
              purchaseInstallments: this.secondCard.purchaseInstallments,
            },
          },
        });
        return true;
      }

      this.validatePayment({
        isValid: false,
        paymentData: {},
      });
      return false;
    },
    loadScript() {
      return new Promise((resolve, reject) => {
        const scriptAlreadyAdded = document.head.querySelectorAll(
          'script[src="https://h.online-metrix.net/fp/tags.js?org_id={AMBIENTE}&session_id={NUMERO DE SESSÃO}"]'
        );

        if (scriptAlreadyAdded.length) {
          return resolve();
        }

        let script = document.createElement('script');
        script.setAttribute('type', 'application/javascript');
        script.setAttribute(
          'src',
          'https://h.online-metrix.net/fp/tags.js?org_id={AMBIENTE}&session_id={NUMERO DE SESSÃO}'
        );
        script.defer = true;
        script.async = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject('Failed to load gateway library');
        };

        document.head.appendChild(script);
      });
    },
    async validateFirstCard() {
      if (!this.validateCard('firstCard', true)) return false;
      if (!this.validateCardValue()) return false;

      const firstCard = this.validateCard('firstCard', true);

      if (firstCard) {
        try {
          this.isLoading = true;
          this.validatingCard = true;
          await api
            .post('/checkout/verifica_cartao', this.firstCard)
            .then((res) => {
              this.isLoading = false;
              this.validatingCard = false;
              if (res.status === 200 && res.data?.success) {
                this.isSecondCardAvailable = true;
                return true;
              } else {
                this.isSecondCardAvailable = false;
                this.$toast.error(
                  'Cartão inválido. Verifique os dados informados'
                );
                return false;
              }
            });
        } catch (err) {
          this.isLoading = false;
          this.validatingCard = false;
          this.isSecondCardAvailable = false;
          this.$toast.error('Cartão inválido. Verifique os dados informados');
          return false;
        }
      }
    },
    async updateCartValue() {
      if (!this.cartTotal.juros) return;

      if (!this.validateCardValue()) {
        this.$toast.error('Valor de compra inválido');

        return false;
      }

      this.firstCardCurrentValue = this.firstCard.purchasePrice;

      this.isLoading = true;
      this.fetchingInstallments = true;

      await this.updateCart({
        valor_cartao1: this.firstCard.purchasePrice,
        parcelas: {
          cartao1: this.firstCard.purchaseInstallments,
          cartao2: this.secondCard.purchaseInstallments,
        },
      });

      this.isLoading = false;
      this.fetchingInstallments = false;
    },
  },
  async mounted() {
    this.firstCard.purchasePrice = this.cartTotal.juros
      ? this.cartTotal?.dois_cartoes?.valores?.cartao1
      : (this.cartTotal.total_prazo / 2).toFixed(2);

    this.firstCardCurrentValue = this.firstCard.purchasePrice;
    this.validateCardValue();

    try {
      await this.loadScript();
      this.importStatus = true;
    } catch (error) {
      console.warn(error);
      this.importStatus = false;
    }
  },
  deactivated() {
    this.firstCard.purchaseInstallments = 1;
    this.secondCard.purchaseInstallments = 1;
    this.isSecondCardAvailable = false;

    this.validatePayment({
      isValid: false,
    });
  },
};
</script>

<style scoped lang="scss">
.dois-cartoes {
  .card-container {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem 2rem;
    padding: 0.5rem;
    width: 100%;

    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr;
    }

    & + .card-container {
      margin-top: 3rem;
    }

    .blocker {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--light-filter-color);
      backdrop-filter: blur(10px);
      z-index: 5;
      display: flex;
      flex-flow: column;
      gap: 1.25rem;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $sm) {
        padding: 3rem 1rem;
      }

      .main {
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 0;
        text-align: center;
        max-width: 32.5rem;

        @media screen and (max-width: $sm) {
          font-size: 2rem;
        }
      }

      .text {
        font-size: 1rem;
        text-align: center;
        max-width: 32.5rem;
        margin: 0;
      }

      .sync-button {
        width: 8rem;
      }

      &.done {
        .text {
          max-width: 25rem;
        }
      }
    }

    h2 {
      font-size: 1.25rem;
      margin: 0.25rem 0;
      height: max-content;
    }

    .interest-note {
      margin-top: 0.25rem;
      padding: 0.5rem;
      border-radius: 4px;
      background: var(--note-background-color);

      font-size: 0.75rem;
      overflow: hidden;
      min-height: 4.325em;

      p {
        font-size: inherit;
        line-height: 1.5;
        color: var(--note-title-color);
      }

      .interest-skeleton-container {
        display: flex;
        flex-flow: column;
        gap: 0.5em;

        > span {
          height: 1em;
          display: block;
          background-color: var(--note-text-color);
          border-radius: 2px;

          animation: blink 0.625s infinite alternate;

          @keyframes blink {
            from {
              opacity: 0.15;
            }
            to {
              opacity: 0.3;
            }
          }

          &:first-child {
            width: 85%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }
}

.credit-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem 1.25rem;
  max-width: 35rem;

  .message {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: -0.01em;
    transition: color 150ms ease-out;

    &.red {
      color: var(--alert-color);
    }
  }

  .hidden {
    display: none;
  }

  .card-number,
  .card-name,
  .card-document,
  .card-installments,
  .card-value {
    grid-column: span 2;
  }

  .card-expiration-date,
  .card-security-code {
    grid-column: 1fr;
  }

  @media screen and (max-width: $sm) {
    .card-number,
    .card-name,
    .card-document,
    .card-installments,
    .card-value {
      grid-column: span 4;
    }

    .card-expiration-date,
    .card-security-code {
      grid-column: span 2;
    }
  }
}
</style>
