<template>
  <div
    ref="modal"
    :class="{ 'no-content': noContent }"
    class="modal"
    @click="filterClose($event)"
  >
    <transition mode="out-in">
      <component :is="component"></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FreightContent from '@/components/modal/ModalFreightContent.vue';
import UpdatedFreight from '@/components/modal/ModalUpdatedFreight.vue';
import SellerSelector from '@/components/modal/ModalSellerSelector.vue';
import CompanyFreight from '@/components/modal/ModalCompanyFreight.vue';
import InstallmentsList from '@/components/modal/ModalInstallmentsList.vue';
import ContentFob from '@/components/modal/ModalContentFob.vue';

export default {
  name: 'Modal',
  components: {
    FreightContent,
    UpdatedFreight,
    SellerSelector,
    CompanyFreight,
    InstallmentsList,
    ContentFob,
  },
  props: {
    component: String,
    isVisible: Boolean,
  },
  computed: {
    ...mapGetters({
      modal: 'modalSettings',
    }),
    noContent() {
      return this.isVisible && !this.component ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      update: 'UPDATE_MODAL',
    }),
    close() {
      this.update({
        isVisible: false,
        component: null,
        canClose: null,
      });
    },
    filterClose(event) {
      const { currentTarget, target } = event;

      if (currentTarget === target && this.modal.canClose) {
        this.close();
      }
    },
  },
  mounted() {
    if (!this.noContent) {
      document.body.classList.add('lock');
    }
  },
  destroyed() {
    setTimeout(() => {
      document.body.classList.remove('lock');
    }, 200);
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--dark-filter-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 25;
  padding: 3rem 1rem;
  overflow-y: auto;

  @media screen and (max-width: $sm) {
    padding: 1rem;
  }

  &.no-content {
    z-index: 10;
  }
}
</style>
