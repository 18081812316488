<template>
  <label class="main-container" :class="{ error: localError }">
    <div class="title-container">
      <p class="title">
        {{ textLabel }}
      </p>
      <transition name="slide">
        <div class="message" v-if="localError">
          <span> Valor inválido </span>
        </div>
      </transition>
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'MainInput',
  data() {
    return {
      timeout: 0,
      localError: false,
    };
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    hasError(value) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.localError = value;
      }, 200);
    },
  },
  created() {
    this.localError = this.hasError;
  },
};
</script>

<style scoped lang="scss">
.main-container {
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 0.125rem;

  .title-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.35rem;
    justify-content: space-between;

    .title {
      font-size: 0.875rem;
      line-height: 1.4;
      font-weight: 600;
      color: var(--dark-text-color);
    }

    .message {
      display: flex;
      flex-flow: row;
      align-items: center;
      position: relative;

      span {
        display: block;
        white-space: nowrap;
        font-size: 0.625rem;
        background-color: var(--alert-color);
        border-radius: 0.25rem;
        padding: 0.1rem 0.25rem 0.025rem;
        color: var(--white-text-color);
        font-weight: 600;
        transition: width 2s ease;
        margin-left: 0.2rem;
      }
    }
  }

  input:not([type='checkbox']) {
    appearance: none;
    color: var(--normal-text-color);
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &.error {
    input[type='text']:not(:disabled),
    input[type='email']:not(:disabled),
    input[type='date']:not(:disabled),
    select:not(:disabled) {
      box-shadow: 0 0 0 0.06125rem var(--alert-color) !important;
    }

    input[type='number'],
    button:not(:disabled) {
      border-color: var(--alert-color) !important;
    }
  }
}
</style>