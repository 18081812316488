<template>
  <ModalContentContainer :closeButton="false" modalTitle="Atualização de frete">
    <p class="text">
      <span v-if="differentCep">
        O CEP de entrega selecionado anteriormente não corresponde ao endereço
        de entrega cadastrado.
      </span>
      Para alterar o endereço de entrega da sua conta jurídica, entre em contato
      com o
      <a :href="$baseURL + '/central-de-atendimento'" target="_blank"
        >nosso SAC</a
      >.
    </p>

    <transition mode="out-in" name="opacity">
      <p class="subtitle" key="fetchingData" v-if="isFetching">
        Calculando frete para o CEP cadastrado...
      </p>
      <p class="subtitle" key="result" v-else>
        Opções de entrega para <strong>{{ userAddress.cep }}</strong>
      </p>
    </transition>

    <div class="freight-container">
      <transition name="opacity" mode="out-in">
        <div class="freight-filter" v-if="isFetching">
          <Loading class="loading-component" />
        </div>
      </transition>
      <FreightContent
        class="freight-content"
        :listOnly="true"
        :fetchCep="userAddress.cep"
        @update:isFetching="isFetching = $event"
      />
    </div>

    <MainButton
      class="main-button"
      :isDisabled="!selectedFreight || isFetching"
      @click.native="closeModal"
    >
      <span>Fechar</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import FreightContent from '@/components/FreightContent.vue';
import MainButton from '../input/MainButton.vue';

export default {
  name: 'CompanyFreight',
  data() {
    return {
      differentCep: false,
      isFetching: false,
    };
  },
  components: {
    FreightContent,
    ModalContentContainer,
    MainButton,
  },
  computed: {
    ...mapGetters(['selectedFreight', 'userAddress', 'freightCep']),
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    closeModal() {
      if (this.selectedFreight) {
        this.updateModal({
          isVisible: false,
          component: null,
          canClose: null,
        });
      }
    },
  },
  created() {
    this.differentCep = this.userAddress.cep !== this.freightCep ? true : false;
  },
};
</script>

<style lang="scss" scoped>
.text {
  max-width: 28rem;
  font-size: 0.875rem;
  color: var(--light-text-color);
  margin-bottom: 1rem;

  a {
    font-weight: 600;
    color: var(--main-link-color);
    transition: color 150ms ease-out;

    &:hover,
    &:focus {
      color: var(--main-link-color-hover);
    }
  }
}

.subtitle {
  margin: 1.5rem 0 0.25rem;
  color: var(--normal-text-color);
  font-size: 0.875rem;
  font-weight: 500;

  strong {
    color: inherit;
  }
}

.freight-container {
  position: relative;
  padding: 0.5rem;

  .freight-filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-filter-color);
    backdrop-filter: blur(10px);
    display: flex;
    flex-flow: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .loading-component {
      flex-grow: unset;
    }
  }

  .freight-content {
    max-width: 25rem;
  }
}

.main-button {
  margin: 1.625rem 0 0 auto;
  width: 7.5rem;
}
</style>