<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
  >
    <path
      d="M142.179,4.96l-8.36,8.969a.221.221,0,0,1-.28.035.218.218,0,0,1-.085-.268l2.513-6.041h-4.51a.22.22,0,0,1-.189-.106.218.218,0,0,1-.005-.216L135.146.115A.22.22,0,0,1,135.34,0h3.378a.22.22,0,0,1,.188.1.218.218,0,0,1,.008.214l-2.22,4.274h5.324a.22.22,0,0,1,.2.131.218.218,0,0,1-.04.236Z"
      transform="translate(-131.238 0.001)"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProgressiveDiscountIcon',
};
</script>