<template>
  <keep-alive>
    <component :is="component">
      <p><strong>Componente não encontrado</strong></p>
    </component>
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';
import toggleMethodAvailability from '@/mixins/toggleMethodAvailability.js';
import getnet from '@/components/payment/getnet/Credito.vue';
import pagseguro from '@/components/payment/pagseguro/Credito.vue';
import mercado_pago from '@/components/payment/mercado_pago/Credito.vue';
import pagarme from '@/components/payment/pagarme/Credito.vue';
import UnavailableMethod from '@/components/payment/UnavailableMethod.vue';

export default {
  name: 'credito',
  mixins: [toggleMethodAvailability],
  components: {
    getnet,
    pagseguro,
    mercado_pago,
    pagarme,
    UnavailableMethod,
  },
  computed: {
    ...mapGetters(['selectedPayment']),
    component() {
      if (!this.isMethodAvailable) return 'UnavailableMethod';

      return this.selectedPayment.method === 'credito'
        ? this.selectedPayment.gateway
        : null;
    },
  },
};
</script>