import { mapGetters } from 'vuex';

const ebitFunctions = {
  computed: {
    ...mapGetters(['storeConfig', 'ebitData'])
  },
  methods: {
    createEbitElements() {
      const id = this.storeConfig?.ebit;
      if (!id) return;

      const queryParams = this.getEbitParameters()
      if (!queryParams) return;

      const parentElement = document.querySelector('.main-content .finish .box')

      const ebitContainer = document.createElement('div')
      ebitContainer.className = "ebitContainer"

      const paramElement = document.createElement('param')
      paramElement.id = "ebitParam"
      paramElement.value = queryParams

      const linkElement = document.createElement('a')
      linkElement.id = "bannerEbit"

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.id = 'getSelo';
      scriptElement.src = `https://imgs.ebit.com.br/ebitBR/selo-ebit/js/getSelo.js?${id}&lightbox=true`;

      ebitContainer.appendChild(paramElement)
      ebitContainer.appendChild(linkElement)

      const scriptAlreadyAdded = document.head.querySelectorAll('#getSelo');
      if (!scriptAlreadyAdded.length) {
        ebitContainer.appendChild(scriptElement)
      }

      parentElement.appendChild(ebitContainer)
    },
    getEbitParameters() {
      let queryParams = []

      for (const [key, value] of Object.entries(this.ebitData)) {
        if (value !== null) {
          queryParams.push(`${key}=${value}`)
        }
      }

      return queryParams.join('&')
    },
    ebitInit() {
      this.createEbitElements()
    }
  }
}

export default ebitFunctions