<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 36">
    <rect width="48" height="36" fill="#fff" />
    <path
      d="M8,8h2V28H8ZM20,8h2V28H20ZM30,8H32V28H30Zm4,0H36V28H34Zm4,0h2V28H38ZM24,8h4V28H24ZM12,8h6V28H12Z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'BoletoIcon',
};
</script>