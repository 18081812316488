<template>
  <div class="box-container">
    <ContentBox title="Produtos" :isLarge="true">
      <CartBox />
    </ContentBox>
    <ContentBox title="Cupom">
      <CouponContent />
    </ContentBox>
    <ContentBox title="Frete">
      <FreightContent />
    </ContentBox>
  </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import CartBox from '@/components/cartBox/CartBox.vue';
import CouponContent from '@/components/CouponContent.vue';
import FreightContent from '@/components/FreightContent.vue';

export default {
  name: 'ShoppingCart',
  components: {
    ContentBox,
    CartBox,
    CouponContent,
    FreightContent,
  },
};
</script>

<style>
</style>