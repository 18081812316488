<template>
  <ModalContentContainer modalTitle="Alterar frete">
    <FreightContent />
    <MainButton
      class="main-button"
      :isDisabled="!selectedFreight"
      @click.native="closeModal"
    >
      <span>Fechar</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import FreightContent from '@/components/FreightContent.vue';
import MainButton from '../input/MainButton.vue';

export default {
  name: 'ModalFreightContent',
  components: {
    FreightContent,
    ModalContentContainer,
    MainButton,
  },
  computed: {
    ...mapGetters(['selectedFreight']),
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    closeModal() {
      if (this.selectedFreight) {
        this.updateModal({
          isVisible: false,
          component: null,
          canClose: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-button {
  margin: 1.625rem 0 0 auto;
  width: 7.5rem;
}
</style>