<template>
  <label :class="{ disabled: isDisabled, readonly: readonly }">
    <input
      type="checkbox"
      v-model="localInputValue"
      :readonly="readonly"
      :disabled="isDisabled"
      :tabindex="readonly ? '-1' : '0'"
      @change="handleChange()"
    />
    <span class="checkmark">
      <CheckIcon />
    </span>
    <p>{{ textLabel }}</p>
  </label>
</template>

<script>
import CheckIcon from '@/components/icons/input/CheckIcon.vue';

export default {
  name: 'CheckboxInput',
  data() {
    return {
      localInputValue: this.inputValue,
    };
  },
  components: {
    CheckIcon,
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    inputValue: {
      type: Boolean,
      require: true,
    },
    readonly: Boolean,
    isDisabled: Boolean,
  },
  watch: {
    inputValue(value) {
      if (value === undefined || value === null) {
        this.localInputValue = false;
        return;
      }

      this.localInputValue = value;
    },
  },
  methods: {
    handleChange() {
      if (this.readonly) return false;

      this.$emit('update:inputValue', this.localInputValue);
    },
  },
  created() {
    if (this.inputValue === undefined || this.inputValue === null) {
      this.localInputValue = false;
    }
  },
};
</script>

<style lang="scss" scoped>
label {
  position: relative;
  width: max-content;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem 0.5rem;
  cursor: pointer;
  user-select: none;
  transition: opacity 150ms ease-out;

  &.disabled {
    cursor: auto;
  }

  &.readonly {
    cursor: auto;
    opacity: 0.5;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkmark {
    width: 1rem;
    height: 1rem;
    box-shadow: 0 0 0 0.06125rem var(--input-border-color),
      0 0 0 0 var(--input-border-color-hover);
    border-radius: 0.25rem;
    margin: 0.125rem 0;
    transition: box-shadow 150ms ease-out, background-color 50ms ease-out;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 0.625rem;
      height: auto;
      transform: translateY(0.5px) rotate(3deg);
    }
  }

  input:focus ~ .checkmark {
    box-shadow: 0 0 0 0.06125rem var(--input-border-color),
      0 0 0 0.175rem var(--input-border-color-hover);
  }

  input:checked ~ .checkmark {
    background-color: var(--main-button-background);
    box-shadow: 0 0 0 0 var(--input-border-color),
      0 0 0 0 var(--input-border-color-hover);

    ::v-deep svg .check {
      stroke-dashoffset: 0;
    }
  }

  p {
    font-size: 0.875rem;
    color: var(--normal-text-color);
  }
}
</style>