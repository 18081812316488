<template>
  <span class="button-text"> Finalizar </span>
</template>

<script>
export default {
  name: 'FinishButton',
};
</script>

<style scoped lang="scss">
span {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--main-button-color);
  pointer-events: none;
  text-align: center;

  @media screen and (max-width: $sm) {
    font-size: 0.75rem;
  }
}
</style>