<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="18"
    viewBox="0 0 26 18"
  >
    <path
      d="M1.889,18A1.888,1.888,0,0,1,0,16.122V3.878A1.888,1.888,0,0,1,1.889,2H2V1.878A1.886,1.886,0,0,1,3.89,0H24.111A1.886,1.886,0,0,1,26,1.878V14.122A1.886,1.886,0,0,1,24.111,16H24V14.844h.111a.725.725,0,0,0,.725-.722V5.155H24v-1.4h.836V1.878a.724.724,0,0,0-.725-.721H3.89a.724.724,0,0,0-.726.721V2H22.11A1.888,1.888,0,0,1,24,3.878V16.122A1.888,1.888,0,0,1,22.11,18Zm-.725-1.877a.727.727,0,0,0,.725.721H22.11a.727.727,0,0,0,.725-.721V7.155H1.164Zm0-12.245V5.756H22.836V3.878a.726.726,0,0,0-.725-.721H1.889A.726.726,0,0,0,1.164,3.878Zm14.559,9.773a.58.58,0,0,1-.581-.579V9.961a.58.58,0,0,1,.581-.579H20.65a.58.58,0,0,1,.582.579v3.111a.58.58,0,0,1-.582.579Zm.582-1.156h3.763V10.539H16.305Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoisCartoesIcon',
};
</script>