<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 221.84 66"
  >
    <defs>
      <clipPath id="a" transform="translate(1.83 0)">
        <rect width="220" height="66" fill="none" />
      </clipPath>
      <clipPath id="b" transform="translate(1.83 0)">
        <path
          d="M24.08,3.48C13.22,3.48,3.81,8,0,9.34v6.32C0,33.24.07,45.38,10.3,55.6,14.24,59.54,23.82,66,23.82,66s9.66-6.45,13.6-10.4C47.67,45.36,47.63,34.6,47.63,18V9.26C42.86,7.79,34.31,3.48,24.08,3.48Z"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="c"
        x1="10.74"
        y1="1507.32"
        x2="10.74"
        y2="1510.83"
        gradientTransform="translate(15.02 -1498.84)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#263238" stop-opacity="0.35" />
        <stop offset="0.42" stop-color="#263238" stop-opacity="0.15" />
        <stop offset="0.89" stop-color="#263238" stop-opacity="0.02" />
        <stop offset="1" stop-color="#263238" stop-opacity="0" />
      </linearGradient>
    </defs>
    <g clip-path="url(#a)">
      <path
        d="M0,9.34v6.33C0,33.24,0,45.39,10.26,55.61,14.2,59.55,23.81,66,23.81,66V3.49C13.81,3.48,3.81,8,0,9.34Z"
        transform="translate(1.83 0)"
        fill="#66bb6a"
      />
      <path
        d="M47.63,9.26C42.86,7.79,33.81,3.48,23.81,3.48V66s9.66-6.45,13.6-10.4C47.66,45.36,47.63,34.6,47.63,18Z"
        transform="translate(1.83 0)"
        fill="#388e3c"
      />
      <g clip-path="url(#b)">
        <rect y="9.34" width="51.53" height="4.24" fill="url(#c)" />
      </g>
      <path
        d="M28.76,51.66c-2.26,2.53-4.91,5.11-4.91,5.11s-2.57-2.44-4.81-4.9A7.75,7.75,0,0,1,28.76,51.66Z"
        transform="translate(1.83 0)"
        fill="#fafafa"
      />
      <path
        d="M24,44.64a13.23,13.23,0,0,0-8.33,2.94,16.7,16.7,0,0,0,1,1.44c.16.23.34.46.53.7a10.69,10.69,0,0,1,13.38-.23c.13-.16.24-.32.35-.47.38-.55.74-1.11,1.07-1.7A13.26,13.26,0,0,0,24,44.64Z"
        transform="translate(1.83 0)"
        fill="#fafafa"
      />
      <path
        d="M24,39a18.92,18.92,0,0,0-10.73,3.3c.31.9.65,1.78,1,2.62A16.18,16.18,0,0,1,24,41.69h0a16.24,16.24,0,0,1,9.35,3,24.85,24.85,0,0,0,1-2.65A18.91,18.91,0,0,0,24,39Z"
        transform="translate(1.83 0)"
        fill="#fafafa"
      />
      <path
        d="M36.67,14.84s-.51,9.71-.51,13.61A44.7,44.7,0,0,1,34.36,42a24.85,24.85,0,0,1-1,2.65A23.92,23.92,0,0,1,32,47.32,18.45,18.45,0,0,1,30.92,49c-.11.15-.22.31-.35.47-.51.68-1.15,1.42-1.81,2.17-2.26,2.53-4.91,5.11-4.91,5.11s-2.57-2.44-4.81-4.9c-.67-.73-1.31-1.47-1.85-2.15-.19-.24-.37-.47-.53-.7-.33-.47-.67-1-1-1.44a25,25,0,0,1-1.4-2.66,22.42,22.42,0,0,1-1-2.62,43.66,43.66,0,0,1-2-13.85c0-3.9-.42-13.61-.42-13.61s6.25-4.68,13-4.68C30.23,10.16,36.67,14.84,36.67,14.84Z"
        transform="translate(1.83 0)"
        fill="none"
      />
      <circle cx="25.49" cy="26.61" r="7.85" fill="#fafafa" />
      <path
        d="M47.63,9.05V4.19C47.63,2.44,45.21,0,43.05,0H4.18C2,0,0,2.44,0,4.19V9.05Z"
        transform="translate(1.83 0)"
        fill="#e0e0e0"
      />
      <circle cx="7.71" cy="5.09" r="1.4" fill="#fff" />
      <circle cx="12.84" cy="5.09" r="1.4" fill="#fff" />
      <path
        d="M73.79,13H84.64a7,7,0,0,1,.15.9c0,.33.06.67.07,1a12.65,12.65,0,0,1-.65,4,9.32,9.32,0,0,1-2,3.37,10.15,10.15,0,0,1-3.56,2.44,12.14,12.14,0,0,1-4.73.87,11.81,11.81,0,0,1-4.55-.87,11.16,11.16,0,0,1-3.71-2.41,11.62,11.62,0,0,1-2.5-3.66A11.39,11.39,0,0,1,62.23,14a11.29,11.29,0,0,1,.93-4.62,11.67,11.67,0,0,1,2.5-3.67A11.16,11.16,0,0,1,69.37,3.3a12.67,12.67,0,0,1,9.36.06A11.25,11.25,0,0,1,82.32,6L79.44,8.81a7.13,7.13,0,0,0-5.6-2.35A7.87,7.87,0,0,0,71,7a7.18,7.18,0,0,0-4,3.93,7.67,7.67,0,0,0-.6,3.14,7.67,7.67,0,0,0,.6,3.14,6.94,6.94,0,0,0,1.64,2.4A7.22,7.22,0,0,0,71,21.14a8.52,8.52,0,0,0,5.89,0A7.36,7.36,0,0,0,79,19.86a5.38,5.38,0,0,0,1.06-1.41,8.49,8.49,0,0,0,.7-1.9h-7Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M93.36,9.49a8.44,8.44,0,0,1,3.22.6,7.51,7.51,0,0,1,4.21,4.23,8.41,8.41,0,0,1,.6,3.25,8.41,8.41,0,0,1-.6,3.25A7.49,7.49,0,0,1,96.58,25a9,9,0,0,1-6.45,0,7.44,7.44,0,0,1-4.21-4.22,8.41,8.41,0,0,1-.6-3.25,8.41,8.41,0,0,1,.6-3.25,7.51,7.51,0,0,1,4.21-4.23A8.69,8.69,0,0,1,93.36,9.49Zm0,12.4a4,4,0,0,0,1.49-.29A3.89,3.89,0,0,0,97,19.39a5.21,5.21,0,0,0,0-3.65,3.87,3.87,0,0,0-2.16-2.21,4,4,0,0,0-3,0,3.51,3.51,0,0,0-1.26.85,3.71,3.71,0,0,0-.88,1.36,5.21,5.21,0,0,0,0,3.65,3.71,3.71,0,0,0,.88,1.36,4,4,0,0,0,1.26.85A3.77,3.77,0,0,0,93.36,21.89Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M109.68,9.49a8.49,8.49,0,0,1,3.23.6,7.51,7.51,0,0,1,4.21,4.23,8.41,8.41,0,0,1,.6,3.25,8.6,8.6,0,0,1-.6,3.25A7.46,7.46,0,0,1,112.91,25a8.89,8.89,0,0,1-6.46,0,7.48,7.48,0,0,1-4.2-4.22,9,9,0,0,1,0-6.5,7.48,7.48,0,0,1,4.2-4.22A8.5,8.5,0,0,1,109.68,9.49Zm0,12.4a4.08,4.08,0,0,0,1.5-.29,3.87,3.87,0,0,0,2.16-2.21,5.21,5.21,0,0,0,0-3.65,3.87,3.87,0,0,0-2.16-2.21,3.83,3.83,0,0,0-1.5-.3,4,4,0,0,0-1.52.3,3.69,3.69,0,0,0-1.26.85,4.08,4.08,0,0,0-.88,1.36,5.21,5.21,0,0,0,0,3.65,3.91,3.91,0,0,0,2.14,2.21A3.77,3.77,0,0,0,109.68,21.89Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M125.82,32.31A8.68,8.68,0,0,1,123,31.9a8.1,8.1,0,0,1-2.18-1.09,8,8,0,0,1-1.55-1.47,6,6,0,0,1-.9-1.6l3.87-1.55a3.73,3.73,0,0,0,1.42,1.87,4.14,4.14,0,0,0,5.23-.58A4.82,4.82,0,0,0,130,24.05V23h-.24a4.91,4.91,0,0,1-1.87,1.57,6.17,6.17,0,0,1-2.74.58,6.73,6.73,0,0,1-2.65-.55,7,7,0,0,1-2.3-1.6,8.14,8.14,0,0,1-2.2-5.7,8.13,8.13,0,0,1,2.23-5.71A7.09,7.09,0,0,1,122.47,10a6.72,6.72,0,0,1,2.65-.54,6.12,6.12,0,0,1,2.73.57,5.11,5.11,0,0,1,1.87,1.55H130V10h3.87V24.06a9.4,9.4,0,0,1-.59,3.44,7.09,7.09,0,0,1-4.17,4.24A8.81,8.81,0,0,1,125.82,32.31ZM126,21.49a4.06,4.06,0,0,0,1.46-.27,3.71,3.71,0,0,0,1.29-.81,3.89,3.89,0,0,0,.9-1.3,4.18,4.18,0,0,0,.34-1.78,4.59,4.59,0,0,0-.34-1.79,3.9,3.9,0,0,0-.91-1.32,3.82,3.82,0,0,0-1.28-.81,4.08,4.08,0,0,0-2.92,0,3.92,3.92,0,0,0-1.26.82,4.42,4.42,0,0,0-.9,1.32,4.76,4.76,0,0,0,0,3.56,4,4,0,0,0,.9,1.3,3.73,3.73,0,0,0,1.26.81A4.06,4.06,0,0,0,126,21.49Z"
        transform="translate(1.83 0)"
      />
      <path d="M135.81,25.14V3h4.05V25.14Z" transform="translate(1.83 0)" />
      <path
        d="M155.6,22a7.81,7.81,0,0,1-3.52,2.8,8.29,8.29,0,0,1-3.17.73,7.58,7.58,0,0,1-5.58-2.17,8.68,8.68,0,0,1-2.58-5.79,7.92,7.92,0,0,1,2-5.61,7.61,7.61,0,0,1,2.65-1.87,8.12,8.12,0,0,1,3.21-.76,6.67,6.67,0,0,1,2.85.57,7.25,7.25,0,0,1,2.35,1.72,9.78,9.78,0,0,1,1.72,2.69l.15.34a1.17,1.17,0,0,0,.11.33,1.73,1.73,0,0,0,.11.32l-10.44,4.59a4.16,4.16,0,0,0,1.1,1.26,3.88,3.88,0,0,0,1.33.63,3.72,3.72,0,0,0,1.41.09,4.85,4.85,0,0,0,1.35-.38,4,4,0,0,0,1.86-1.5m-1.3-5.77a2.63,2.63,0,0,0-.61-.72,3.1,3.1,0,0,0-1-.57,3.64,3.64,0,0,0-1.31-.21,4.27,4.27,0,0,0-1.57.39,3.67,3.67,0,0,0-1.79,1.6,4,4,0,0,0-.45,2.46Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M76.2,51.37a5.36,5.36,0,0,1-2,4.34,7.62,7.62,0,0,1-4.88,1.6,7.25,7.25,0,0,1-4.5-1.49,7.73,7.73,0,0,1-2.68-4.08l2.53-1a5.87,5.87,0,0,0,.73,1.66,5.29,5.29,0,0,0,1.08,1.28,4.73,4.73,0,0,0,1.36.83,4.27,4.27,0,0,0,1.58.3,4.51,4.51,0,0,0,3-.93,3.1,3.1,0,0,0,.15-4.69,8.93,8.93,0,0,0-3.32-1.72c-1.65-.59-2.67-1-3.08-1.2a5.26,5.26,0,0,1-3.26-4.91,5,5,0,0,1,1.8-3.87,6.59,6.59,0,0,1,4.51-1.6,7,7,0,0,1,4.07,1.2A5.67,5.67,0,0,1,75.59,40l-2.47,1a3.65,3.65,0,0,0-1.36-1.91,4.4,4.4,0,0,0-5.09.1,2.47,2.47,0,0,0-1.08,2.09,2.36,2.36,0,0,0,.83,1.84,14.36,14.36,0,0,0,4,1.83,10.13,10.13,0,0,1,4.46,2.6A5.6,5.6,0,0,1,76.2,51.37Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M82.88,42.34A6.6,6.6,0,0,1,87.5,43.9a5.6,5.6,0,0,1,1.69,4.29v8.66H86.66v-2h-.11a5,5,0,0,1-4.36,2.4,5.66,5.66,0,0,1-3.89-1.37,4.35,4.35,0,0,1-1.56-3.44A4.14,4.14,0,0,1,78.39,49a6.93,6.93,0,0,1,4.4-1.29,7.76,7.76,0,0,1,3.87.86V48a3,3,0,0,0-1.08-2.33,3.79,3.79,0,0,0-2.56-1,4,4,0,0,0-3.49,1.86L77.2,45.09A6.48,6.48,0,0,1,82.88,42.34ZM79.47,52.55a2.07,2.07,0,0,0,.87,1.72,3.29,3.29,0,0,0,2,.68,4.17,4.17,0,0,0,3-1.23,3.85,3.85,0,0,0,1.3-2.89,5.41,5.41,0,0,0-3.44-1,4.5,4.5,0,0,0-2.68.78A2.21,2.21,0,0,0,79.47,52.55Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M97,36.09a5,5,0,0,1,2.35.48l-.73,2.32a3.44,3.44,0,0,0-1.55-.34,2,2,0,0,0-1.59.68A2.6,2.6,0,0,0,94.85,41v1.8h3.67v2.4H94.86V56.85H92.23V45.2H89.59V42.8h2.64v-2a4.42,4.42,0,0,1,1.33-3.38A4.64,4.64,0,0,1,97,36.09Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M105.12,57.3a6.69,6.69,0,0,1-5.1-2.12,7.48,7.48,0,0,1-2-5.36,7.71,7.71,0,0,1,2-5.35,7.09,7.09,0,0,1,10-.13A8.05,8.05,0,0,1,111.77,50v.29h-11a4.59,4.59,0,0,0,1.38,3.32,4.44,4.44,0,0,0,6.27,0,4.24,4.24,0,0,0,.84-1.21l2.35,1.15A7,7,0,0,1,109,56.28,7.25,7.25,0,0,1,105.12,57.3Zm-4.22-9.17H109a3.44,3.44,0,0,0-1.19-2.42,4.21,4.21,0,0,0-2.88-1,3.85,3.85,0,0,0-2.57.92,4.44,4.44,0,0,0-1.42,2.46Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M118.2,56.85V36.32h7.42a6,6,0,0,1,4.19,1.6,5.14,5.14,0,0,1,1.75,4,4.32,4.32,0,0,1-2.78,4.27v.12a5,5,0,0,1,2.53,1.79,4.82,4.82,0,0,1,.94,2.91,5.43,5.43,0,0,1-1.84,4.18A6.11,6.11,0,0,1,126,56.85Zm2.63-18v6.27h4.79a3.14,3.14,0,0,0,2.35-.94,3,3,0,0,0,.11-4.31h0l-.07-.07a3,3,0,0,0-2.26-.94Zm0,8.74v6.74h5.31A3.37,3.37,0,0,0,129.55,51h0a3.17,3.17,0,0,0-1-2.35,3.41,3.41,0,0,0-2.53-1Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M136,56.85h-2.6V42.8h2.53v2.29H136a3.76,3.76,0,0,1,1.65-1.91,4.68,4.68,0,0,1,2.45-.79,5.09,5.09,0,0,1,2,.35l-.81,2.55a4.3,4.3,0,0,0-1.55-.2,3.4,3.4,0,0,0-2.59,1.2A4,4,0,0,0,136,49.1Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M140.76,49.82a7.18,7.18,0,0,1,12.35-5.36,7.32,7.32,0,0,1,2.06,5.36,7.28,7.28,0,0,1-2.06,5.36,7.18,7.18,0,0,1-12.35-5.36Zm2.64,0a5.15,5.15,0,0,0,1.32,3.67,4.49,4.49,0,0,0,6.35.19h0l.17-.17a5.72,5.72,0,0,0,0-7.31,4.44,4.44,0,0,0-6.27-.27h0l-.26.26A5.13,5.13,0,0,0,143.4,49.82Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M175.86,42.8l-4.53,14.05h-2.7l-3.5-10.78-3.47,10.78H159L154.47,42.8h2.75l3.13,10.61h0l3.47-10.61h2.73L170,53.41h0l3.09-10.61Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M187,52.94a3.86,3.86,0,0,1-1.61,3.1,6.32,6.32,0,0,1-4,1.26,6.42,6.42,0,0,1-3.73-1.1,6.08,6.08,0,0,1-2.29-2.91l2.35-1a4.45,4.45,0,0,0,1.5,2,3.67,3.67,0,0,0,2.17.7,3.83,3.83,0,0,0,2.11-.54,1.54,1.54,0,0,0,.84-1.29c0-.9-.69-1.56-2.06-2l-2.41-.6q-4.1-1-4.1-4a3.66,3.66,0,0,1,1.56-3.08,6.56,6.56,0,0,1,4-1.16,6.44,6.44,0,0,1,3.37.89,4.8,4.8,0,0,1,2.11,2.38l-2.35,1a3,3,0,0,0-1.3-1.39,4.09,4.09,0,0,0-2-.5,3.46,3.46,0,0,0-1.85.51,1.49,1.49,0,0,0-.81,1.27c0,.8.75,1.38,2.26,1.72l2.12.54Q187,49.78,187,52.94Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M191.15,37.94a1.86,1.86,0,1,1-1.86-1.86h0a1.89,1.89,0,0,1,1.31.54A1.86,1.86,0,0,1,191.15,37.94Zm-.54,4.85V56.84H188v-14Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M192.66,42.8h2.53v2h.12a4.52,4.52,0,0,1,1.85-1.8,5.74,5.74,0,0,1,6.57.78,5.9,5.9,0,0,1,1.39,4.2v8.84h-2.64V48.19q-.09-3.44-3.47-3.44A3.33,3.33,0,0,0,196.37,46a4.62,4.62,0,0,0-1.06,3.06v7.76h-2.64Z"
        transform="translate(1.83 0)"
      />
      <path
        d="M212.91,63.5a8.24,8.24,0,0,1-2.21-.3,7.24,7.24,0,0,1-1.88-.85,5.72,5.72,0,0,1-1.44-1.29,5,5,0,0,1-.92-1.66l2.49-1a4,4,0,0,0,1.49,2,4.12,4.12,0,0,0,2.47.75,4.37,4.37,0,0,0,3.35-1.29,5,5,0,0,0,1.21-3.56V54.9h-.12a5.15,5.15,0,0,1-2,1.76,6,6,0,0,1-2.76.64,6.17,6.17,0,0,1-4.73-2.15,7.76,7.76,0,0,1-2-5.33,7.64,7.64,0,0,1,2-5.3,6.16,6.16,0,0,1,4.73-2.18,6,6,0,0,1,2.76.64,5.25,5.25,0,0,1,2,1.77h.11v-2H220V56.24a7.17,7.17,0,0,1-1.92,5.31A7,7,0,0,1,212.91,63.5Zm.08-8.6a4.1,4.1,0,0,0,3.19-1.41,5.82,5.82,0,0,0,0-7.31,4.26,4.26,0,0,0-6-.36c-.12.12-.24.23-.35.36a5.22,5.22,0,0,0-1.29,3.64,5.12,5.12,0,0,0,1.29,3.64A4.14,4.14,0,0,0,213,54.9Z"
        transform="translate(1.83 0)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GoogleSafeBrowsingIcon',
};
</script>