<template>
  <div class="box">
    <h2>Pedido efetuado</h2>
    <p>
      Realize o pagamento do boleto no valor de
      <strong>{{ cartTotal.total_vista | currency }}</strong> até
      {{ paymentDeadline }}.<br />
      <span v-html="deliveryDate"></span>
    </p>
    <p>Agradecemos a preferência</p>
    <div class="button-container">
      <MainButton
        buttonType="link"
        :buttonLink="processedPaymentData.boleto_link"
      >
        <BoletoIcon />
        <span>Imprimir boleto</span>
      </MainButton>
      <a :href="$baseURL + '/minha-conta'" class="link">Painel do usuário</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ebitFunctions from '@/mixins/ebitFunctions.js';
import MainButton from '@/components/input/MainButton.vue';
import BoletoIcon from '@/components/icons/finish/BoletoIcon.vue';

export default {
  name: 'boleto',
  mixins: [ebitFunctions],
  components: {
    MainButton,
    BoletoIcon,
  },
  computed: {
    ...mapGetters([
      'processedPaymentData',
      'selectedFreight',
      'cartTotal',
      'storeConfig',
    ]),
    paymentDeadline() {
      const date = new Date(this.processedPaymentData.boleto_vencimento);

      return date.toLocaleDateString('pt-BR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      });
    },
    deliveryDate() {
      if (this.selectedFreight.mensagem)
        return this.selectedFreight.mensagem + '.';

      const deadline =
        this.selectedFreight.entrega > 1
          ? `${this.selectedFreight.entrega} dias úteis`
          : `${this.selectedFreight.entrega} dia útil`;

      if (this.selectedFreight.alias == 'retirar') {
        return `Você poderá retirar sua encomenda em <strong>${deadline}</strong> após confirmarmos o pagamento.`;
      } else if (this.selectedFreight.alias == 'fob') {
        return `Caso o frete ainda não tenha sido combinado, entre em contato pelo número 
        <a href=https://wa.me/+${this.storeConfig.contact.whatsapp.raw} target=_blank>
          ${this.storeConfig.contact.whatsapp.pretty}
        </a>`;
      }

      return `Sua compra chegará em <strong>até ${deadline}</strong> após o pagamento ser aprovado.`;
    },
  },
  mounted() {
    this.ebitInit();
  },
};
</script>

<style>
</style>