<template>
  <InputContainer :textLabel="textLabel" :hasError="hasError && required">
    <input
      type="text"
      :id="inputId"
      :name="inputName"
      :autocomplete="inputAutocomplete"
      :disabled="isDisabled"
      :inputmode="inputMode"
      :placeholder="placeholderText"
      :readonly="readonly ? true : null"
      v-model="localInputValue"
      v-mask="inputMask"
      @blur="handleBlur()"
      @focus="
        handleFocus();
        hideCheckout();
      "
      @keyup="
        handleKeyup();
        showCheckout();
      "
    />
  </InputContainer>
</template>

<script>
import validateText from '@/helpers/validateText.js';
import InputContainer from '@/components/input/InputContainer.vue';
import toggleCheckout from '@/mixins/toggleCheckout.js';

export default {
  name: 'TextInput',
  mixins: [toggleCheckout],
  data() {
    return {
      hasError: false,
      localInputValue: this.inputValue,
    };
  },
  components: {
    InputContainer,
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    inputValue: {
      type: [String, Number],
      require: true,
    },
    inputName: {
      type: String,
      default: null,
    },
    inputId: {
      type: String,
      default: null,
    },
    inputAutocomplete: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
    validateField: Boolean,
    regexString: String,
    inputMode: String,
    placeholderText: String,
    isDisabled: Boolean,
    inputMask: [String, Array],
  },
  watch: {
    inputValue(value) {
      this.localInputValue = value;
    },
    validateField(value) {
      if (value) {
        const status = this.validate();

        this.hasError = !status;

        this.$emit('validateField', status);
        return status;
      }
    },
  },
  methods: {
    handleFocus() {
      if (this.readonly) return false;

      this.hasError = false;
    },
    handleBlur() {
      if (this.readonly) return false;

      this.$emit('update:inputValue', this.localInputValue);

      const response = this.validate(this.localInputValue);

      this.hasError = !response;

      this.$emit('validateField', this.validate());
    },
    validate() {
      let status = true;

      if (this.required) {
        if (!this.regexString) {
          console.warn(
            'Um RegExp é necessário para fazer a validação de texto'
          );
          return false;
        }

        status = validateText(this.localInputValue, this.regexString);
      }

      return status;
    },
    handleKeyup() {
      if (this.readonly) return false;

      this.$emit('update:inputValue', this.localInputValue);
    },
  },
};
</script>

<style>
</style>