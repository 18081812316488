<template>
  <div class="box">
    <transition mode="out-in">
      <template v-if="isPaymentDone">
        <div class="approved" key="approved">
          <h2>Pagamento aprovado</h2>
          <p>
            <span v-html="deliveryDate"></span>
            <br />
            Agradecemos a preferência.
          </p>
          <MainButton
            buttonType="link"
            buttonTarget="_self"
            :buttonLink="$baseURL + '/minha-conta'"
          >
            <span>Painel do usuário</span>
          </MainButton>
        </div>
      </template>
      <template v-else>
        <transition mode="out-in">
          <template v-if="!isCodeValid">
            <div class="expired" key="expired">
              <h2>Código expirado</h2>
              <p>
                O código Pix expirou e seu carrinho foi desfeito.<br />
                Caso o pagamento não tenha sido efetuado dentro do prazo, será
                necessário adicionar novamente os itens ao carrinho e fazer um
                novo pedido.
              </p>
              <MainButton
                target="_self"
                buttonType="link"
                class="main-button"
                :buttonLink="$baseURL"
              >
                <span>Adicionar produtos</span>
              </MainButton>
            </div>
          </template>
          <template v-if="isSaleCancelled">
            <div class="cancelled" key="cancelled">
              <h2>Compra cancelada</h2>
              <p>
                Sua compra foi cancelada. Para saber mais, entre em contato
                conosco através do botão abaixo.
              </p>
              <MainButton
                target="_self"
                buttonType="link"
                class="main-button"
                :buttonLink="$baseURL + '/central-de-atendimento'"
              >
                <span>Fale conosco</span>
              </MainButton>
            </div>
          </template>
          <template v-else>
            <div class="pending" key="pending">
              <h2>Pedido efetuado</h2>
              <div class="payment-data-container">
                <div>
                  <img
                    :src="processedPaymentData.qr"
                    alt
                    width="400"
                    height="400"
                  />
                  <p class="code">{{ this.processedPaymentData.codigo }}</p>
                  <button
                    @click="copyToClipboard"
                    :disabled="copied"
                    class="copy-button"
                  >
                    <transition mode="out-in">
                      <span v-if="!copied" key="false">Copiar código</span>
                      <span v-else key="true">Copiado!</span>
                    </transition>
                  </button>
                </div>
                <p class="expires">Código válido até {{ expiresAt }}</p>
              </div>
              <div class="info">
                <p>
                  Para concluir sua compra no valor de
                  <strong>{{ cartTotal.total_vista | currency }}</strong
                  >, é necessário que você faça o pagamento utilizando o código
                  acima. Lembre-se que:
                </p>
                <ul>
                  <li>
                    O código tem validade de <strong>{{ expiresIn }}</strong
                    >.
                  </li>
                  <li>
                    Caso o pagamento não seja feito dentro do prazo, será
                    necessário gerar outra compra no site.
                  </li>
                  <li>
                    Você pode visualizar as informações acima através do e-mail
                    que enviamos ou acessando suas compras em nosso painel.
                  </li>
                </ul>
              </div>
              <MainButton
                buttonType="link"
                :buttonLink="$baseURL + '/minha-conta'"
                buttonTarget="_self"
              >
                <span>Painel do usuário</span>
              </MainButton>
            </div>
          </template>
        </transition>
      </template>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ebitFunctions from '@/mixins/ebitFunctions.js';
import moment from 'moment';
import MainButton from '@/components/input/MainButton.vue';
import { api } from '../../services.js';

export default {
  name: 'pix',
  mixins: [ebitFunctions],
  data() {
    return {
      copied: false,
      clipboardTimeout: 0,
      validateTimeout: 0,
      isPaymentDone: false,
      isCodeValid: true,
      isSaleCancelled: false,
    };
  },
  components: {
    MainButton,
  },
  computed: {
    ...mapGetters([
      'processedPaymentData',
      'selectedFreight',
      'cartTotal',
      'cartId',
      'storeConfig',
    ]),
    expiresAt() {
      moment.locale('pt-br');
      const expirationDate = moment(
        this.processedPaymentData.data_expiracao
      ).format('MM/DD/YYYY HH:mm:ss');

      let date = new Date(expirationDate);

      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };

      return date.toLocaleTimeString('pt-BR', options);
    },
    expiresIn() {
      const time = this.processedPaymentData.duracao;

      if (isNaN(time)) return null;

      if (time >= 120) {
        const hours = (time / 60).toFixed(0);
        const minutes = time % 60;

        if (minutes === 0) {
          return `${hours} horas`;
        }

        if (minutes === 1) {
          return `${hours} horas e 1 minuto`;
        }

        return `${hours} horas e ${minutes} minutos`;
      }

      return time > 1 ? `${time} minutos` : `${time} minuto`;
    },
    deliveryDate() {
      if (this.selectedFreight.mensagem)
        return this.selectedFreight.mensagem + '.';

      let date = new Date();
      date.setDate(date.getDate() + +this.selectedFreight.entrega + 1);

      const formattedDate = date.toLocaleDateString('pt-BR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      });

      const deadline =
        this.selectedFreight.entrega > 1
          ? `${this.selectedFreight.entrega} dias úteis`
          : `${this.selectedFreight.entrega} dia útil`;

      if (this.selectedFreight.alias == 'retirar') {
        return `Você poderá retirar sua encomenda em <strong>${deadline}</strong> após confirmarmos o pagamento.`;
      } else if (this.selectedFreight.alias == 'fob') {
        return `Caso o frete ainda não tenha sido combinado, entre em contato pelo número 
        <a href=https://wa.me/+${this.storeConfig.contact.whatsapp.raw} target=_blank>
          ${this.storeConfig.contact.whatsapp.pretty}
        </a>`;
      }

      return `Sua encomenda chegará <strong>até ${formattedDate}</strong>.`;
    },
  },
  watch: {
    isPaymentDone() {
      this.ebitInit();
    },
  },
  methods: {
    copyToClipboard() {
      clearTimeout(this.clipboardTimeout);

      if (!this.copied) {
        this.$copyText(this.processedPaymentData.codigo).then(() => {
          this.clipboardTimeout = setTimeout(() => {
            this.copied = !this.copied;
          }, 4000);
        });
      }

      this.copied = !this.copied;
    },
    checkCodeAvailability() {
      const now = moment();
      const expirationDate = moment(this.processedPaymentData.data_expiracao);

      clearTimeout(this.validateTimeout);

      this.isCodeValid = now.isBefore(expirationDate);

      if (this.isCodeValid) {
        this.validateTimeout = setTimeout(() => {
          this.checkCodeAvailability();
        }, 1000);
      }
    },
    async queryPayment() {
      if (this.isPaymentDone || this.isSaleCancelled) return;

      const response = await api.get(
        `/checkout/verifica_pagamento/${this.cartId}`
      );

      if (!response.data.success) return false;

      if (response.data.payment_status == 'canceled') {
        this.isSaleCancelled = true;

        return false;
      }

      if (response.data.payment_status == 'approved') {
        this.isPaymentDone = true;

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'conversion',
            value: this.cartTotal.total_vista
              ? +this.cartTotal.total_vista.toFixed(2)
              : 1.0,
            currency: 'BRL',
            transaction_id: this.cartId,
          });
        }

        return true;
      }

      setTimeout(() => {
        this.queryPayment();
      }, 5000);
    },
  },
  created() {
    this.checkCodeAvailability();
    this.queryPayment();
  },
};
</script>

<style lang="scss" scoped>
.pending,
.approved,
.expired,
.cancelled {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem;

  p.code {
    width: 100%;
    word-break: break-all;
    word-wrap: break-word;
    line-height: 1.5;
    font-style: italic;
    opacity: 0.5;
  }

  .copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.75rem;
    gap: 0.75rem;
    background-color: var(--main-button-background);
    color: var(--main-button-color);
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 0.3125rem;
    font-weight: 500;
    box-shadow: 0 0 0 0 var(--main-button-hover);
    transition: 150ms ease-out;
    transition-property: background-color, box-shadow;
    font-size: 0.875rem;
    cursor: pointer;

    span {
      color: inherit;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.175rem var(--main-button-hover);
    }

    &[disabled] {
      cursor: auto;
      background-color: var(--copied-button-background);
      color: var(--copied-button-color);
      box-shadow: none;
    }
  }

  .info {
    padding: 1rem 0;

    p {
      margin-bottom: 1.5rem;
      text-align: left !important;
    }
  }
}

.expired,
.cancelled {
  p {
    max-width: 30rem;
  }
}
</style>