<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
  >
    <path
      d="M1.889,0H22.111A1.888,1.888,0,0,1,24,1.878V14.122A1.888,1.888,0,0,1,22.111,16H1.889A1.888,1.888,0,0,1,0,14.122V1.878A1.888,1.888,0,0,1,1.889,0ZM15.723,7.383H20.65a.58.58,0,0,1,.582.579v3.111a.58.58,0,0,1-.582.579H15.723a.58.58,0,0,1-.582-.579V7.961a.58.58,0,0,1,.582-.579Zm4.345,1.157H16.305v1.955h3.763V8.539ZM1.164,3.755H22.836V1.878a.727.727,0,0,0-.725-.721H1.889a.727.727,0,0,0-.726.721V3.755Zm21.672,1.4H1.164v8.967a.727.727,0,0,0,.726.721H22.111a.727.727,0,0,0,.725-.721V5.155Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CreditoIcon',
};
</script>