import { mapMutations } from 'vuex'

const toggleCheckout = {
  methods: {
    ...mapMutations({
      updateNavigation: 'UPDATE_NAVIGATION'
    }),
    hideCheckout() {
      this.updateNavigation({
        hideMobileCheckout: true
      })
    },
    showCheckout() {
      this.updateNavigation({
        hideMobileCheckout: false
      })
    }
  }
}

export default toggleCheckout