export default {
  "list": [
    {
      valor: 'AC',
      texto: 'Acre'
    },
    {
      valor: 'AL',
      texto: 'Alagoas'
    },
    {
      valor: 'AP',
      texto: 'Amapá'
    },
    {
      valor: 'AM',
      texto: 'Amazonas'
    },
    {
      valor: 'BA',
      texto: 'Bahia'
    },
    {
      valor: 'CE',
      texto: 'Ceará'
    },
    {
      valor: 'DF',
      texto: 'Distrito Federal'
    },
    {
      valor: 'ES',
      texto: 'Espírito Santo'
    },
    {
      valor: 'GO',
      texto: 'Goiás'
    },
    {
      valor: 'MA',
      texto: 'Maranhão'
    },
    {
      valor: 'MT',
      texto: 'Mato Grosso'
    },
    {
      valor: 'MS',
      texto: 'Mato Grosso do Sul'
    },
    {
      valor: 'MG',
      texto: 'Minas Gerais'
    },
    {
      valor: 'PA',
      texto: 'Pará'
    },
    {
      valor: 'PB',
      texto: 'Paraíba'
    },
    {
      valor: 'PR',
      texto: 'Paraná'
    },
    {
      valor: 'PE',
      texto: 'Pernambuco'
    },
    {
      valor: 'PI',
      texto: 'Piauí'
    },
    {
      valor: 'RJ',
      texto: 'Rio de Janeiro'
    },
    {
      valor: 'RN',
      texto: 'Rio Grande do Norte'
    },
    {
      valor: 'RS',
      texto: 'Rio Grande do Sul'
    },
    {
      valor: 'RO',
      texto: 'Rondônia'
    },
    {
      valor: 'RR',
      texto: 'Roraima'
    },
    {
      valor: 'SC',
      texto: 'Santa Catarina'
    },
    {
      valor: 'SP',
      texto: 'São Paulo'
    },
    {
      valor: 'SE',
      texto: 'Sergipe'
    },
    {
      valor: 'TO',
      texto: 'Tocantins'
    },
  ],
}
