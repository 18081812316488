<template>
  <div>
    <p :style="{ fontSize: fontSize }">Vendedor</p>
    <select
      name="seller"
      v-model="seller"
      :style="{ fontSize: fontSize }"
      :class="{ alert: purchaseSeller.alert }"
    >
      <option disabled value="">Selecione</option>
      <option value="null">Nenhum</option>
      <option
        v-for="seller in purchaseSeller.list"
        :value="seller.id"
        :key="seller.id"
      >
        {{ seller.nome }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SellerSelector',
  data() {
    return {
      seller: '',
    };
  },
  props: {
    fontSize: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['purchaseSeller']),
  },
  watch: {
    seller(value) {
      this.updateSeller({
        id: value,
        alert: false,
      });
    },
    'purchaseSeller.id': function (value) {
      this.seller = value;
    },
  },
  methods: {
    ...mapMutations({
      updateSeller: 'UPDATE_SELLER',
    }),
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  p {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--dark-text-color);
  }

  select {
    width: max-content;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    transition: box-shadow 150ms ease-out;
    text-transform: capitalize;
    color: var(--normal-text-color);

    option {
      color: inherit;
    }

    &.alert {
      box-shadow: 0 0 0 0.06125rem var(--alert-color);
    }
  }
}
</style>