<template>
  <InputContainer :textLabel="textLabel" :hasError="hasError && required">
    <div class="input-container">
      <input
        min="1"
        ref="input"
        type="number"
        v-show="hasNumber"
        key="numberInput"
        inputmode="numeric"
        v-model="localInputValue"
        :readonly="readonly ? true : null"
        :disabled="isDisabled"
        :placeholder="placeholderText"
        @focus="
          handleFocus();
          hideCheckout();
        "
        @blur="
          handleFieldBlur();
          showCheckout();
        "
      />
      <input
        v-show="!hasNumber"
        readonly
        type="text"
        tabindex="-1"
        key="noNumber"
        :value="'Sem número'"
        :disabled="isDisabled"
      />
      <button
        v-if="!isDisabled"
        :tabindex="!hasNumber || localInputValue"
        :disabled="readonly"
        @click="handleClick()"
        @focus="handleButtonFocus($event)"
        @blur="handleButtonBlur($event)"
      >
        <label class="checkbox-container">
          <input type="checkbox" tabindex="-1" :checked="!hasNumber" />
          <p>Sem número</p>
        </label>
      </button>
    </div>
  </InputContainer>
</template>

<script>
import validateInteger from '@/helpers/validateInteger.js';
import InputContainer from '@/components/input/InputContainer.vue';
import toggleCheckout from '@/mixins/toggleCheckout.js';

export default {
  name: 'AddressNumberInput',
  mixins: [toggleCheckout],
  data() {
    return {
      hasError: false,
      localInputValue: this.inputValue,
      hasNumber: true,
    };
  },
  components: {
    InputContainer,
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    inputValue: {
      type: [String, Number],
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
    validateField: Boolean,
    placeholderText: String,
    isDisabled: Boolean,
  },
  watch: {
    inputValue(value) {
      this.localInputValue = value;

      if (value === '0' || value === 0) {
        this.hasNumber = false;
      } else {
        this.hasNumber = true;
      }
    },
    validateField(value) {
      if (value) {
        const status = this.validate();

        this.hasError = !status;

        this.$emit('validateField', status);
        return status;
      }
    },
  },
  methods: {
    handleFocus() {
      this.hasError = false;
    },
    handleFieldBlur() {
      if (this.localInputValue && this.localInputValue.trim() == '0') {
        this.hasNumber = false;
      }

      this.$emit('update:inputValue', this.localInputValue);

      this.$emit('validateField', this.validate());
    },
    handleButtonBlur(el) {
      el.target.style.zIndex = '0';
      this.validate();
      this.$emit('validateField', this.validate());
    },
    handleButtonFocus(el) {
      el.target.style.zIndex = '1';
    },
    handleClick() {
      if (this.hasNumber) {
        this.hasError = false;
        this.hasNumber = false;
        this.$emit('update:inputValue', '0');
        return;
      }

      this.hasNumber = true;
      this.localInputValue = null;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);
    },
    validate() {
      if (!this.required) {
        this.hasError = false;
        return true;
      }

      if (!this.hasNumber) {
        this.hasError = false;
        return true;
      }

      if (this.localInputValue === '' || this.localInputValue === null) {
        this.hasError = true;
        return false;
      }

      if (!validateInteger(this.localInputValue).isValid) {
        this.hasError = true;
        return false;
      }

      this.hasError = false;
      return true;
    },
  },
  created() {
    if (this.inputValue == '0') {
      this.hasNumber = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;

  input {
    border-radius: 0.25rem 0 0 0.25rem;
    z-index: 1;
    border: 1px solid var(--input-border-color);
    box-shadow: 0 0 0 0 var(--input-border-color-hover);

    &:focus {
      box-shadow: 0 0 0 0.125rem var(--input-border-color-hover);
    }

    &:read-only {
      &:focus {
        box-shadow: none;
      }
    }
  }

  button {
    background-color: var(--input-button-background);
    border: 1px solid var(--input-border-color);
    border-left: none;
    border-radius: 0 0.25rem 0.25rem 0;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: filter 150ms ease-out;
    outline: none;

    .checkbox-container {
      pointer-events: none;

      p {
        font-size: 0.75rem;
        white-space: nowrap;
        transform: translateY(1px);
        color: var(--input-button-color);
      }

      input[type='checkbox'] {
        appearance: auto;
        box-shadow: none;
        flex-shrink: 0;
        width: unset;
      }
    }

    &:focus {
      box-shadow: 0 0 0 0.125rem var(--input-border-color-hover);
    }

    &:active {
      filter: brightness(0.9);
    }
  }

  .checkbox-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    gap: 0.375rem;
  }
}
</style>