<template>
  <header class="section">
    <transition name="opacity">
      <div class="container" v-if="storeConfig">
        <a :href="$baseURL" class="logo-container">
          <img :src="storeConfig.logos.logo" :alt="storeConfig.name" />
        </a>
        <a :href="storeConfig.url">Voltar para a loja</a>
      </div>
    </transition>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MainHeader',
  computed: {
    ...mapGetters(['storeConfig']),
  },
};
</script>

<style scoped lang="scss">
header {
  position: relative;
  height: 6.25rem;
  padding: 1rem 0;
  z-index: 1;
  box-shadow: var(--header-box-shadow);
  background-color: var(--header-background);

  .container {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .logo-container {
      display: flex;
      height: 100%;
      max-width: 10rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    a:not(.logo-container) {
      color: var(--header-link);
      opacity: 0.75;
      font-size: 0.875rem;
      font-weight: 600;
      transition: opacity 150ms ease-out;

      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>