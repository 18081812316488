<template>
  <InputContainer :textLabel="textLabel" :hasError="hasError && required">
    <div>
      <select
        v-model="localInputValue"
        :class="{
          readonly: readonly,
          disabled: isDisabled && !inputValue,
        }"
        :disabled="isDisabled"
        :tabindex="readonly ? '-1' : '0'"
        @change="handleChange()"
        @focus="
          handleFocus();
          hideCheckout();
        "
        @blur="
          handleBlur();
          showCheckout();
        "
      >
        <option disabled value="" v-if="placeholderText">
          {{ placeholderText }}
        </option>
        <option
          v-for="item in selectItems"
          :key="item.valor"
          :value="item.valor"
          :disabled="item.isDisabled"
          :title="item.isDisabled ? 'Indisponível' : null"
        >
          {{ item.texto }}
        </option>
      </select>
      <span class="arrow" v-if="!isDisabled">
        <SelectArrow />
      </span>
    </div>
  </InputContainer>
</template>

<script>
import InputContainer from '@/components/input/InputContainer.vue';
import toggleCheckout from '@/mixins/toggleCheckout.js';
import SelectArrow from '@/components/icons/input/SelectArrow.vue';

export default {
  name: 'SelectInput',
  mixins: [toggleCheckout],
  data() {
    return {
      hasError: false,
      localInputValue: this.inputValue,
    };
  },
  components: {
    InputContainer,
    SelectArrow,
  },
  props: {
    textLabel: {
      type: String,
      require: false,
    },
    inputValue: {
      type: [Number, String],
      require: false,
    },
    selectItems: {
      type: Array,
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: null,
    },
    readonly: Boolean,
    validateField: Boolean,
    isDisabled: Boolean,
  },
  watch: {
    inputValue(value) {
      if (value === undefined || value === null) {
        this.localInputValue = '';
        return;
      }

      this.localInputValue = value;
    },
    localInputValue() {
      this.hasError = false;
    },
    validateField(value) {
      if (value) {
        const status = this.validate();

        this.hasError = !status;

        this.$emit('validateField', status);
        return status;
      }
    },
  },
  methods: {
    handleFocus() {
      if (this.readonly) return false;

      this.hasError = false;
    },
    handleBlur() {
      if (this.readonly) return false;

      this.hasError = !this.validate();
      this.$emit('validateField', this.validate());
    },
    handleChange() {
      if (this.readonly) return false;

      this.$emit('update:inputValue', this.localInputValue);
    },
    validate() {
      if (this.required) {
        let status;

        if (this.localInputValue !== '') {
          status = true;
        } else {
          status = false;
        }

        this.$emit('validateField', status);
        return status;
      }
    },
  },
  created() {
    if (this.inputValue === undefined || this.inputValue === null) {
      this.localInputValue = '';
    }
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;

  select {
    padding-right: 1.75rem;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f6;
    }

    &::-webkit-scrollbar-thumb {
      background: #e2e2e6;
      border-radius: 7px;

      &:hover {
        background: #c4c4ca;
      }
    }

    &.disabled {
      color: var(--placeholder-color) !important;
    }

    option:disabled {
      color: var(--placeholder-color);
    }
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.5rem;
    background: var(--select-arrow-background);
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    ::v-deep svg {
      width: 50%;
      height: auto;
      fill: var(--select-arrow-fill);
    }
  }
}
</style>