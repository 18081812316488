<template>
  <div class="unavailable-method">
    <p class="main">Método indisponível</p>
    <p class="text">
      Para utilizar este método de pagamento, o valor mínimo da sua compra deve
      ser de <strong>{{ minValue | currency }}</strong
      >.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'UnavailableMethod',
  computed: {
    ...mapGetters(['selectedPayment']),
    minValue() {
      return this.selectedPayment.valor_minimo_compra;
    },
  },
  methods: {
    ...mapMutations({
      validatePayment: 'VALIDATE_PAYMENT',
    }),
  },
  activated() {
    this.validatePayment({
      isValid: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.unavailable-method {
  height: 100%;
  padding: 1.5rem;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  .main {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 0;
    text-align: center;
  }

  .text {
    text-align: center;
    margin: 0;
    max-width: 25rem;
  }
}
</style>