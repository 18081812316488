<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.28 38.7">
    <polygon
      points="51.71 38.05 41.98 38.05 48.06 0.67 57.79 0.67 51.71 38.05"
      fill="#005ca9"
    />
    <path
      d="M33.79.67,24.51,26.38l-1.1-5.54h0L20.14,4S19.74.67,15.52.67H.18L0,1.3A36.47,36.47,0,0,1,10.18,5.58l8.46,32.47H28.78L44.26.67Z"
      fill="#005ca9"
    />
    <path
      d="M110.34,38.05h8.94L111.49.67h-7.83a4.47,4.47,0,0,0-4.49,2.79L84.65,38.05H94.8l2-5.55H109.2ZM99.63,24.83l5.12-14,2.87,14Z"
      fill="#005ca9"
    />
    <path
      d="M85.41,9.66l1.39-8A28,28,0,0,0,78.05,0c-4.83,0-16.3,2.11-16.3,12.38,0,9.66,13.46,9.78,13.46,14.85s-12.07,4.16-16.06,1L57.7,36.58a27.21,27.21,0,0,0,11,2.12c6.64,0,16.66-3.44,16.66-12.8,0-9.72-13.58-10.63-13.58-14.85S81.25,7.36,85.41,9.66Z"
      fill="#005ca9"
    />
    <path
      d="M23.41,20.85,20.14,4S19.74.67,15.52.67H.18L0,1.3A37.82,37.82,0,0,1,14.45,8.55,29.16,29.16,0,0,1,23.41,20.85Z"
      fill="#f7a823"
    />
  </svg>
</template>

<script>
export default {
  name: 'VisaIcon',
};
</script>