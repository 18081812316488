<template>
  <footer class="section">
    <transition name="opacity">
      <div class="container" v-if="storeConfig">
        <div class="badge-container">
          <SSLIcon />
          <a
            :href="`https://transparencyreport.google.com/safe-browsing/search?url=${storeConfig.url}`"
            target="_blank"
          >
            <GoogleSafeBrowsingIcon />
          </a>
        </div>
        <div class="payment-methods">
          <EloIcon v-if="hasCredit" />
          <VisaIcon v-if="hasCredit" />
          <MastercardIcon v-if="hasCredit" />
          <AmericanExpressIcon v-if="hasCredit" />
          <HipercardIcon v-if="hasCredit" />
          <BoletoIcon v-if="hasBoleto" />
          <PixIcon v-if="hasPix" />
        </div>
      </div>
    </transition>
  </footer>
</template>

<script>
import EloIcon from '@/components/icons/footer/EloIcon.vue';
import SSLIcon from '@/components/icons/footer/SSLIcon.vue';
import PixIcon from '@/components/icons/footer/PixIcon.vue';
import VisaIcon from '@/components/icons/footer/VisaIcon.vue';
import BoletoIcon from '@/components/icons/footer/BoletoIcon.vue';
import HipercardIcon from '@/components/icons/footer/HipercardIcon.vue';
import MastercardIcon from '@/components/icons/footer/MastercardIcon.vue';
import AmericanExpressIcon from '@/components/icons/footer/AmericanExpressIcon.vue';
import GoogleSafeBrowsingIcon from '@/components/icons/footer/GoogleSafeBrowsingIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MainFooter',
  components: {
    EloIcon,
    SSLIcon,
    PixIcon,
    VisaIcon,
    BoletoIcon,
    HipercardIcon,
    MastercardIcon,
    AmericanExpressIcon,
    GoogleSafeBrowsingIcon,
  },
  computed: {
    ...mapGetters(['storeConfig', 'paymentOptions']),
    hasCredit() {
      return Object.keys(this.paymentOptions).some(
        (el) => el === 'credito' || el === 'dois_cartoes'
      )
        ? true
        : false;
    },
    hasBoleto() {
      return Object.keys(this.paymentOptions).some((el) => el === 'boleto')
        ? true
        : false;
    },
    hasPix() {
      return Object.keys(this.paymentOptions).some((el) => el === 'pix')
        ? true
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  background-color: var(--footer-background);
  padding: 1rem 0;
  height: 6.25rem;

  @media screen and (max-width: $lg) {
    height: auto;
    padding: 2.5rem 0;
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 2rem;

    @media screen and (max-width: $lg) {
      margin-bottom: 4rem;
    }

    @media screen and (max-width: $md) {
      justify-content: center;
    }

    @media screen and (max-width: $sm) {
      flex-flow: column;
      align-items: center;
      gap: 2.5rem;
      margin-bottom: 3rem;
    }

    .badge-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 2rem;
      height: 2rem;
      width: max-content;

      @media screen and (max-width: $sm) {
        height: 2.5rem;
      }

      a {
        height: 100%;
        width: max-content;
      }

      svg {
        height: 100%;
        width: auto;
        max-width: 7rem;
        fill: var(--footer-icons-color);
      }
    }

    .payment-methods {
      width: max-content;
      height: auto;
      display: flex;
      flex-flow: row wrap;
      padding: 0.325rem 0.75rem;
      background-color: #fff;
      gap: 1rem;
      border-radius: 0.25rem;

      @media screen and (max-width: $sm) {
        padding: 0.75rem 1rem 0.825rem;
        align-items: center;
        justify-content: center;
        width: 75%;
        gap: 1.5rem;
      }

      > * {
        height: 100%;
        width: auto;
        max-width: 2.5rem;
        height: 1.75rem;
      }
    }
  }
}
</style>