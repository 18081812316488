<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.5 5">
    <polyline class="check" points="0.5 2 2.5 4 5 0.5" />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
};
</script>

<style lang="scss">
.check {
  fill: none;
  stroke: var(--main-button-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 14;
  stroke-dashoffset: 14;
  transition: stroke-dashoffset 300ms ease-out;
}
</style>