<template>
  <ModalContentContainer
    :closeButton="updatedFreight"
    modalTitle="Endereço alterado"
  >
    <p class="text" v-if="updatedFreight">
      Confirme a escolha de frete para sua compra.
    </p>
    <p class="text" v-else>
      A opção de frete selecionada anteriormente não está disponível para o CEP
      informado. Selecione uma das opções abaixo para continuar.
    </p>
    <FreightContent :listOnly="true" />
    <MainButton
      class="main-button"
      :isDisabled="!selectedFreight"
      @click.native="closeModal"
    >
      <span>Continuar</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import FreightContent from '@/components/FreightContent.vue';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'UpdatedFreight',
  data() {
    return {
      updatedFreight: false,
    };
  },
  components: {
    FreightContent,
    ModalContentContainer,
    MainButton,
  },
  computed: {
    ...mapGetters(['selectedFreight']),
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    closeModal() {
      if (this.selectedFreight) {
        this.updateModal({
          isVisible: false,
          component: null,
          canClose: null,
        });
      }
    },
  },
  created() {
    this.updatedFreight = this.selectedFreight ? true : false;
  },
  activated() {
    this.updatedFreight = this.selectedFreight ? true : false;
  },
};
</script>

<style lang="scss" scoped>
.text {
  max-width: 27.5rem;
  font-size: 0.875rem;
  color: var(--light-text-color);
  margin-bottom: 1rem;
}

.main-button {
  margin: 1.625rem 0 0 auto;
  width: 7.5rem;
}
</style>