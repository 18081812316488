import createReCaptchaToken from '@/helpers/createReCaptchaToken.js'
import fetchCEP from '@/helpers/fetchCEP.js'
import { api } from '@/services.js'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function appendSelectedInstallments(currentOptions = {}, forceUpdate = false) {
  const method = appState.getters?.selectedPayment?.method
  const installments = appState.getters?.selectedInstallments?.quantity

  if ((forceUpdate || method == 'credito') && installments) {
    currentOptions = {
      ...currentOptions,
      parcelas: installments
    }
  }

  return currentOptions
}

let appState = new Vuex.Store({
  state: {
    initAction: 'Loading',
    config: null,
    cart: null,
    userData: {},
    userAddress: {},
    navigation: {
      pageTitle: '',
      nextStep: '',
      buttonComponent: '',
      isLoading: false,
      couponLoading: false,
      freightLoading: false,
      productLoading: false,
      paymentLoading: false,
      hideMobileCheckout: false,
    },
    payment: {
      processingPayment: false,
      hasError: null,
      seller: {
        id: null,
        alert: false,
        list: []
      },
      methods: [],
      selectedInstallments: null
    },
    modal: {
      isVisible: false,
      component: null,
      canClose: null,
      content: null
    },
    isUserAddressValid: false,
    isUserDataValid: false,
    ebitData: {}
  },
  mutations: {
    UPDATE_CART(state, payload) {
      let data = { ...payload }

      data.produtos.forEach(item => {
        item.isDeleting = false
        item.isLoading = false
        item.message = null
      })

      if (this.getters.storeConfig?.ebit) {
        this.commit('UPDATE_EBIT_DATA', data)
      }

      state.cart = data
    },
    SET_INIT_ACTION(state, payload) {
      state.initAction = payload
    },
    CHANGE_ITEM_STATUS(state, payload) {
      const index = state.cart.produtos.findIndex(item => item.id === payload.id)
      Object.assign(state.cart.produtos[index], payload.data)
    },
    REMOVE_ITEM_AMOUNT_FROM_STATE(state, payload) {
      const index = state.cart.produtos.findIndex(item => item.id === payload)
      const item = state.cart.produtos[index]
      item.quantidade--
    },
    UPDATE_NAVIGATION(state, payload) {
      state.navigation = { ...state.navigation, ...payload }
    },
    UPDATE_USER_ADDRESS(state, payload) {
      state.userAddress = { ...state.userAddress, ...payload }
    },
    VALIDATE_USER_ADDRESS(state, payload) {
      state.isUserAddressValid = payload
    },
    UPDATE_USER_DATA(state, payload) {
      state.userData = { ...state.userData, ...payload }

      this.commit('UPDATE_EBIT_DATA', { ...state.userData })
    },
    VALIDATE_USER_DATA(state, payload) {
      state.isUserDataValid = payload
    },
    UPDATE_PAYMENT(state, payload) {
      state.payment = {
        ...state.payment,
        ...payload
      }
    },
    INVALIDATE_CARD_TOKEN(state, payload) {
      state.payment.hasError = payload
    },
    UPDATE_SELLER(state, payload) {
      state.payment.seller = {
        ...state.payment.seller,
        ...payload
      }
    },
    VALIDATE_PAYMENT(state, payload) {
      const methods = state.payment.methods

      for (const method in methods) {
        if (methods[method].selected) {
          methods[method] = {
            ...methods[method],
            ...payload
          }
        }
      }

      state.payment.methods = methods
    },
    UPDATE_MODAL(state, payload) {
      state.navigation.hideMobileCheckout = payload.isVisible

      state.modal = { ...state.modal, ...payload }
    },
    GET_PAYMENT_METHOD(state, payload) {
      const defaultMethod = payload.padrao
      let methods = payload.metodos

      Object.keys(methods).forEach(method => {
        methods[method].isValid = false
        methods[method].selected = false
      })

      state.payment.methods = methods

      // fallback
      if (!state.cart) {
        this.commit('SET_PAYMENT_METHOD', defaultMethod ? defaultMethod : Object.keys(methods)[0])
      }
    },
    SET_PAYMENT_METHOD(state, payload) {
      const methods = state.payment.methods

      for (const method in methods) {
        methods[method].selected = false
      }

      methods[payload].selected = true

      if (!state.cart) {
        state.cart = { metodo_pagamento: payload }
      }
    },
    SAVE_CONFIG(state, payload) {
      state.config = payload

      if (payload.ebit) {
        this.commit('UPDATE_EBIT_DATA', {
          storeId: payload.ebit
        })
      }
    },
    UPDATE_SELECTED_INSTALLMENTS(state, payload) {
      state.payment.selectedInstallments = payload

      this.commit('UPDATE_EBIT_DATA', {
        parcelas: this.getters.selectedPayment.method == 'credito' ? payload.quantity : 1
      })
    },
    UPDATE_EBIT_DATA(state, payload) {
      if (!this.getters.storeConfig?.ebit) {
        state.ebitData = null
        return
      }

      function getUserAgent() {
        return /Mobi|Android/i.test(navigator.userAgent) ? 1 : 0
      }

      let data = {
        storeId: payload?.storeId ?? state.ebitData?.storeId,
        transactionId: payload?.id ?? state.ebitData?.transactionId,
        platform: getUserAgent(),
        zipCode: state.ebitData?.zipCode ?? null,
        parcels: state.ebitData?.parcels ?? 1,
        deliveryTax: state.ebitData?.deliveryTax ?? null,
        deliveryTime: state.ebitData?.deliveryTime ?? null,
        totalSpent: state.ebitData?.totalSpent ?? null,
        value: state.ebitData?.value ?? null,
        quantity: state.ebitData?.quantity ?? null,
        productName: state.ebitData?.productName ?? null,
        paymentType: state.ebitData?.paymentType ?? null,
        sku: state.ebitData?.sku ?? null,
        productCondition: 0,
        deliveryType: state.ebitData?.deliveryType ?? null,
        mktSaleId: 0,
        ean: state.ebitData?.ean ?? null,
        birthday: state.ebitData?.birthday ?? null,
        age: state.ebitData?.age ?? null
      }

      const isInCash = this.getters.selectedPayment.pagamento == 'vista'

      if (payload?.produtos) {
        let productName, quantity, sku, ean, value = null

        payload.produtos.forEach(item => {
          let selectedValue = isInCash
            ? item?.valores?.total?.valor_vista.toFixed(2)
            : item?.valores?.total?.valor_prazo.toFixed(2)

          productName = !productName ? item?.titulo : productName + `|${item?.titulo}`
          quantity = !quantity ? item?.quantidade : quantity + `|${item?.quantidade}`
          sku = !sku ? item?.sku : sku + `|${item?.sku}`
          ean = !ean ? item?.gtin : ean + `|${item?.gtin}`
          value = !value ? selectedValue : value + `|${selectedValue}`
        })

        data = {
          ...data,
          productName,
          quantity,
          sku,
          ean,
          value
        }
      }

      if (payload?.metodo_pagamento) {
        switch (payload.metodo_pagamento) {
          case 'credito':
            data.paymentType = '05'
            break
          case 'dois_cartoes':
            data.paymentType = '05'
            break
          case 'boleto':
            data.paymentType = '08'
            break
          case 'pix':
            data.paymentType = '90'
            break
          default:
            data.paymentType = '14'
        }
      }

      if (payload?.valores) {
        data.totalSpent = isInCash ? payload.valores?.total_vista.toFixed(2) : payload.valores?.total_prazo.toFixed(2)
      }

      if (payload?.parcelas) {
        data.parcels = payload.parcelas
      }

      if (payload?.frete) {
        let currentSelection = null

        for (let index in payload.frete?.transportadoras) {
          if (payload.frete?.transportadoras[index]?.selected) {
            currentSelection = payload.frete?.transportadoras[index]
          }
        }

        data.zipCode = currentSelection?.destino ?? data.zipCode
        data.deliveryTime = currentSelection?.entrega ?? data.deliveryTime
        data.deliveryTax = currentSelection?.valor.toFixed(2) ?? data.deliveryTax
        data.deliveryType = currentSelection?.transportadora == 'SEDEX' ? '2' : '1'
      }

      if (payload?.nascimento) {
        const today = new Date()
        const birthday = new Date(payload.nascimento)
        const age = Math.floor((today - birthday) / (1000 * 60 * 60 * 24 * 365.25))

        data.age = age

        const dateParts = payload.nascimento.split('-')
        data.birthday = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      }

      state.ebitData = data
    }
  },
  actions: {
    async GET_CONFIG(context) {
      try {
        return await api.get('/checkout/config')
          .then(res => {
            if (res.status === 200) {
              context.commit('UPDATE_SELLER', { list: res.data.config.vendedores })
              context.commit('GET_PAYMENT_METHOD', res.data.config.metodo_pagamento)
              context.commit('SAVE_CONFIG', res.data.config)

              return true
            }

          })
      } catch (err) {
        console.error(err)
        context.commit('SET_INIT_ACTION', 'ConnectionIssue')
        return false
      }
    },
    async FETCH_DATA(context) {
      try {
        return await api.get('/checkout/carrinho')
          .then(res => {
            if (res.status === 200) {
              if (!res.data.carrinho.produtos.length) {
                context.commit('SET_INIT_ACTION', 'EmptyMessage')
                return false
              } else {
                context.commit('UPDATE_CART', res.data.carrinho)
                context.commit('SET_PAYMENT_METHOD', res.data.carrinho.metodo_pagamento)

                if (!context.state.userAddress.cep && res.data.carrinho.frete) {
                  context.commit('UPDATE_USER_ADDRESS', {
                    cep: res.data.carrinho.frete.cep
                  })
                }

                context.commit('SET_INIT_ACTION', null)
                return true
              }
            }
          })
      } catch (err) {
        console.error(err)
        context.commit('SET_INIT_ACTION', 'ConnectionIssue')
        return false
      }
    },
    async REMOVE_ITEM(context, payload) {
      const options = appendSelectedInstallments({
        id_produto: payload
      })

      context.commit('CHANGE_ITEM_STATUS', {
        id: payload,
        data: {
          isDeleting: true
        }
      })

      try {
        context.commit('UPDATE_NAVIGATION', { productLoading: true })

        return await api.post('/checkout/produto/remove/', options)
          .then(res => {
            if (res.status === 200) {
              if (Object.keys(res.data.carrinho.produtos).length === 0) {
                context.commit('SET_INIT_ACTION', 'EmptyMessage')
              }
              context.commit('UPDATE_CART', res.data.carrinho)
              context.commit('UPDATE_NAVIGATION', { productLoading: false })

              return true
            } else {
              console.error(res.status)
              context.commit('UPDATE_NAVIGATION', { productLoading: false })
              return false
            }
          })
      } catch (err) {
        console.error(err)
        context.commit('UPDATE_NAVIGATION', { productLoading: false })
        context.commit('CHANGE_ITEM_STATUS', {
          id: payload,
          data: {
            isDeleting: false
          }
        })
        Vue.$toast.error('Erro ao remover produto')
        return false
      }
    },
    async ADD_AMOUNT(context, payload) {
      const options = appendSelectedInstallments({
        ...payload
      })

      context.commit('CHANGE_ITEM_STATUS', {
        id: payload.id_produto,
        data: {
          isLoading: true
        }
      })

      try {
        context.commit('UPDATE_NAVIGATION', { productLoading: true })

        return await api.post('/checkout/produto/add_qtd', options)
          .then(res => {
            if (res.status === 200) {
              context.commit('UPDATE_CART', res.data.carrinho)
              context.commit('CHANGE_ITEM_STATUS', {
                id: payload.id_produto,
                data: {
                  isLoading: false,
                }
              })

              context.commit('UPDATE_NAVIGATION', { productLoading: false })
              return true
            } else {
              context.commit('UPDATE_NAVIGATION', { productLoading: false })
              console.error(res.status)
              return false
            }
          })
      } catch (err) {
        console.error(err)
        context.commit('CHANGE_ITEM_STATUS', {
          id: payload.id_produto,
          data: {
            isLoading: false,
          }
        })
        context.commit('UPDATE_NAVIGATION', { productLoading: false })
        Vue.$toast.error(err.response?.data?.message ? err.response?.data?.message : 'Erro ao atualizar produto')
        return false
      }
    },
    async REMOVE_AMOUNT(context, payload) {
      const options = appendSelectedInstallments({
        id_produto: payload
      })

      context.commit('CHANGE_ITEM_STATUS', {
        id: payload,
        data: {
          isLoading: true
        }
      })

      try {
        context.commit('UPDATE_NAVIGATION', { productLoading: true })

        return await api.post('/checkout/produto/remove_qtd', options)
          .then(res => {
            if (res.status === 200) {
              context.commit('UPDATE_CART', res.data.carrinho)
              context.commit('UPDATE_NAVIGATION', { productLoading: false })
              context.commit('CHANGE_ITEM_STATUS', {
                id: payload,
                data: {
                  isLoading: false,
                }
              })
              return true
            } else {
              console.error(res.status)
              context.commit('UPDATE_NAVIGATION', { productLoading: false })
              return false
            }
          })
      } catch (err) {
        context.commit('UPDATE_NAVIGATION', { productLoading: false })
        console.error(err)
        context.commit('CHANGE_ITEM_STATUS', {
          id: payload,
          data: {
            isLoading: false,
          }
        })
        Vue.$toast.error('Erro ao atualizar produto')
        return false
      }
    },
    async SAVE_USER_DATA(context, payload) {
      try {
        return await api.post('/checkout/cliente/save', {
          ...payload
        })
          .then(res => {
            if (res.data.success) {
              const data = Object.assign(res.data.cliente, { isValid: true })
              context.commit('UPDATE_USER_DATA', data)
              return true
            } else {
              return false
            }
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao salvar dados')
        return false
      }
    },
    async SAVE_ADDRESS_DATA(context, payload) {
      const options = appendSelectedInstallments({
        ...payload
      })

      try {
        return await api.post('/checkout/endereco/save', options)
          .then(res => {
            if (res.status === 200) {
              context.commit('UPDATE_USER_ADDRESS', res.data.endereco)
              context.commit('UPDATE_CART', res.data.carrinho)
              return true
            } else {
              return false
            }
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao salvar endereço')
        return false
      }
    },
    async UPDATE_PAYMENT_METHOD(context, payload) {
      const options = appendSelectedInstallments({
        ...payload
      }, true)

      try {
        return await api.post('/checkout/define_pagamento', options)
          .then(res => {
            if (res.status === 200) {
              context.commit('UPDATE_CART', res.data.carrinho)
              context.commit('SET_PAYMENT_METHOD', res.data.carrinho.metodo_pagamento)
              return true
            } else {

              return false
            }
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao atualizar pagamento')
        return false
      }
    },
    async ADD_COUPON(context, payload) {
      const options = appendSelectedInstallments({
        cupom: payload,
        ...await createReCaptchaToken()
      })

      try {
        return await api.post('/checkout/cupom/add', options)
          .then(res => {
            if (res.data.success) {
              context.commit('UPDATE_CART', res.data.carrinho)
            } else {
              Vue.$toast.error(res.data.message)
            }
            return res.data
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao adicionar cupom')
        return false
      }
    },
    async REMOVE_COUPON(context) {
      const options = appendSelectedInstallments()

      try {
        return await api.post('/checkout/cupom/remove', options)
          .then(res => {
            if (res.data.success) {
              context.commit('UPDATE_CART', res.data.carrinho)
            }
            return res.data
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao remover cupom')
        return false
      }
    },
    async CALC_FREIGHT(context, payload) {
      const options = appendSelectedInstallments({
        destino: payload
      })

      try {
        const apiResponse = await fetchCEP(payload)

        if (!apiResponse) {
          return;
        }

        return await api.post('/checkout/calculo_frete', options)
          .then(res => {
            if (!res.data.success) {
              Vue.$toast.error(res.data.message ? res.data.message : 'Falha ao consultar frete')
              return false
            }

            context.commit('UPDATE_CART', res.data.carrinho)
            return apiResponse
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao calcular frete')
        return false
      }
    },
    async SET_FREIGHT(context, payload) {
      const options = appendSelectedInstallments({
        frete_id: payload
      })

      try {
        return await api.post('checkout/frete_save', options)
          .then(res => {
            context.commit('UPDATE_CART', res.data.carrinho)
            return res.data.carrinho
          })
      } catch (err) {
        console.error(err)
        Vue.$toast.error('Erro ao selecionar frete')
        return {
          message: 'Erro ao selecionar frete'
        }
      }
    },
    async UPDATE_CART(context, payload) {
      // There's no need to go through the appendSelectedInstallments function because component already fills in this information

      context.commit('UPDATE_NAVIGATION', { isLoading: true })

      try {
        return api.post('/checkout/carrinho', payload)
          .then(res => {
            context.commit('UPDATE_NAVIGATION', { isLoading: false })

            if (!res.data?.success) {
              Vue.$toast.error('Falha ao atualizar valores. Tente novamente')
              return false
            }

            context.commit('UPDATE_CART', res.data.carrinho)
            return true
          })
      } catch (err) {
        context.commit('UPDATE_NAVIGATION', { isLoading: false })

        Vue.$toast.error('Falha ao atualizar valores. Tente novamente')
        console.error(err)
        return false
      }
    }
  },
  getters: {
    storeConfig: state => state.config,
    cartId: state => state.cart && state.cart.id ? state.cart.id : null,
    cartItems: state => state.cart.produtos,
    cartDiscounts: state => state.cart.valores.descontos,
    cartTotal: state => state.cart.valores,
    initAction: state => state.initAction,
    navigationData: state => state.navigation,
    isAppLoading: state => state.navigation.isLoading,
    userAddress: state => state.userAddress,
    isUserAddressValid: state => state.isUserAddressValid,
    userData: state => state.userData,
    isUserDataValid: state => state.isUserDataValid,
    accountType: state => state.userData.tipo ? state.userData.tipo : null,
    modalSettings: state => state.modal,
    freightCep: state => state.cart.frete ? state.cart.frete.cep : null,
    freightOptions: state => {
      if (state.cart.frete && state.cart.frete.transportadoras) {
        return state.cart.frete.transportadoras
      }
      return null
    },
    selectedFreight: state => {
      if (state.cart.frete && state.cart.frete.transportadoras) {
        for (let index in state.cart.frete.transportadoras) {
          if (state.cart.frete.transportadoras[index].selected) {
            return state.cart.frete.transportadoras[index]
          }
        }
      }
      return null
    },
    selectedFreightId: state => {
      if (state.cart.frete && state.cart.frete.transportadoras) {
        for (let index in state.cart.frete.transportadoras) {
          if (state.cart.frete.transportadoras[index].selected) {
            return state.cart.frete.transportadoras[index].id
          }
        }
      }
      return null
    },
    selectedPayment: state => {
      const paymentSettings = state.payment.methods
      let selected = null

      for (const method in paymentSettings) {
        if (paymentSettings[method].selected) {
          selected = paymentSettings[method]
          selected.method = method
        }
      }

      return selected
    },
    selectedInstallments: state => state.payment.selectedInstallments ?? null,
    paymentOptions: state => state.payment.methods,
    paymentId: state => state.cart.payment_id ? state.cart.payment_id : null,
    isPaymentBeingProcessed: state => state.payment.processingPayment,
    invalidateCardToken: state => state.payment.hasError,
    purchaseSeller: state => state.payment.seller.list.length ? state.payment.seller : null,
    processedPaymentData: state => state.cart.dados_pagamento ? state.cart.dados_pagamento : null,
    ebitData: state => state.ebitData ?? null
  },
})

export default appState