<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.002"
    height="16"
    viewBox="0 0 16.002 16"
  >
    <path
      d="M73.235,181.249a2.338,2.338,0,0,1-1.662-.686l-2.4-2.4a.456.456,0,0,0-.631,0l-2.408,2.407a2.338,2.338,0,0,1-1.662.688H64l3.042,3.042a2.434,2.434,0,0,0,3.438,0l3.052-3.047Z"
      transform="translate(-60.754 -169.009)"
    />
    <path
      d="M64.472,3.76a2.332,2.332,0,0,1,1.662.688l2.409,2.409a.447.447,0,0,0,.631,0l2.4-2.4a2.332,2.332,0,0,1,1.662-.688h.289L70.476.72a2.43,2.43,0,0,0-3.437,0h0L64,3.76Z"
      transform="translate(-60.754 -0.008)"
    />
    <path
      d="M15.268,89.27l-1.842-1.842a.36.36,0,0,1-.131.026h-.838a1.653,1.653,0,0,0-1.163.482l-2.4,2.4a1.153,1.153,0,0,1-1.629,0L4.857,87.927a1.652,1.652,0,0,0-1.163-.482H2.665a.371.371,0,0,1-.124-.025L.688,89.27a2.434,2.434,0,0,0,0,3.438l1.851,1.85a.347.347,0,0,1,.124-.025H3.695a1.656,1.656,0,0,0,1.163-.482l2.409-2.409a1.18,1.18,0,0,1,1.629,0l2.4,2.4a1.656,1.656,0,0,0,1.163.482H13.3a.341.341,0,0,1,.131.026l1.842-1.842a2.434,2.434,0,0,0,0-3.437h0"
      transform="translate(0.023 -82.988)"
    />
  </svg>
</template>

<script>
export default {
  name: 'PixIcon',
};
</script>