<template>
  <div class="content-wrap">
    <div class="field-container">
      <CepInput
        required
        textLabel="CEP"
        placeholderText="00000-000"
        :isDisabled="isDisabled"
        :inputValue="localData.cep"
        :validateField="triggerValidate"
        :readonly="savingData"
        @fetchData="changeData($event)"
        @update:InputValue="localData.cep = $event"
      />
      <TextInput
        required
        textLabel="Logradouro"
        placeholderText="Seu endereço"
        :isDisabled="isDisabled"
        :regexString="defaultRegex"
        :inputValue="localData.endereco"
        :validateField="triggerValidate"
        :readonly="savingData"
        @update:inputValue="localData.endereco = $event"
        @validateField="validateFields.endereco = $event"
      />
      <AddressNumberInput
        required
        textLabel="Número"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.numero"
        :validateField="triggerValidate"
        placeholderText="O número da residência"
        @update:inputValue="localData.numero = $event"
        @validateField="validateFields.numero = $event"
      />
      <TextInput
        textLabel="Complemento"
        placeholderText="Opcional"
        :readonly="savingData"
        :regexString="defaultRegex"
        :isDisabled="isDisabled"
        :inputValue="localData.complemento"
        @update:inputValue="localData.complemento = $event"
      />
      <TextInput
        required
        textLabel="Bairro"
        placeholderText="Seu bairro"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :regexString="defaultRegex"
        :inputValue="localData.bairro"
        :validateField="triggerValidate"
        @update:inputValue="localData.bairro = $event"
        @validateField="validateFields.bairro = $event"
      />
      <TextInput
        required
        textLabel="Cidade"
        placeholderText="Sua cidade"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :regexString="defaultRegex"
        :inputValue="localData.cidade"
        :validateField="triggerValidate"
        @update:inputValue="localData.cidade = $event"
        @validateField="validateFields.cidade = $event"
      />
      <SelectInput
        required
        textLabel="Estado"
        placeholderText="Selecione uma opção"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :selectItems="localStates"
        :inputValue="localData.uf"
        :validateField="triggerValidate"
        @update:inputValue="localData.uf = $event"
        @validateField="validateFields.uf = $event"
      />
      <NoteContainer v-if="accountType === 'J'" :alignCenter="true">
        <p>
          Não é possível alterar o endereço de contas jurídicas. Para mais
          informações,
          <a :href="$baseURL + '/central-de-atendimento'" target="_blank"
            >fale conosco</a
          >.
        </p>
      </NoteContainer>
    </div>
    <div class="editingButtonContainer" v-if="!isDisabled">
      <MainButton
        :isSecondary="true"
        :isDisabled="savingData"
        @click.native="cancelChanges"
      >
        <span>Cancelar</span>
      </MainButton>
      <MainButton :isLoading="savingData" @click.native="saveChanges">
        <span>Salvar</span>
      </MainButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import states from '@/static/states.js';
import fetchCEP from '@/helpers/fetchCEP.js';
import CepInput from '@/components/input/CepInput.vue';
import TextInput from '@/components/input/TextInput.vue';
import SelectInput from '@/components/input/SelectInput.vue';
import AddressNumberInput from '@/components/input/AddressNumberInput.vue';
import MainButton from '@/components/input/MainButton.vue';
import NoteContainer from '@/components/NoteContainer.vue';

export default {
  name: 'DeliveryBox',
  data() {
    return {
      localStates: states.list,
      localData: {},
      initialData: {},
      defaultRegex: '^[a-zA-Zà-úÀ-Ú0-9 /,-.]*$',
      savingData: false,
      triggerValidate: false,
      validateFields: {
        endereco: false,
        numero: false,
        bairro: false,
        cidade: false,
        uf: false,
      },
    };
  },
  components: {
    CepInput,
    TextInput,
    SelectInput,
    AddressNumberInput,
    MainButton,
    NoteContainer,
  },
  props: {
    isDisabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([
      'userAddress',
      'freightCep',
      'selectedFreight',
      'isUserAddressValid',
      'accountType',
    ]),
  },
  watch: {
    userAddress(value) {
      this.localData = Object.assign({}, value);
      this.initialData = Object.assign({}, value);
    },
    isDisabled(value) {
      if (!value) {
        this.validateUserAddress(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      openModal: 'UPDATE_MODAL',
      updateUserAddress: 'UPDATE_USER_ADDRESS',
      validateUserAddress: 'VALIDATE_USER_ADDRESS',
    }),
    ...mapActions({
      updateDeliveryData: 'SAVE_ADDRESS_DATA',
    }),
    changeData(response) {
      const { cep, bairro, cidade, complemento, endereco, numero, uf } =
        response;

      this.localData = {
        cep,
        bairro,
        cidade,
        complemento,
        endereco,
        numero,
        uf,
      };
    },
    async saveChanges() {
      this.savingData = true;
      const isValid = await this.checkValidation();

      if (!isValid) {
        this.$toast.error('Preencha os campos corretamente');
        this.savingData = false;
        return;
      }

      let differentCEP =
        this.localData.cep !== this.freightCep || !this.selectedFreight
          ? true
          : false;

      const data = Object.assign(
        {},
        {
          cep: this.localData.cep,
          endereco: this.localData.endereco,
          bairro: this.localData.bairro,
          cidade: this.localData.cidade,
          uf: this.localData.uf,
          numero: this.localData.numero,
          complemento: this.localData.complemento,
        }
      );

      await this.updateDeliveryData(data).then((res) => {
        if (res) {
          if (differentCEP) {
            this.openModal({
              isVisible: true,
              component: 'UpdatedFreight',
            });
          }

          this.initialData = Object.assign({}, data);

          this.updateUserAddress(data);
          this.validateUserAddress(true);
        } else {
          this.cancelChanges();
          this.$toast.error('Erro ao processar. Tente novamente');
        }

        this.savingData = false;

        this.$emit('update:isDeliveryDisabled', {
          isClosed: true,
          keepModalOpen: differentCEP,
        });
      });
    },
    async cancelChanges() {
      this.localData = Object.assign({}, this.initialData);

      this.updateUserAddress(this.initialData);
      this.validateUserAddress(
        (await this.checkValidation(false)) ? true : false
      );

      this.$emit('update:isDeliveryDisabled', {
        isClosed: true,
        keepModalOpen: false,
      });
    },
    checkValidation() {
      this.triggerValidate = true;
      this.validateUserAddress(false);

      return new Promise((resolve) => {
        fetchCEP(this.localData.cep).then((validateCep) => {
          if (!validateCep) resolve(false);

          for (let key in this.validateFields) {
            if (this.validateFields[key] == false) {
              this.triggerValidate = false;
              this.validateUserAddress(false);
              return resolve(false);
            }
          }

          this.triggerValidate = false;
          this.validateUserAddress(true);
          return resolve(true);
        });
      });
    },
    cloneData() {
      let { cep, endereco, numero, bairro, cidade, uf, complemento } =
        this.userAddress;

      this.localData = Object.assign(
        {},
        {
          cep,
          numero,
          endereco,
          bairro,
          cidade,
          uf,
          complemento,
        }
      );

      this.initialData = Object.assign({}, this.localData);
    },
  },
  created() {
    this.cloneData();
  },
  async activated() {
    this.cloneData();
    await this.checkValidation();
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 1.25rem;
}

.field-container {
  display: flex;
  flex-flow: column;
  gap: 1.25rem;
}

.label-title {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.editingButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    width: 5rem;
  }
}
</style>