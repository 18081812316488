import getCustomerFromUrl from './getCustomerFromUrl'

export default function () {
  const customer = getCustomerFromUrl()

  try {
    import(`../assets/themes/${customer.name}.css`);
  } catch (error) {
    console.warn(`Theme for ${customer.name} not found`);
  }
}