<template>
  <InputContainer :textLabel="textLabel" :hasError="hasError && required">
    <input
      type="text"
      inputmode="numeric"
      v-mask="['#####-###']"
      v-model="localInputValue"
      :readonly="readonly ? true : null"
      :disabled="isDisabled"
      :placeholder="placeholderText"
      @keyup="handleKeyup($event)"
      @paste="handleKeyup($event)"
      @focus="
        handleFocus();
        hideCheckout();
      "
      @blur="
        handleBlur();
        showCheckout();
      "
    />
  </InputContainer>
</template>

<script>
import { mapMutations } from 'vuex';
import fetchCEP from '@/helpers/fetchCEP.js';
import InputContainer from '@/components/input/InputContainer.vue';
import toggleCheckout from '@/mixins/toggleCheckout.js';

export default {
  name: 'CepInput',
  mixins: [toggleCheckout],
  data() {
    return {
      hasError: false,
      localInputValue: this.inputValue,
    };
  },
  components: {
    InputContainer,
  },
  props: {
    textLabel: {
      type: String,
      require: true,
    },
    inputValue: {
      type: String,
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
    placeholderText: String,
    isDisabled: Boolean,
  },
  watch: {
    inputValue(value) {
      this.localInputValue = value;
      this.validate(value);
    },
    localInputValue(value) {
      this.$emit('update:InputValue', value);
    },
  },
  methods: {
    ...mapMutations({
      validateUserAddress: 'VALIDATE_USER_ADDRESS',
    }),
    handleFocus() {
      if (this.readonly) return false;

      this.hasError = false;
    },
    async handleKeyup() {
      if (this.readonly) return false;

      const data = await this.validate(this.localInputValue);

      if (data) {
        this.$emit('fetchData', data);
      }
    },
    async handleBlur() {
      if (this.readonly) return false;

      await this.validate(this.localInputValue);
    },
    validate(inputValue) {
      return new Promise((resolve, reject) => {
        try {
          fetchCEP(inputValue).then((response) => {
            this.hasError = response ? false : true;
            return resolve(response);
          });
        } catch (error) {
          this.hasError = true;
          return reject(error);
        }
      });
    },
  },
};
</script>

<style>
</style>