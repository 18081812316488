<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="378.16"
    height="378.16"
    viewBox="0 0 378.16 378.16"
  >
    <g transform="translate(-160.92 -90.934)">
      <path
        d="M498.17,148.4a35.007,35.007,0,0,0-31.5-20.066H233.33a35.009,35.009,0,0,0-35,35v233.34a35.007,35.007,0,0,0,20.066,31.5l19.832-19.832-4.9,0a11.665,11.665,0,0,1-11.664-11.664V283.044l3.148-1.4v0a52.606,52.606,0,0,1,51.449,13.418l37.684,37.684,16.566-16.566L292.83,278.494a76.172,76.172,0,0,0-71.164-19.949v-95.2a11.665,11.665,0,0,1,11.664-11.664H466.67a11.656,11.656,0,0,1,11.664,11.664v4.9Zm-19.836,52.852v163.33L432.6,318.848l0,0a52.153,52.153,0,0,0-69.066-2.454l-33.016,33.016,3.035,3.035h0a19.974,19.974,0,0,0,13.184,4.785,21.145,21.145,0,0,0,12.832-4.434l2.1-2.8L375.9,335.766h0a29.989,29.989,0,0,1,41.3,0L475.537,394.1A23.341,23.341,0,0,1,455,408.333H271.252L247.92,431.665l207.08,0A46.665,46.665,0,0,0,501.668,385V177.92Z"
      />
      <path
        d="M402.5,175a52.5,52.5,0,0,0-27.883,96.949l17.266-17.266a29.168,29.168,0,1,1,37.8-37.8l17.266-17.266A52.492,52.492,0,0,0,402.5,175ZM455,224.7,399.7,280h2.8A52.613,52.613,0,0,0,455,227.5a22.335,22.335,0,0,0,0-2.8Z"
      />
      <path d="M522.58,90.934l16.5,16.5L177.42,469.094l-16.5-16.5Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NoImage',
};
</script>