<template>
  <div id="app">
    <transition mode="out-in" name="modal">
      <Modal
        v-if="modalSettings.isVisible"
        :isVisible="modalSettings.isVisible"
        :component="modalSettings.component"
      />
    </transition>
    <MainHeader />
    <div class="section main-content">
      <transition mode="out-in">
        <component v-if="initAction" :is="initAction" key="component" />
        <router-view v-else key="router" />
      </transition>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/modal/Modal.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import ConnectionIssue from '@/components/ConnectionIssue.vue';

export default {
  name: 'App',
  data() {
    return {
      isModalVisible: false,
    };
  },
  components: {
    MainHeader,
    MainFooter,
    EmptyMessage,
    ConnectionIssue,
    Modal,
  },
  computed: {
    ...mapGetters(['initAction', 'modalSettings', 'storeConfig']),
  },
  watch: {
    storeConfig() {
      this.loadReCaptchaScript();
      this.changeHeadData();
      this.validateTagManager();
    },
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    loadReCaptchaScript() {
      if (!this.storeConfig || !this.storeConfig.recaptcha) return;

      return new Promise((resolve, reject) => {
        const link = `https://www.google.com/recaptcha/api.js?render=${this.storeConfig.recaptcha.chave_site}`;

        const scriptAlreadyAdded = document.head.querySelectorAll(
          `script[src="${link}"]`
        );

        if (scriptAlreadyAdded.length) {
          return resolve();
        }

        let script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = link;
        script.defer = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject('Failed to load reCaptcha library');
        };

        document.head.appendChild(script);
      });
    },
    changeHeadData() {
      window.document.title = `Carrinho | ${this.storeConfig.name}`;

      if (!document.head.querySelectorAll(`link[rel="icon"]`).length) {
        const favicon = document.createElement('link');
        favicon.setAttribute('rel', 'icon');
        favicon.setAttribute('href', this.storeConfig.logos.favicon);
        window.document.head.appendChild(favicon);
      }
    },
    validateTagManager() {
      if (!this.storeConfig.tracking?.tag_manager) return;

      const tag = this.storeConfig.tracking.tag_manager;

      const scriptAlreadyAdded =
        document.head.querySelectorAll('#googleTagManager');

      if (scriptAlreadyAdded.length) return;

      this.activateTagManager('dataLayer', tag);
    },
    activateTagManager(dataLayer, tag) {
      window[dataLayer] = window[dataLayer] || [];

      window[dataLayer].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      let firstScriptTag = document.getElementsByTagName('script')[0];
      let script = document.createElement('script');
      let dataLayerText = dataLayer != 'dataLayer' ? '&l=' + dataLayer : '';

      script.id = 'googleTagManager';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${tag}${dataLayerText}`;

      firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

      let noScript = document.createElement('noscript');
      let iframe = document.createElement('iframe');

      iframe.src = `https://www.googletagmanager.com/ns.html?id=${tag}`;
      iframe.width = '0';
      iframe.height = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      noScript.appendChild(iframe);

      document.body.appendChild(noScript);
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/sass/global';

#app {
  justify-content: space-between;

  .main-content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    padding: 3rem 0;
    background-color: var(--background-color);

    @media screen and (max-width: $lg) {
      min-height: calc(100vh - 7.5rem);
    }

    > .loading {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: 300ms;
}

.v-enter,
.v-leave-to {
  transform: translateY(-1rem);
  opacity: 0;
}

.v-enter-to,
.v-leave {
  transform: translateY(0);
  opacity: 1;
}

.opacity-enter-active,
.opacity-leave-active {
  transition: 150ms ease-out;
}

.opacity-enter,
.opacity-leave-to {
  opacity: 0;
}

.opacity-enter-to,
.opacity-leave {
  opacity: 1;
}

.modal-enter-active,
.modal-leave-active {
  transition: 200ms opacity ease-out;

  .modal-content {
    transition: 200ms cubic-bezier(0.32, 0.46, 0.41, 1.04);
    transition-property: transform, opacity;
  }
}

.modal-enter,
.modal-leave-to {
  opacity: 0;

  .modal-content {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

.modal-enter-to,
.modal-leave {
  opacity: 1;

  .modal-content {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 300ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-0.5rem);
  opacity: 0;
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
  opacity: 1;
}
</style>
