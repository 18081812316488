<template>
  <div class="content-wrap">
    <div class="field-container">
      <TextInput
        required
        textLabel="Nome fantasia"
        regexString="^[a-zA-ZÀ-ú \/,.\-@:&*+_<>()!?'$%]*$"
        placeholderText="Nome fantasia"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.nome_fantasia"
        :validateField="triggerValidate"
        @update:inputValue="localData.nome_fantasia = $event"
        @validateField="validateFields.nome_fantasia = $event"
      />
      <TextInput
        required
        textLabel="Razão social"
        regexString="^[a-zA-ZÀ-ú \/,.\-@:&*+_<>()!?'$%]*$"
        placeholderText="Razão social"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.razao_social"
        :validateField="triggerValidate"
        @update:inputValue="localData.razao_social = $event"
        @validateField="validateFields.razao_social = $event"
      />
      <TextInput
        inputValue="Isento"
        textLabel="Inscrição estadual"
        :readonly="true"
        :isDisabled="isDisabled"
        v-if="localData.tipo_tributacao === 'isento'"
      />
      <TextInput
        v-else
        textLabel="Inscrição estadual"
        inputMode="numeric"
        regexString="^\d{6,20}$"
        :required="localData.tipo_tributacao !== 'isento'"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.inscricao_estadual"
        :validateField="triggerValidate"
        @update:inputValue="localData.inscricao_estadual = $event"
        @validateField="validateFields.inscricao_estadual = $event"
      />
      <div class="tributacao">
        <SelectInput
          required
          textLabel="Tributação"
          placeholderText="Selecione uma opção"
          :readonly="savingData"
          :isDisabled="isDisabled"
          :inputValue="localData.tipo_tributacao"
          :validateField="triggerValidate"
          :selectItems="taxType"
          @update:inputValue="localData.tipo_tributacao = $event"
          @validateField="validateFields.tipo_tributacao = $event"
        />
        <CheckboxInput
          textLabel="Simples nacional"
          :readonly="
            localData.tipo_tributacao === 'isento' ||
            localData.tipo_tributacao === 'nao_contribuinte'
          "
          :isDisabled="
            isDisabled ||
            localData.tipo_tributacao === 'isento' ||
            localData.tipo_tributacao === 'nao_contribuinte'
          "
          :inputValue="localData.simples_nacional"
          :validateField="triggerValidate"
          @update:inputValue="localData.simples_nacional = $event"
        />
      </div>
      <TextInput
        required
        textLabel="Responsável"
        placeholderText="Nome e sobrenome"
        regexString="^[a-zA-ZÀ-ú ]*$"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.responsavel"
        :validateField="triggerValidate"
        @update:inputValue="localData.responsavel = $event"
        @validateField="validateFields.responsavel = $event"
      />
      <TextInput
        required
        textLabel="Telefone"
        inputMode="tel"
        placeholderText="Telefone para contato"
        regexString="^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$"
        :readonly="savingData"
        :isDisabled="isDisabled"
        :inputValue="localData.telefone"
        :inputMask="['(##) ####-####', '(##) #####-####']"
        :validateField="triggerValidate"
        @update:inputValue="localData.telefone = $event"
        @validateField="validateFields.telefone = $event"
      />
      <DocumentInput
        required
        textLabel="CNPJ"
        placeholderText="00.000.000/0000-00"
        :readonly="savingData"
        :isDisabled="!!initialData.cpf_cnpj || isDisabled"
        :inputValue="localData.cpf_cnpj"
        :validateField="triggerValidate"
        :inputMask="['##.###.###/####-##']"
        @update:inputValue="localData.cpf_cnpj = $event"
        @validateField="validateFields.cpf_cnpj = $event"
      />
      <EmailInput
        required
        textLabel="E-mail"
        placeholderText="Seu melhor e-mail"
        :readonly="savingData"
        :isDisabled="true"
        :inputValue="localData.email"
      />
    </div>
    <div class="editingButtonContainer" v-if="!isDisabled">
      <MainButton
        :isSecondary="true"
        :isDisabled="savingData"
        @click.native="cancelChanges"
      >
        <span>Cancelar</span>
      </MainButton>
      <MainButton :isLoading="savingData" @click.native="saveChanges">
        <span>Salvar</span>
      </MainButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import TextInput from '@/components/input/TextInput.vue';
import DocumentInput from '@/components/input/DocumentInput.vue';
import EmailInput from '@/components/input/EmailInput.vue';
import SelectInput from '@/components/input/SelectInput.vue';
import CheckboxInput from '@/components/input/CheckboxInput.vue';
import MainButton from '@/components/input/MainButton.vue';

export default {
  name: 'CompanyBox',
  data() {
    return {
      localData: {},
      initialData: {},
      taxType: [
        {
          valor: 'contribuinte',
          texto: 'Contribuinte de ICMS',
        },
        {
          valor: 'isento',
          texto: 'Contribuinte isento de ICMS',
        },
        {
          valor: 'nao_contribuinte',
          texto: 'Não contribuinte de ICMS',
        },
      ],
      savingData: false,
      triggerValidate: false,
      validateFields: {
        razao_social: false,
        nome_fantasia: false,
        inscricao_estadual: false,
        tipo_tributacao: false,
        responsavel: false,
        telefone: false,
        cpf_cnpj: false,
      },
    };
  },
  components: {
    TextInput,
    DocumentInput,
    EmailInput,
    SelectInput,
    CheckboxInput,
    MainButton,
  },
  props: {
    isDisabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['userData']),
  },
  watch: {
    userData(value) {
      this.localData = Object.assign({}, value);
      this.initialData = Object.assign({}, value);
    },
    isDisabled(value) {
      if (!value) {
        this.validateUserData(false);
      }
    },
    'localData.tipo_tributacao': {
      handler(value) {
        if (value === 'nao_contribuinte') {
          this.localData.simples_nacional = false;
          return;
        }

        if (value === 'isento') {
          this.validateFields.inscricao_estadual = true;
          this.localData.simples_nacional = false;
          return;
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      updateUserData: 'UPDATE_USER_DATA',
      validateUserData: 'VALIDATE_USER_DATA',
    }),
    ...mapActions({
      saveUserData: 'SAVE_USER_DATA',
    }),
    async saveChanges() {
      this.savingData = true;
      const isValid = await this.checkValidation();

      if (!isValid) {
        this.$toast.error('Preencha os campos corretamente');
        this.savingData = false;
        return false;
      }

      const data = Object.assign(
        {},
        {
          razao_social: this.localData.razao_social,
          nome_fantasia: this.localData.nome_fantasia,
          telefone: this.localData.telefone,
          responsavel: this.localData.responsavel,
          tipo_tributacao: this.localData.tipo_tributacao,
          simples_nacional: this.localData.simples_nacional,
        }
      );

      if (this.localData.tipo_tributacao === 'isento') {
        this.localData.inscricao_estadual = null;
        Object.assign(data, { inscricao_estadual: null });
      } else {
        Object.assign(data, {
          inscricao_estadual: this.localData.inscricao_estadual,
        });
      }

      if (!this.initialData.cpf_cnpj) {
        Object.assign(data, { cpf_cnpj: this.localData.cpf_cnpj });
      } else {
        Object.assign(this.localData, { cpf_cnpj: this.initialData.cpf_cnpj });
      }

      await this.saveUserData(data).then((res) => {
        if (res) {
          this.initialData = Object.assign({}, data);

          this.updateUserData(data);
          this.validateUserData(true);
        } else {
          this.cancelChanges();
          this.$toast.error('Erro ao processar. Tente novamente');
        }

        this.savingData = false;
        this.$emit('update:isUserDisabled', true);
      });
    },
    async cancelChanges() {
      this.localData = Object.assign({}, this.initialData);

      this.updateUserData(this.initialData);
      this.validateUserData((await this.checkValidation(false)) ? true : false);

      this.$emit('update:isUserDisabled', true);
    },
    checkValidation() {
      this.triggerValidate = true;
      this.validateUserData(false);

      return new Promise((resolve) => {
        setTimeout(() => {
          for (let key in this.validateFields) {
            if (this.validateFields[key] == false) {
              this.triggerValidate = false;
              this.validateUserData(false);
              return resolve(false);
            }
          }

          this.triggerValidate = false;
          this.validateUserData(true);
          return resolve(true);
        }, 0);
      });
    },
    cloneData() {
      let {
        email,
        razao_social,
        nome_fantasia,
        cpf_cnpj,
        inscricao_estadual,
        telefone,
        responsavel,
        tipo_tributacao,
        simples_nacional,
      } = this.userData;

      this.localData = Object.assign(
        {},
        {
          email,
          razao_social,
          nome_fantasia,
          cpf_cnpj,
          inscricao_estadual,
          telefone,
          responsavel,
          tipo_tributacao,
          simples_nacional,
        }
      );

      this.initialData = Object.assign({}, this.localData);
    },
  },
  created() {
    this.cloneData();
  },
  async activated() {
    this.cloneData();
    await this.checkValidation();
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 1.5rem;
}

.field-container {
  display: flex;
  flex-flow: column;
  gap: 1.25rem;
  flex-grow: 1;
}

.editingButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    width: 5rem;
  }
}

.feedback-container {
  .text-feedback {
    overflow: hidden;
    display: block;
    color: var(--alert-color);
    font-size: 0.75rem;
    text-align: center;
    font-weight: 500;
  }
}

.feedback-transition-enter,
.feedback-transition-leave-to {
  height: 0;
  opacity: 0;
  transition: height 200ms cubic-bezier(0.86, 0.07, 0.35, 0.89) 150ms,
    opacity 150ms ease-in;
}

.feedback-transition-enter-to,
.feedback-transition-leave {
  height: 18px;
  opacity: 1;
  transition: height 200ms cubic-bezier(0.86, 0.07, 0.35, 0.89),
    opacity 150ms ease-in 200ms;
}

.tributacao {
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
}
</style>
