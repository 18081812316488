<template>
  <ModalContentContainer
    :modalTitle="
      modalSettings.content.isProduct
        ? 'Valores com taxas'
        : 'Opções de parcelamento'
    "
  >
    <p class="text" v-if="modalSettings.content.isProduct">
      <template v-if="modalSettings.content.hasFees">
        Este produto contém juros em pagamentos parcelados.
      </template>
      Confira abaixo o valor unitário final das parcelas.
    </p>
    <p class="text" v-else>
      Confira abaixo as opções de parcelamento disponíveis para sua compra.
    </p>

    <p class="no-interest-total" v-if="modalSettings.content.hasFees">
      Total sem juros: {{ cartTotal.total_prazo_sem_juros | currency }}
    </p>

    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th>Parcelas</th>
          <th>Juros</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, index) in modalSettings.content.installments"
          :key="`parcela-${index}`"
        >
          <td>{{ index }}x de {{ value.valor | currency }}</td>
          <td>
            {{
              value.total_juros == 0
                ? 'Sem juros'
                : value.total_juros | currency
            }}
          </td>
          <td>{{ value.total | currency }}</td>
        </tr>
      </tbody>
    </table>

    <MainButton class="main-button" @click.native="closeModal">
      <span>Fechar</span>
    </MainButton>
  </ModalContentContainer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContentContainer from '@/components/modal/ModalContentContainer.vue';
import MainButton from '../input/MainButton.vue';

export default {
  name: 'ModalProductInstallments',
  components: {
    ModalContentContainer,
    MainButton,
  },
  computed: {
    ...mapGetters(['modalSettings', 'cartTotal']),
  },
  methods: {
    ...mapMutations({
      updateModal: 'UPDATE_MODAL',
    }),
    closeModal() {
      this.updateModal({
        isVisible: false,
        component: null,
        canClose: null,
        content: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  max-width: 25rem;
  font-size: 0.875rem;
  color: var(--light-text-color);
  margin-bottom: 1rem;
}

.no-interest-total {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--dark-text-color);
}

table {
  width: 100%;

  th,
  td {
    font-size: 0.75rem;
    padding: 0.325rem 0.5rem;
    text-align: right;
    color: var(--normal-text-color);

    &:first-child {
      text-align: left;
    }
  }

  thead th {
    font-weight: 700;
    color: var(--dark-text-color);
  }

  tbody {
    tr:nth-child(odd) {
      background-color: var(--table-background);
    }

    th,
    td {
      font-size: 0.75rem;
      padding: 0.325rem 0.5rem;
      text-align: right;
      color: var(--normal-text-color);

      &:last-child {
        /* color: var(--dark-text-color); */
        font-weight: 600;
      }
    }
  }
}

.main-button {
  margin: 1.625rem 0 0 auto;
  width: 7.5rem;
}
</style>