import customerData from '../static/customers.json'

export default function () {
  const hostname = window.location.hostname
  const exceptions = {
    "localhost": "localhost",
    "bikeshopbarigui.test": "bikeshopbarigui"
  }

  const name = Object.keys(exceptions).some(domain => domain === hostname)
    ? exceptions[hostname]
    : hostname.split('.')[1]

  return {
    name: name,
    ...customerData[name]
  }
}