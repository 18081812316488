<template>
  <div>
    <CartButtonIcon />
    <span class="button-text"> Continuar </span>
  </div>
</template>

<script>
import CartButtonIcon from '@/components/icons/checkoutBox/CartButtonIcon.vue';

export default {
  name: 'CartButton',
  components: {
    CartButtonIcon,
  },
};
</script>

<style scoped lang="scss">
div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  svg {
    fill: var(--main-button-color);
    pointer-events: none;
  }

  span {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--main-button-color);
    pointer-events: none;
    text-align: center;
  }

  @media screen and (max-width: $sm) {
    gap: 0.25rem;

    svg {
      height: 1rem;
    }

    span {
      font-size: 0.75rem;
    }
  }
}
</style>