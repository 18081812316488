<template>
  <transition name="opacity" mode="out-in">
    <img
      :src="item.imagem"
      :alt="item.titulo"
      :class="{ hidden: !showImage }"
      v-if="hasImage && item.imagem"
      @load="showImage = true"
      @error="hasImage = false"
    />
    <div class="no-image" v-else>
      <NoImage />
    </div>
  </transition>
</template>

<script>
import NoImage from '@/components/icons/cartBox/NoImage.vue';

export default {
  name: 'ItemImage',
  data() {
    return {
      showImage: false,
      hasImage: true,
    };
  },
  components: {
    NoImage,
  },
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: opacity 300ms ease-out 300ms;

  &.hidden {
    opacity: 0;
  }
}

.no-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-color: var(--no-image-background);
  border-radius: 0.25rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 70%;
    height: auto;
    fill: var(--no-image-fill);
  }
}
</style>