<template>
  <div class="note" :class="{ center: alignCenter }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NoteContainer',
  props: {
    alignCenter: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--note-background-color);
  padding: 0.75rem 1rem 1rem;

  ::v-deep span,
  ::v-deep p {
    font-size: 0.75rem;
    line-height: 1.5;
    color: var(--note-text-color);
    font-weight: 500;
  }

  ::v-deep .title {
    font-weight: 700;
    color: var(--note-title-color);
  }

  ::v-deep a {
    font-weight: 600;
    color: var(--main-link-color);
    transition: color 150ms ease-out;

    &:focus,
    &:hover {
      color: var(--main-link-color-hover);
    }
  }

  &.center {
    align-items: center;
    justify-content: center;

    ::v-deep * {
      text-align: center;
    }
  }
}
</style>